import { Typography, styled } from '@mui/material';

import { ThemeProvider } from '/common';
import { ThemeMode } from '/common/models';
import { headerHeight } from '/common/Header/styles';
import { collapsedNavSidebarWidth, navSidebarWidth } from '/constants';

export const SplashScreen = styled('div', {
  shouldForwardProp: (prop) => prop !== 'navSidebarOpen', // Prevent passing this prop to DOM
})<{ navSidebarOpen: boolean }>(({ navSidebarOpen, theme }) => ({
  position: 'fixed',
  top: `${headerHeight}px`,
  height: '100%',
  zIndex: 1101,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255,255,255,.9)',
  margin: '0 -15px',
  width: `calc(100% - ${navSidebarOpen ? navSidebarWidth : collapsedNavSidebarWidth}px)`,
  transition: theme.transitions.create('width', {
    duration: navSidebarOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
}));

export const SplashScreenText = styled('div')`
  text-align: center;
`;

export const SplashScreenTitle = styled(Typography)`
  color: ${({ theme }) =>
    ThemeProvider.getAppropriateStyles(
      theme.palette.mode as ThemeMode,
      theme.palette.primary.main,
      theme.palette.text.primary
    )};
`;
