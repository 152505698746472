import { Avatar, styled } from '@mui/material';

import { IAvatarStyledProps } from './types';
import { getAvatarBorderColor } from './constant';

export const AvatarStyled = styled(Avatar)(({ theme, ownerState }: IAvatarStyledProps) => ({
  cursor: ownerState.imageSelected || ownerState.imageUrl ? 'pointer' : 'default',
  borderColor: getAvatarBorderColor(theme, ownerState),
  borderWidth: '2px',
  borderStyle: 'solid',
}));
