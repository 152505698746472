import { css, Grid, styled, Typography } from '@mui/material';

export const idCellCSS = css`
  width: 1%;
  min-width: 82px;
`;

export const AppIconContainer = styled(Grid)`
  width: ${({ theme }) => theme.spacing(4)};
  height: ${({ theme }) => theme.spacing(4)};
`;
export const TypographyStyled = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const GridContainer = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr auto;
`;
