import { Typography, Theme } from '@mui/material';

import { FileRevision } from '@playq/octopus2-files';

import { FCWithChildren, IThemeDependentEntityResolver } from '/common/models';

import { PickerLabelStyled, PickerLabelIconStyled } from './styles';

export const resolvePickerLabel: IThemeDependentEntityResolver<(rev: FileRevision) => string | JSX.Element> =
  (theme: Theme) => (rev: FileRevision) => {
    const PickerLabelStyledBound: FCWithChildren = (props) => (
      <PickerLabelStyled $themePalette={theme.palette}>{props.children}</PickerLabelStyled>
    );

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!rev) {
      return 'Empty revision';
    }

    if (rev.version === 1) {
      return rev.comment || 'Empty comment';
    }

    return (
      <Typography component={PickerLabelStyledBound} noWrap={true}>
        {rev.comment || 'Select Version'}
        <PickerLabelIconStyled />
      </Typography>
    );
  };
