import { companyId } from '/constants';
export const generateId = <T extends { id: string | number; app: string | number }>(
  Constructor: new () => T,
  appId?: string | number,
  id?: string | number
): T | undefined => {
  // currently there is no entities with id === 0, replace this check if needed
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!id || !appId) {
    return;
  }

  const newType = new Constructor();
  newType.app = appId;
  newType.id = id;
  return newType;
};

export const generateAppId = <T extends { app: string }>(Constructor: new () => T, appId: string): T => {
  const newType = new Constructor();
  newType.app = appId;
  return newType;
};
export const generateAppIdWithCompany = <T extends { company: number; id: number }>(
  Constructor: new () => T,
  id: number
): T | undefined => {
  const newType = new Constructor();
  if (typeof id === 'number' && id !== undefined) {
    newType.company = companyId;
    newType.id = id;
    return newType;
  }
  return;
};
