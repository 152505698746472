import { SxProps, Theme } from '@mui/system';
import { FlattenSimpleInterpolation } from '@mui/styled-engine';

export const cssToSx = (flattenCss: FlattenSimpleInterpolation): SxProps<Theme> => {
  const styleString = flattenCss.toString();
  const styleObject = styleString.split(';').reduce<Record<string, string>>((acc, style) => {
    const [key, value] = style.split(':').map((str) => str.trim());
    if (key && value) {
      acc[key] = value;
    }
    return acc;
  }, {});
  return styleObject;
};
