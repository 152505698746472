import { FC, SyntheticEvent } from 'react';
import { Tooltip } from '@mui/material';
import { ChevronRight as NextImageButton, ChevronLeft as PrevImageButton } from '@mui/icons-material';

import { IFallbackProps, IFullPreviewProps, SourceType } from '/shared/FilePreview/types';

import { handlers, IHandlerProps } from '../handlers';
import { UnknownPreview } from '../previewComponents';

import {
  DialogContainerStyled,
  DialogContentStyled,
  PrevPaginationButtonWrapperStyled,
  NextPaginationButtonWrapperStyled,
  PaginationButtonStyled,
} from './styles';

export const FullScreenPreview: FC<IFullPreviewProps> = (props) => {
  const { source, Fallback, withPagination, showPrev, showNext, onClose, onRenderFailure, ...rest } = props;

  const FB: FC<IFallbackProps> = () => {
    return Fallback ? (
      <Fallback />
    ) : (
      <UnknownPreview fallbackTitle={rest.fallbackTitle} onRenderFailure={onRenderFailure} />
    );
  };

  if (source === undefined) {
    return <FB />;
  }

  const Handler = handlers.find((handler) => handler.canProcess(source)) as FC<IHandlerProps<SourceType>>;

  const isHandlerExist = Handler !== null;

  const handleStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <DialogContainerStyled open={true} onClose={onClose} maxWidth='lg'>
      <DialogContentStyled onClick={handleStopPropagation}>
        {withPagination && (
          <PrevPaginationButtonWrapperStyled onClick={showPrev}>
            <Tooltip placement='top' title='Previous'>
              <PaginationButtonStyled>
                <PrevImageButton />
              </PaginationButtonStyled>
            </Tooltip>
          </PrevPaginationButtonWrapperStyled>
        )}
        {isHandlerExist ? <Handler source={source} autoPlay={true} Fallback={FB} {...rest} /> : <FB />}
        {withPagination && (
          <NextPaginationButtonWrapperStyled onClick={showNext}>
            <Tooltip placement='top' title='Next'>
              <PaginationButtonStyled>
                <NextImageButton />
              </PaginationButtonStyled>
            </Tooltip>
          </NextPaginationButtonWrapperStyled>
        )}
      </DialogContentStyled>
    </DialogContainerStyled>
  );
};
