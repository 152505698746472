import { useState } from 'react';

import { Mimes } from '/constants/file';

import { IPreviewComponentProps } from '../../types';
import { PlayButtonStyled } from '../styles';

import { ContainerStyled } from './styles';

export function HTMLPreview({
  url,
  autoPlay,
  onRebuildThumbnail: _,
  onClick,
  Fallback,
  ...rest
}: IPreviewComponentProps) {
  const [activeFrame, setActiveFrame] = useState(Boolean(autoPlay));

  const handleClick = () => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (onClick) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      onClick();
      return;
    }

    setActiveFrame(true);
  };

  return (
    <ContainerStyled onClick={handleClick}>
      {!activeFrame ? (
        <PlayButtonStyled />
      ) : (
        <iframe {...rest} src={url} frameBorder={0} title='url' sandbox='allow-scripts' />
      )}
    </ContainerStyled>
  );
}

HTMLPreview.mimes = Mimes.HTML;
