import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CssBaseline } from '@mui/material';

import { Header } from '/common/Header';
import { NavSidebar } from '/common/NavSidebar';
import { RoutesList } from '/Router/RoutesList';
import { IRouteComponentProps } from '/Router/models';
import { Helper } from '/common/Helper';
import { AppTimeline } from '/common/AppTimeline';
import { ProgressIndicatorProvider } from '/common/ProgressIndicator';
import { uiToolkit } from '/store';

import { AppContent, RootComponent } from './styles';

export const AppPage: FC<IRouteComponentProps> = ({ routes = [], location }) => {
  const isNavSidebarPermanentlyOpen: boolean = useSelector(uiToolkit.selectors.navSidebarOpen);

  const [isNavSidebarOpen, setIsNavSidebarOpen] = useState(isNavSidebarPermanentlyOpen);

  const routerList = useMemo(() => <RoutesList routes={routes} />, [routes]);

  const handleNavSidebarOpeningStatusChange = useCallback((isOpen: boolean) => {
    setIsNavSidebarOpen(isOpen);
  }, []);

  return (
    <RootComponent>
      <CssBaseline />
      <ProgressIndicatorProvider>
        <Header />
      </ProgressIndicatorProvider>
      <NavSidebar location={location} onOpeningStatusChange={handleNavSidebarOpeningStatusChange} />
      <AppContent>
        {routerList}
        <Helper navSidebarOpen={isNavSidebarOpen} />
      </AppContent>
      <AppTimeline />
    </RootComponent>
  );
};
