import { MutationKey } from '@tanstack/query-core';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { SpaceID, AppID } from '@playq/octopus-common';

import { UseMutationOptionsExtended, useEitherMutation } from '/api/service-hooks';
import { experimentsQueryKeys, flowQueryKeys, gameEventsQueryKeys, services2 } from '/api';
import { snackbarService } from '/common/snackbarService';

import { spacePromotionsCloneKey } from './constants';

export type UseAppSpacePromotionsCloneParams = {
  appID: AppID;
  sourceSpaceID?: SpaceID;
  targetSpaceID: SpaceID;
};

export const useAppSpacePromotionsClone = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, UseAppSpacePromotionsCloneParams>
) => {
  return useEitherMutation(
    ({ appID, sourceSpaceID = undefined, targetSpaceID }) =>
      services2.spacesPromotionsService.clonePromotions(appID, sourceSpaceID, targetSpaceID),
    {
      onSuccess: () => {
        snackbarService.success('All entities successfully cloned to selected space.');
      },
      onError: (err) => {
        snackbarService.genericFailure(err);
      },
      removeQueriesKeysArray: [experimentsQueryKeys, flowQueryKeys, gameEventsQueryKeys],
      mutationKey: spacePromotionsCloneKey as MutationKey,
      ...options,
    }
  );
};
