import { List, ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';

import { ThemeMode } from '/common/models';
import { ThemeProvider } from '/common';

export const StyledListItemIcon = styled(ListItemIcon)`
  color: inherit;
`;

export const StyledListItemText = styled(ListItemText)`
  color: inherit;
`;

export const StyledList = styled(List)`
  color: inherit;
`;

export const StyledListItemButton = styled(ListItemButton)<{ selected: boolean }>(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: `${theme.palette.background.paper} !important`,

    color: ThemeProvider.getAppropriateStyles(
      theme.palette.mode as ThemeMode,
      theme.palette.primary.main,
      theme.palette.text.disabled
    ),
    '&:hover': {
      backgroundColor: `${theme.palette.action.selected} !important`,
    },
  }),
}));
