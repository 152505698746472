import { Dialog, DialogContent, IconButton, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

const NAV_BUTTON_WRAPPER_MARGIN = 30;
const NAV_BUTTON_SIZE = 40;
const CONTENT_MIN_SIZE = 100;

export const DialogContainerStyled = styled(Dialog)(({ theme }) => ({
  margin: theme.spacing(4),
  '& .MuiDialog-paperWidthLg': {
    display: 'grid',
    alignContent: 'center',
  },
}));

export const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  minHeight: CONTENT_MIN_SIZE,
  minWidth: CONTENT_MIN_SIZE,
  maxWidth: `calc(100vw - ${theme.spacing(22)}px)`,
  maxHeight: `calc(100vh - ${theme.spacing(22)}px)`,
  width: 'auto',
  height: 'auto',
  overflowY: 'hidden',
  backgroundColor: theme.palette.background.default,
  justifyContent: 'center',
  padding: 0,
  border: 0,
  // this is needed to override default MuiDialogContent styles
  '&:first-child': {
    padding: 0,
  },
}));

const paginationButtonWrapperStyles = {
  top: 0,
  width: '50%',
  height: '100%',
  display: 'flex',
  cursor: 'pointer',
  opacity: 0,
  '&:hover ': {
    opacity: 1,
    transitionDuration: '1s',
  },
};
export const PrevPaginationButtonWrapperStyled = styled('div')({
  ...paginationButtonWrapperStyles,
  position: 'absolute',
  left: NAV_BUTTON_WRAPPER_MARGIN,
});

export const NextPaginationButtonWrapperStyled = styled('div')({
  ...paginationButtonWrapperStyles,
  position: 'absolute',
  right: NAV_BUTTON_WRAPPER_MARGIN,
  justifyContent: 'flex-end',
});

export const PaginationButtonStyled = styled(IconButton)(({ theme }) => ({
  width: NAV_BUTTON_SIZE,
  height: NAV_BUTTON_SIZE,
  alignSelf: 'center',
  borderRadius: '100%',
  border: `1px solid ${grey[500]}`,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: grey[500],
  },
}));
