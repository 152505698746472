import {
  css,
  IconButton,
  InputAdornment,
  Menu,
  styled,
  Table,
  TableCell,
  TableRow,
  TextField,
  Theme,
} from '@mui/material';
import * as d3 from 'd3';

import { ThemeProvider } from '/common';
import { IWithCSS, ThemeMode } from '/common/models';

import { COMMON_TABLE_CELL_PADDING, ROW_HOVER_TRANSITION_DELAY } from './constants';

const gradientCellBackground = (theme: Theme) =>
  theme.palette.mode === 'dark'
    ? `linear-gradient(90deg, rgba(48, 48, 48, 0.00) 0%, rgba(48, 48, 48) 10%)`
    : `linear-gradient(90deg, rgba(250, 251, 252, 0.00) 0%, rgba(250, 251, 252) 10%)`;

export const ACTION_ROW_CLASSNAME = 'actionRowClassName';

// CSS
export const cellCSS = css`
  padding-left: 5px;
  padding-right: 5px;
  position: relative;

  &:first-child {
    padding-left: ${COMMON_TABLE_CELL_PADDING}px;
  }

  &:last-child {
    min-width: 150px;
    padding-right: ${COMMON_TABLE_CELL_PADDING}px;
  }
`;

export const actionsRowCellContainerCSS = css`
  height: auto;
`;

export const actionsRowCellCSS = css`
  padding: 0;
  position: absolute;
  height: -webkit-fill-available;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  right: 0;
  top: 0;
  background-image: ${({ theme }) => gradientCellBackground(theme)};
  display: flex;
  align-items: center;
  opacity: 0;
`;

export const rowSelectedCSS = css`
  background-color: ${({ theme }) => {
    const { r, g, b } = d3.rgb(theme.palette.secondary.main);
    return `rgba(${r}, ${g}, ${b}, 0.1) !important`;
  }};
`;

export const rowHoverCSS = css`
  cursor: pointer;
`;

export const rowCSS = css`
  position: relative;

  &:hover {
    box-shadow: 0 calc(${({ theme }) => theme.spacing(1)} / 4) calc(${({ theme }) => theme.spacing(1)} / 2)
      ${({ theme }) => theme.palette.grey[400]};
    transition-delay: ${ROW_HOVER_TRANSITION_DELAY};

    & .${ACTION_ROW_CLASSNAME} {
      opacity: 1;
      transition-delay: ${ROW_HOVER_TRANSITION_DELAY};
    }
  }
`;
const baseSelectionCellCSS = css`
  padding-right: ${COMMON_TABLE_CELL_PADDING}px;
  width: 50px;
`;

// Styled components
export const SelectionHeadCell = styled(TableCell)<{ $isMasterSelectionDisabled: boolean }>`
  ${baseSelectionCellCSS}

  ${({ $isMasterSelectionDisabled }) => $isMasterSelectionDisabled && `visibility: hidden;`}
}
`;

export const TableWrapperStyled = styled('div')<IWithCSS>`
  position: relative;

  ${({ css }) => css}
`;

export const TableStyled = styled(Table)`
  width: 100%;
`;

export const TableContentWrapper = styled('div')<IWithCSS>`
  width: 100%;
  overflow-x: auto;
  padding-bottom: 2px;

  ${({ css }) => css}
`;

export const TablePaginationActions = styled('div')`
  flex-shrink: 0;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-left: ${({ theme }) => theme.spacing(2.5)};
`;

export const Id = styled('span')`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const IdWrapper = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ActionsCell = styled('div')<{ $withAdditionalColumn?: boolean } & IWithCSS>`
  ${({ $withAdditionalColumn }) =>
    $withAdditionalColumn
      ? css`
          padding-left: 5px;
          padding-right: ${({ theme }) => theme.spacing(2)};
          opacity: 0;
        `
      : actionsRowCellCSS}

  ${({ css }) => css}
`;

export const ActionsRowCellControls = styled('div')<{ $hidden: boolean } & IWithCSS>`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  transition: ${({ theme }) =>
    theme.transitions.create('opacity', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    })};


  ${({ css }) => css}

  ${({ $hidden }) =>
    $hidden &&
    `opacity: 0;
    pointer-events: none;`}
}
`;

export const ActionsRowCellSpinner = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
`;

export const InputAdornmentStyled = styled(InputAdornment)`
  margin-right: 10px;

  &:hover > svg {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

export const MenuStyled = styled(Menu)<IWithCSS>`
  ${({ css }) => css}
`;

export const IconButtonStyled = styled(IconButton)`
  ${actionsRowCellContainerCSS};
`;

export const ActionList = styled('div')`
  ${actionsRowCellContainerCSS};
`;

export const StyledTextFilter = styled(TextField)<IWithCSS>`
  margin-right: 10px;
  vertical-align: middle;
  justify-content: center;

  & .MuiInput-input {
    width: 250px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:focus {
      width: 350px;
    }

    &:autofill,
    &:autofill:hover,
    &:autofill:focus,
    &:autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #fff !important;
    }
  }

  ${({ css }) => css}
`;

export const TruncatedCell = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableCellSkeleton = styled(TableCell)`
  ${cellCSS};

  padding-top: ${({ theme }) => `${parseInt(theme.spacing(3)) + theme.typography.fontSize / 2}px`};
  padding-bottom: ${({ theme }) => `${parseInt(theme.spacing(3)) + theme.typography.fontSize / 2}px`};
  position: relative;

  &:after {
    position: absolute;
    left: 0;
    top: 35%;
    content: '';
    height: 30%;
    width: 80%;
    margin: 0 auto;
    background: ${({ theme }) =>
      ThemeProvider.getAppropriateStyles(
        theme.palette.mode as ThemeMode,
        'linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%)',
        'linear-gradient(to right, #666666 20%, #4d4d4d 50%, #666666 80%)'
      )};
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    &:nth-of-type(even) {
      background-color: #f4f8fb;
    }
  }

  & .MuiTableCell-sizeSmall {
    padding-top: ${({ theme }) => `${parseInt(theme.spacing(2)) + theme.typography.fontSize / 2}px`};
    padding-bottom: ${({ theme }) => `${parseInt(theme.spacing(2)) + theme.typography.fontSize / 2}px`};
  }
`;

export const SkeletonTableRow = styled(TableRow)`
  ${rowCSS}
`;

/**
 * In GenericTableCellStyled two types of css are used:
 * because we have ability to pass css from column list and directly from table as styles props in both cases.
 * But order of css is important, because cssColumn should override css as more specific.
 */
export const GenericTableCellStyled = styled(TableCell)<{
  $columnHidden: boolean;
  $cssColumn: IWithCSS['css'];
  css: IWithCSS['css'];
}>`
  ${cellCSS}

  ${({ css }) => css}
  ${({ $cssColumn }) => $cssColumn}
  ${({ $columnHidden }) => $columnHidden && `display:none;`}
`;

export const MuiTableRowStyled = styled(TableRow)`
  &.MuiTableRow-root {
    ${rowCSS}
  }

  &.MuiTableRow-hover {
    ${rowHoverCSS}
  }

  &.Mui-selected {
    ${rowSelectedCSS}
  }
`;

export const HeaderCellWrapper = styled(TableCell)<{
  $css: IWithCSS['css'];
  $cssColumn: IWithCSS['css'];
  $cellCSS: IWithCSS['css'];
  $columnHidden: boolean;
}>`
  ${cellCSS};

  position: relative;
  text-wrap: nowrap;

  &:last-child {
    padding-left: ${COMMON_TABLE_CELL_PADDING}px;
  }

  & svg {
    opacity: 1;
  }

  ${({ $css }) => $css}
  ${({ $cellCSS }) => $cellCSS}
  ${({ $cssColumn }) => $cssColumn}
  ${({ $columnHidden }) => $columnHidden && `display:none;`}
`;
