import { useCallback } from 'react';

import { useToggleElements } from './useToggleElements';

interface UseMultipleModalsReturn<T extends string> {
  anchors: Map<T, HTMLButtonElement | null | undefined>;
  checkAnchorElOpen: (id: T) => boolean;
  addAnchorEl: (id: T) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  clearAnchorEl: (id: T) => void;
}

/**
 * A custom hook for managing multiple modals, with each modal associated with a button (anchor element).
 *
 * This hook allows toggling modal visibility, checking if a modal is open, and clearing anchor elements.
 *
 * @template T - A string type representing unique modal identifiers.
 *
 * @returns {UseMultipleModalsReturn} The returned object containing:
 *
 * @property {Map<T, HTMLButtonElement | null>} anchors - A map of anchor elements where the key is the modal ID.
 *
 * @property {function(T): boolean} checkAnchorElOpen - Checks if the anchor element for a given modal ID is currently open.
 *
 * @property {function(T): (event: React.MouseEvent<HTMLButtonElement>) => void} addAnchorEl - Adds an anchor element (usually a button) associated with a modal ID. Logs an error if the modal ID is already used by another anchor.
 *
 * @property {function(T): void} clearAnchorEl - Clears the anchor element associated with a given modal ID.
 */
export const useMultipleModals = <T extends string>(): UseMultipleModalsReturn<T> => {
  const { toggledElements, handleToggleElements, clearToggles } = useToggleElements<T, HTMLButtonElement | null>();

  const checkAnchorElOpen = useCallback((id: T) => Boolean(toggledElements.get(id)), [toggledElements]);

  const addAnchorEl = useCallback(
    (id: T) => (event: React.MouseEvent<HTMLButtonElement>) => {
      if (toggledElements.has(id)) {
        console.error(
          'There is another anchor with the same key. Please, rename the anchor to avoid errors. If you are trying to close the modal by setting null to anchor, please use clearAnchorEl method.'
        );
      }
      handleToggleElements(id, event.currentTarget);
    },
    [toggledElements, handleToggleElements]
  );

  const clearAnchorEl = useCallback(
    (id: T) => {
      clearToggles(id);
    },
    [clearToggles]
  );

  return {
    anchors: toggledElements,
    checkAnchorElOpen,
    addAnchorEl,
    clearAnchorEl,
  };
};
