import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  Link,
  Paper,
  Typography,
  useEventCallback,
} from '@mui/material';
import {
  AccountCircle,
  ArrowBack as ChevronLeftIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
  Textsms,
  Update,
  Warning as WarningIcon,
} from '@mui/icons-material';
import cs from 'classnames';
import { Link as RouterLink, Prompt, Router } from 'react-router-dom';

import { FilterExpression, NumberFilter } from '@playq/octopus-common';
import {
  AppEntitiesResponse,
  AppEntityFeatures,
  AppEntityFilterField,
  AppEntityVersionResponse,
} from '@playq/octopus2-apps';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Either } from '@playq/irt';

import { ThemeModeContext } from '/common';
import { IErrorBoundaryQueryData, IThemeModeContext, QueryFilter, ThemeMode } from '/common/models';
import { confirmAboutUnsavedChanges, formatDate, getErrorBoundaryQueryKey, mergeClasses } from '/helpers';
import { useMounted } from '/hooks';
import { IBasePromotionService } from '/api/hooks/promotions';
import { IVersionPickerProps, useVersionPicker } from '/shared/AppEntityVersionPicker';
import { ControlBarPromotionsPreview, sandboxColor } from '/shared/Promotions';
import { EntityID } from '/shared/Promotions/components/Service/types';
import { LabelThemed, LabelThemedColor } from '/shared/LabelThemed';
import { confirmDialog, ConfirmDialogType } from '/common/ConfirmDialog';
import { iteratorMaxLimit } from '/constants';
import { history } from '/Router/history';

import { IconButtonWithTooltip } from '../IconButtonWithTooltip';

import { tooltipCSS, StickyContainer, useControlBarStyles, versionPickerTheme } from './styles';
import { AppEntityControlBarClasses, AppEntityControlBarProps, ILogRow } from './types';

export function AppEntityControlBar<ID extends EntityID>(props: AppEntityControlBarProps<ID>) {
  const {
    componentIdLabels,
    componentState,
    label,
    entityId,
    service,
    disabled = false,
    version,
    content,
    withoutContentDivider,
    manifestName,
    notificationLabel,
    withPromotion = false,
    canSearch,
    canSave,
    disabledSave = false,
    canReset,
    saving: propSaving,
    processing,
    spaceless,
    canWrite,
    canWriteLive,
    isVersioned = true,
    promotionResolverData,
    className: containerClassNameReceived = '',
    backURL,
    wasLive = false,
    classes: propsClasses = {},
    isCreating = false,
    refreshData,
    openPromotionDialog,
    isCloned,
    noChanges: propsNoChanges,
    versionPickerProps,
    validate,
    onSearch,
    onEntitySelect,
    onPromotionsChange,
    afterModalOpen,
    onOpenPromotionDialogAfterUpdating,
    onSave,
    onReset,
    onGoBack,
    onUnsavedChangesPrompt,
    getParentVersionAsLink,
  } = props;
  const internalClasses = useControlBarStyles();
  const { currentThemeMode }: IThemeModeContext = useContext(ThemeModeContext);
  const classes = mergeClasses<AppEntityControlBarClasses>(internalClasses, propsClasses);

  const saving = !!propSaving;

  const noChanges = propsNoChanges === true && !isCloned;

  const [versionPickerNode, pickVersion] = useVersionPicker<ID>();
  const isMounted = useMounted();
  const [filter, setFilter] = useState('');
  const [currentVersion, setCurrentVersion] = useState<AppEntityVersionResponse | null>(null);
  const [isPromotedOnLive, setIsPromotedOnLive] = useState<boolean>(false);
  const [highlightVersionPicker, setHighlightVersionPicker] = useState<boolean>(false);
  const [shouldOpenPromotions, setShouldOpenPromotions] = useState<boolean>(openPromotionDialog ?? false);
  const [lastVersion, setLastVersion] = useState<number>();
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const errorBoundaryQueryKey = getErrorBoundaryQueryKey(history.location.pathname);

  const queryVersions = useMemo(() => service?.queryVersions?.bind(service), [service]);

  const linkToParentVersion = useMemo(() => {
    if (getParentVersionAsLink) {
      return getParentVersionAsLink(currentVersion?.comment ?? '');
    }
    return undefined;
  }, [getParentVersionAsLink, currentVersion?.comment]);

  const getVersion = useCallback(
    (nVersion?: number) => {
      if (!entityId || !service?.queryVersions) {
        return;
      }

      const iterator = new OffsetLimit({ limit: nVersion === undefined ? iteratorMaxLimit : 1, offset: 0 });
      const filterBy: QueryFilter =
        nVersion === undefined
          ? {}
          : {
              [AppEntityFilterField.Version]: new NumberFilter({
                left: nVersion,
                right: undefined,
                expression: FilterExpression.Equal,
              }),
            };

      service
        .queryVersions(entityId, iterator, [], filterBy, [AppEntityFeatures.AuthorName])
        .then((data: Either<GenericFailure, AppEntitiesResponse>) => {
          if (isMounted.current) {
            data.bifold(
              (res) => {
                if (nVersion !== undefined) {
                  setCurrentVersion(res.versions[0]);
                  return;
                }
                setLastVersion(res.versions[0]?.version);
              },
              (err) => console.error(err)
            );
          }
        });
    },
    [entityId, service, isMounted]
  );

  const enableClearErrorBoundaryQueryData = useEventCallback(() => {
    queryClient.setQueryData<IErrorBoundaryQueryData>(errorBoundaryQueryKey, (prevState) => ({
      ...prevState,
      disableClear: false,
    }));
  });

  useEffect(() => {
    enableClearErrorBoundaryQueryData();
  }, [enableClearErrorBoundaryQueryData]);

  useEffect(() => {
    getVersion(version);
    // need to listen version only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  const selectVersion = async () => {
    const selectedVersion = await pickVersion({
      entityId,
      version,
      queryVersions,
      queryPromotions: service?.queryPromotions?.bind(service),
      ...versionPickerProps,
    } as IVersionPickerProps<ID>);

    if (selectedVersion) {
      onEntitySelect?.(selectedVersion);
    }
  };

  const handleVersionSelect = () => {
    if (entityId && version !== undefined && queryVersions && onEntitySelect) {
      confirmAboutUnsavedChanges(selectVersion, noChanges);
    }
  };

  const handleVersionPickerOver = () => {
    if (version !== undefined) {
      setHighlightVersionPicker(true);
    }
  };

  const handleVersionPickerLeave = () => {
    setHighlightVersionPicker(false);
  };

  const onSaveClick = () => {
    if (!onSave) {
      return;
    }

    queryClient.setQueryData<IErrorBoundaryQueryData>(errorBoundaryQueryKey, (prevState) => ({
      ...prevState,
      disableClose: false,
      state: componentState ?? prevState?.state,
      disableClear: true,
    }));

    if (validate !== undefined) {
      const newHasErrors = validate();
      setHasErrors(newHasErrors);
      if (newHasErrors) {
        return;
      }
    }

    if (!isPromotedOnLive) {
      onSave();
      return;
    }

    confirmDialog({
      title: 'WARNING',
      text: 'Saving will not automatically promote the new version to Live. If you want to promote the new version, stay on the page and promote it manually',
      type: ConfirmDialogType.Warning,
      dialogProps: {
        maxWidth: 'sm',
      },
      closeButton: { label: 'Save' },
      onClose: () => {
        onSave();
        setShouldOpenPromotions(false);
      },
      successButton: { label: 'Save and open promotions editor' },
      onSuccess: () => {
        onSave();
        setShouldOpenPromotions(true);
        onOpenPromotionDialogAfterUpdating?.(true);
      },
    });
  };

  const onResetClick = () => {
    setHasErrors(false);

    queryClient.setQueryData<IErrorBoundaryQueryData>(errorBoundaryQueryKey, (prevState) => ({
      ...prevState,
      state: undefined,
      disableClear: false,
    }));

    if (onReset) {
      onReset();
    }
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setFilter(value);

    if (onSearch) {
      onSearch(value);
    }
  };

  const onSearchClear = () => {
    setFilter('');

    if (onSearch) {
      onSearch('');
    }
  };

  // it should listen only saving to detect when it is finished and then open promotions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shouldOpenPromotionsAfterSave = useMemo(() => shouldOpenPromotions && !saving, [saving]);

  const promotionVersion = useMemo(() => {
    if (!propSaving && shouldOpenPromotions && lastVersion !== undefined && lastVersion > (version ?? 0)) {
      return lastVersion + 1;
    }
    return version;
  }, [shouldOpenPromotions, propSaving, lastVersion, version]);

  const handleClosePromotionsDialog = useCallback(() => {
    setShouldOpenPromotions(false);
  }, []);

  function LogRow({ title, children }: ILogRow) {
    return (
      <Typography noWrap={true} component='div' className={classes.logDataInfoWrapper}>
        <Box className={classes.logDataInfoRow} title={title}>
          {children}
        </Box>
      </Typography>
    );
  }

  const getCurrentVersionSection = () => {
    if (!currentVersion) {
      return null;
    }
    const currentVersionContent = (underline?: boolean) => (
      <LogRow title={currentVersion.comment}>
        <Textsms className={classes.iconInline} color='action' />
        <Typography noWrap={true} variant='caption' sx={{ textDecoration: underline ? 'underline' : 'none' }}>
          {currentVersion.comment}
        </Typography>
      </LogRow>
    );
    if (!linkToParentVersion) {
      return currentVersionContent();
    }
    return <RouterLink to={linkToParentVersion}>{currentVersionContent(true)}</RouterLink>;
  };

  const hasErrorsIcon = useMemo(() => {
    if (hasErrors) {
      return <WarningIcon htmlColor={sandboxColor} />;
    }
  }, [hasErrors]);

  return (
    <Router history={history}>
      <Prompt
        when={propsNoChanges === false}
        message={onUnsavedChangesPrompt ?? 'Are you sure? Unsaved changes will be lost.'}
      />
      <StickyContainer className={containerClassNameReceived} data-testid='control-bar'>
        <Paper className={classes.root} elevation={1}>
          <div className={classes.wrapper}>
            {onGoBack && (
              <Link href={backURL}>
                <IconButton onClick={onGoBack} data-testid='go-back-button' size='large'>
                  <ChevronLeftIcon />
                </IconButton>
              </Link>
            )}

            <Typography
              className={classes.label}
              variant='h5'
              color={currentThemeMode === ThemeMode.Light ? 'primary' : 'text.primary'}
            >
              {label}
            </Typography>

            <Box data-testid='id-span' sx={{ display: 'flex' }}>
              {componentIdLabels}
            </Box>

            {isVersioned && !isCloned && (
              <LabelThemed
                onClick={handleVersionSelect}
                onMouseOver={handleVersionPickerOver}
                onMouseLeave={handleVersionPickerLeave}
                color={version !== undefined ? LabelThemedColor.Primary : LabelThemedColor.Grey}
                clickable={version !== undefined}
                className={highlightVersionPicker ? classes.pickerButtonHighlighted : classes.versionPicker}
                data-testid='select-version-btn'
                theme={versionPickerTheme}
                withoutMargins={{ right: false }}
              >
                {version !== undefined ? `v. ${version}` : 'Draft'}
                <ArrowDropDownIcon className={classes.pickerButtonIcon} />
              </LabelThemed>
            )}

            {refreshData?.shouldRefresh && (
              <IconButtonWithTooltip
                tooltipProps={{
                  title: refreshData.message,
                }}
                icon={<RefreshIcon />}
                disabled={refreshData.isRefreshDisabled}
                tooltipInitialFadeOut={true}
                onClick={refreshData.onRefresh}
                styles={{ tooltip: tooltipCSS }}
              />
            )}

            <div className={classes.logData}>
              {currentVersion && !isCloned && (
                <>
                  <div
                    className={cs(classes.logDataInfo, {
                      [classes.logDataInfoColumn as string]: !currentVersion.comment,
                    })}
                  >
                    <LogRow title={currentVersion.authorName}>
                      <AccountCircle className={classes.iconInline} color='secondary' />
                      <span>{currentVersion.authorName}</span>
                    </LogRow>

                    <LogRow title={currentVersion.updatedAt.toDateString()}>
                      <Update className={classes.iconInline} color='primary' />
                      <span>{formatDate(currentVersion.updatedAt)}</span>
                    </LogRow>
                  </div>
                  {getCurrentVersionSection()}
                </>
              )}
            </div>

            {canSearch && (
              <>
                <InputBase
                  data-testid='input-filter'
                  disabled={disabled}
                  startAdornment={
                    <IconButton className={classes.iconButton} aria-label='Search' disabled={true} size='large'>
                      <SearchIcon />
                    </IconButton>
                  }
                  endAdornment={
                    filter !== '' && (
                      <IconButton
                        className={classes.iconButton}
                        aria-label='Clear'
                        onClick={onSearchClear}
                        size='large'
                      >
                        <CloseIcon />
                      </IconButton>
                    )
                  }
                  value={filter}
                  className={classes.input}
                  placeholder='Search...'
                  onChange={onSearchChange}
                />

                <Divider className={classes.divider} />
              </>
            )}

            {content !== undefined && (
              <>
                <div className={classes.content}>{content}</div>
                {!withoutContentDivider && <Divider className={classes.divider} />}
              </>
            )}

            {withPromotion && version !== undefined && service && service.queryPromotions && entityId && (
              <ControlBarPromotionsPreview<ID>
                entityID={entityId}
                service={service as IBasePromotionService<ID>}
                version={promotionVersion}
                lastAvailableVersion={lastVersion}
                promotionResolverData={promotionResolverData}
                disabled={disabled}
                spaceless={spaceless}
                notificationLabel={notificationLabel}
                manifestName={manifestName}
                canWrite={canWrite}
                canWriteLive={canWriteLive}
                wasLive={wasLive}
                open={propSaving === undefined ? shouldOpenPromotions : shouldOpenPromotionsAfterSave}
                onChange={onPromotionsChange}
                afterModalOpen={afterModalOpen}
                setIsPromotedOnLive={setIsPromotedOnLive}
                onClose={handleClosePromotionsDialog}
              />
            )}

            {canSave && (
              <div>
                <Button
                  onClick={onSaveClick}
                  startIcon={hasErrorsIcon ?? (saving || processing ? <CircularProgress size={20} /> : <CheckIcon />)}
                  className={classes.button}
                  disabled={disabled || saving || processing || disabledSave || noChanges}
                  color={isCreating ? 'success' : 'primary'}
                  variant='contained'
                  data-testid='top-save-button'
                >
                  {isCreating ? 'Create' : 'Save'}
                </Button>

                {hasErrors && (
                  <Typography variant='body2' color='error' className={classes.error}>
                    Contains errors
                  </Typography>
                )}
              </div>
            )}

            {canReset && (
              <Button
                className={classes.button}
                disabled={disabled || saving || processing}
                onClick={onResetClick}
                data-testid='button-reset'
              >
                Reset
              </Button>
            )}
          </div>
        </Paper>
        {versionPickerNode}
      </StickyContainer>
    </Router>
  );
}
