import { styled } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTime } from 'luxon';
import { IWithCSS } from 'common/models';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

export const TimePickerStyled = styled(TimePicker<DateTime>)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  & .MuiInputBase-input {
    height: 8px;
    width: 85px;
  }
`;

export const StaticDatePickerStyled = styled(StaticDatePicker<DateTime>)<IWithCSS>`
  ${({ css }) => css}
`;
