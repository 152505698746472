import { FC } from 'react';

import { cssToSx } from '/helpers/cssToSx';

import { DefaultIcon, DefaultWrapper, iconBasicCSS, Text } from './styles';
import { IPlaceholder } from './IPlaceholder';

const PlaceholderComponent: FC<IPlaceholder> = ({
  icon: CustomIcon,
  actionButton: ActionButton,
  component: Component,
  text = 'No data to display',
  styles = {},
  hideIcon = false,
}) => {
  const { wrapper: wrapperCSS, icon: iconCSS } = styles;

  const icon = CustomIcon === undefined ? <DefaultIcon css={iconCSS} /> : <CustomIcon sx={cssToSx(iconBasicCSS)} />;

  return Component ? (
    <Component />
  ) : (
    <DefaultWrapper css={wrapperCSS}>
      {!hideIcon && icon}
      <Text>{text}</Text>
      {ActionButton && <ActionButton />}
    </DefaultWrapper>
  );
};

export const Placeholder = PlaceholderComponent;
