import { ReactNode, SyntheticEvent, useMemo, useState } from 'react';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { CircularProgress, ListItemIcon, MenuItem, Tooltip, Typography } from '@mui/material';

import { TableIconAction } from '/shared/Table/components/TableIconAction';
import {
  ACTION_ROW_CLASSNAME,
  ActionsCell,
  ActionsRowCellControls,
  ActionsRowCellSpinner,
  IBaseTableStyles,
  IconButtonStyled,
  ITableState,
  MenuStyled,
} from '/shared/Table';
import {
  IRowData,
  ITableRowAction,
  RenderRowActionFunction,
  RowActionClickEvent,
  StatusRowActionFunction,
} from '/shared/Table/interfaces';

export interface ITableRowActionsProps<D> extends ITableState<D> {
  rowData: IRowData<D>;
}

export interface ITableRowActionsComponentProps<D> {
  actions: ITableRowAction<D>[];
  rowData: IRowData<D>;
  handleClick?: (clickEvent: RowActionClickEvent<D>) => (event: SyntheticEvent) => void;
  actionsMenuStyles?: IBaseTableStyles['actionsMenu'];
}

function getPendingValue<D>(rowData: IRowData<D>, pending?: boolean | StatusRowActionFunction<D>): boolean {
  if (typeof pending === 'function') {
    return pending(rowData.item);
  }

  return !!pending;
}

function getHiddenValue<D>(rowData: IRowData<D>, hidden?: boolean | StatusRowActionFunction<D>): boolean {
  if (typeof hidden === 'function') {
    return hidden(rowData.item);
  }

  return !!hidden;
}

function getDisabledValue<D>(rowData: IRowData<D>, disabled?: boolean | StatusRowActionFunction<D>): boolean {
  if (typeof disabled === 'function') {
    return disabled(rowData.item);
  }

  return !!disabled;
}

function getTooltipValue<D>(
  rowData: IRowData<D>,
  tooltip?: string | RenderRowActionFunction<D> | ReactNode
): string | ReactNode {
  if (typeof tooltip === 'function') {
    return tooltip(rowData.item);
  }

  return tooltip;
}

function getLabelValue<D>(
  rowData: IRowData<D>,
  label?: string | RenderRowActionFunction<D> | ReactNode
): string | ReactNode {
  if (typeof label === 'function') {
    return label(rowData.item);
  }

  return label;
}

function TableRowActionsMenu<D>(props: ITableRowActionsComponentProps<D>) {
  const { actions, rowData, actionsMenuStyles } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    const htmlElement = event.currentTarget as HTMLElement;
    setAnchorEl(htmlElement);
  };

  const handleClose = (event: SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleSelect = (clickEvent: RowActionClickEvent<D>) => (event: SyntheticEvent) => {
    clickEvent(event, rowData.item);
    handleClose(event);
  };

  return (
    <>
      <Tooltip disableFocusListener={true} title={`Open ${actions.length} actions`}>
        <IconButtonStyled
          aria-label='More'
          aria-owns='long-menu'
          aria-haspopup='true'
          data-testid='table-row-actions-menu'
          onClick={handleClick}
          size='large'
        >
          <MoreVertIcon />
        </IconButtonStyled>
      </Tooltip>
      <MenuStyled
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        css={actionsMenuStyles}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {actions.map(({ label, disabled, icon: Icon, onClick, cy, key }: ITableRowAction<D>, idx: number) => (
          <MenuItem
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            key={key || idx}
            data-testid={cy}
            disabled={getDisabledValue(rowData, disabled)}
            selected={false}
            onClick={handleSelect(onClick)}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <Typography variant='inherit' noWrap={true}>
              {getLabelValue(rowData, label)}
            </Typography>
          </MenuItem>
        ))}
      </MenuStyled>
    </>
  );
}

function TableRowActionsList<D>({ actions, rowData }: ITableRowActionsComponentProps<D>) {
  const handleClick = (clickEvent: RowActionClickEvent<D>) => (event: SyntheticEvent) => {
    event.stopPropagation();
    clickEvent(event, rowData.item);
  };

  return (
    <>
      {actions.map(({ disabled, tooltip, onClick, key, ...rest }: ITableRowAction<D>, idx: number) => (
        <TableIconAction
          key={key ?? idx}
          {...rest}
          disabled={getDisabledValue(rowData, disabled)}
          tooltip={getTooltipValue(rowData, tooltip)}
          onClick={handleClick(onClick)}
        />
      ))}
    </>
  );
}

export function GenericTableRowActions<D>({ rowData, ...props }: ITableRowActionsProps<D>) {
  const { actions = [], actionsLength, options, styles } = props;
  const {
    actionsRowCell: actionsRowCellCSS,
    actionsRowCellControls: actionsRowCellControlsCSS,
    actionsMenu: actionsMenuCSS,
  } = styles ?? {};

  const someProcessing = useMemo<boolean>(() => {
    if (Array.isArray(actions)) {
      return actions.some((a: ITableRowAction<D>) => getPendingValue(rowData, a.pending));
    }
    return Boolean(actions);
  }, [actions, rowData]);

  const visibleActions = useMemo(() => {
    if (Array.isArray(actions)) {
      return actions.filter((a: ITableRowAction<D>) => !getHiddenValue(rowData, a.hidden));
    }
    return actions;
  }, [actions, rowData]);

  const [plainActions, menuActions] = useMemo(() => {
    if (Array.isArray(visibleActions) && visibleActions.length > actionsLength) {
      return [
        visibleActions.slice(0, actionsLength - 1),
        visibleActions.slice(actionsLength - 1, visibleActions.length),
      ];
    }

    return [visibleActions, []];
  }, [actionsLength, visibleActions]);

  const handleClick = (clickEvent: RowActionClickEvent<D>) => (event: SyntheticEvent) => {
    event.stopPropagation();

    clickEvent(event, rowData.item);
  };

  if (typeof actions === 'function') {
    return <>{actions(rowData.item)}</>;
  }

  if (!(actions instanceof Array)) {
    return <>{actions}</>;
  }

  return (
    <ActionsCell
      $withAdditionalColumn={options.withAdditionalColumn}
      className={ACTION_ROW_CLASSNAME}
      css={actionsRowCellCSS}
    >
      {someProcessing && (
        <ActionsRowCellSpinner>
          <CircularProgress />
        </ActionsRowCellSpinner>
      )}
      <ActionsRowCellControls $hidden={someProcessing} css={actionsRowCellControlsCSS}>
        <TableRowActionsList<D>
          actions={plainActions as ITableRowAction<D>[]}
          handleClick={handleClick}
          rowData={rowData}
        />
        {Boolean(menuActions.length) && (
          <TableRowActionsMenu actions={menuActions} rowData={rowData} actionsMenuStyles={actionsMenuCSS} />
        )}
      </ActionsRowCellControls>
    </ActionsCell>
  );
}
