import { ITableState, SkeletonTableRow, TableCellSkeleton } from '/shared/Table';

export function GenericTableSkeletonRows<D>(props: ITableState<D>) {
  const { renderColumnsCount, rowsPerPage } = props;

  const cols = () => {
    return Array.from(new Array(renderColumnsCount)).map((_, idx: number) => {
      return <TableCellSkeleton key={idx} />;
    });
  };

  const rows = () => {
    return Array.from(new Array(rowsPerPage)).map((_, idx: number) => {
      return <SkeletonTableRow key={idx}>{cols()}</SkeletonTableRow>;
    });
  };

  return <>{rows()}</>;
}
