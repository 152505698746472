import { useSelector } from 'react-redux';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';

import { openHelper } from '/common/Helper';
import { IRouteProps } from '/Router/models';
import { uiToolkit } from '/store';

import { HelperIconButton } from './styles';

export function HintEnhancer({ children, helperFile }: IRouteProps) {
  const isHint = useSelector(uiToolkit.selectors.isHints);

  if (!isHint) {
    return <>{children}</>;
  }

  const handleOpenHelper = () => openHelper({ path: helperFile });
  return (
    <>
      <HelperIconButton color='primary' onClick={handleOpenHelper} size='large'>
        <HelpOutlineIcon />
      </HelperIconButton>
      {children}
    </>
  );
}

HintEnhancer.canEnhanced = (props: IRouteProps): boolean => !!props.helperFile;
