import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Fade } from '@mui/material';

import { App } from '@playq/octopus2-apps';

import { uiToolkit, appToolkit } from '/store';
import { appNameKey } from '/constants';
import { IRouteProps } from '/Router/models';

import { SplashScreen, SplashScreenText, SplashScreenTitle } from './styles';

export function SplashScreenEnhancer({ children }: IRouteProps) {
  const isSplashScreen = useSelector(uiToolkit.selectors.isSplashScreen);
  const app: App = useSelector(appToolkit.selectors.app) as App;
  const navSidebarOpen = useSelector(uiToolkit.selectors.navSidebarOpen);
  const timer = 1500;

  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    let didCancel = false;
    setTimeout(() => {
      if (!didCancel) {
        setIsVisible(false);
      }
    }, timer);
    return () => {
      didCancel = true;
    };
  }, []);

  if (!isSplashScreen) {
    return <>{children}</>;
  }

  return (
    <>
      <Fade in={isVisible} timeout={1500}>
        <SplashScreen navSidebarOpen={navSidebarOpen}>
          <SplashScreenText>
            <Typography variant='h5' gutterBottom={true}>
              {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
              {app ? 'Working on' : 'Please, select an application first.'}
            </Typography>
            <SplashScreenTitle variant='h3'>
              {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
              {app ? app.name : 'Application is not selected'}
            </SplashScreenTitle>
          </SplashScreenText>
        </SplashScreen>
      </Fade>
      {children}
    </>
  );
}

SplashScreenEnhancer.canEnhanced = (props: IRouteProps): boolean =>
  // @ts-expect-error Element implicitly has an 'any' type because type '{}' has no index signature.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  !!(props.computedMatch?.params[appNameKey] && props.guard);
