import { FC, SyntheticEvent, useCallback, useMemo, useEffect } from 'react';

import {
  Space,
  SpacesFilterField,
  SpacesFilterFieldHelpers,
  SpacesSort,
  SpacesSortField,
  SpacesSortFieldHelpers,
} from '@playq/octopus2-apps';
import { TextFilter } from '@playq/octopus-common';

import { CollectionTable, ICollectionTableQuery, ICollectionTableQueryChange } from '/shared/Table';
import { createSpaceMutationKey, deleteSpaceMutationKey, updateSpaceMutationKey } from '/api';
import { UserName } from '/shared/UserName';

import { ISpaceTableProps, SpacesColumnType } from './types';
import { CheckCircleIcon, SpaceName } from './styles';

export const SpacesTable: FC<ISpaceTableProps> = (props) => {
  const {
    activeSpaceID,
    query,
    entitiesProcessing,
    pending,
    entities,
    total,
    queryKeys,
    actions,
    styles,

    setEnableQuerySpaces,
    onQueryChange,
    onEditSpace,
    onSelectSpace,
    refetch,
    isHidden,
    onSetColumns,
    onClose,
  } = props;

  const columns: SpacesColumnType[] = useMemo(
    () => [
      {
        label: 'Name',
        sort: SpacesSortField.Name,
        filter: SpacesFilterField.Name,
        filterType: TextFilter.ClassName,
        hidden: isHidden('Name'),
        render: (space: Space) => {
          const handleSelect = (e: SyntheticEvent) => {
            e.stopPropagation();
            onSelectSpace(space);
          };

          return (
            <>
              <SpaceName onClick={handleSelect}>{space.name}</SpaceName>
              {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
              {space.id && activeSpaceID && space.id.id === activeSpaceID.id && <CheckCircleIcon />}
            </>
          );
        },
      },
      {
        label: 'Description',
        hidden: isHidden('Description'),
        render: (space: Space) => {
          return space.description;
        },
      },
      {
        label: 'Created By',
        sort: SpacesSortField.AuthorName,
        filter: SpacesFilterField.AuthorName,
        filterType: TextFilter.ClassName,
        hidden: isHidden('Created By'),
        render: (space: Space) => <UserName id={space.createdBy} isModal={true} onClose={onClose} />,
      },
    ],
    [activeSpaceID, isHidden, onClose, onSelectSpace]
  );

  useEffect(() => {
    onSetColumns(columns);
    // no need to pass the onSetColumns to deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const isSpaceProcessing = useCallback((space: Space) => !!entitiesProcessing?.[space.id.id], [entitiesProcessing]);

  const handleRowClick = useCallback(
    (_e: SyntheticEvent, space: Space) => {
      if (isSpaceProcessing(space)) {
        return;
      }
      onEditSpace(space);
    },
    [isSpaceProcessing, onEditSpace]
  );

  function getRowKey(item: Space): string {
    return item.id.id;
  }

  function onTableQueryChange(q: ICollectionTableQueryChange<ICollectionTableQuery<SpacesSort>>) {
    onQueryChange(q.query);
  }

  return (
    <CollectionTable<Space, SpacesSortField, SpacesFilterField, SpacesSort, ICollectionTableQuery<SpacesSort>>
      pending={pending}
      columns={columns}
      actions={actions}
      getRowKey={getRowKey}
      initialQuery={query}
      data={entities}
      total={total}
      queryKeys={queryKeys}
      mutationsKeys={{
        currentPage: [createSpaceMutationKey, updateSpaceMutationKey],
        restPages: [createSpaceMutationKey, updateSpaceMutationKey, deleteSpaceMutationKey],
      }}
      options={{ withToolbar: false }}
      styles={styles}
      sortHelper={SpacesSortFieldHelpers}
      filterHelper={SpacesFilterFieldHelpers}
      sortClass={SpacesSort}
      onQueryChange={onTableQueryChange}
      refetch={refetch}
      onRowClick={handleRowClick}
      setEnableQueryEntities={setEnableQuerySpaces}
    />
  );
};
