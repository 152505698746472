import { FC, FormEvent, ChangeEvent, MouseEvent, memo, useMemo, useState } from 'react';
import {
  Popover,
  List,
  ListItem,
  TextField,
  IconButton,
  ButtonGroup,
  Button,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputAdornment,
  Typography,
  FormControl,
} from '@mui/material';
import { ArrowDropDown, Add, ArrowUpward, ArrowDownward } from '@mui/icons-material';

import { MetaValueWithCounter } from '@playq/services-beetle';
import { ValueWithCounter } from '@playq/octopus2-files';

import { useDebounce } from '/hooks/useDebounce';

import { IDirectionToSort, IKeyToSort } from '../types';

import { ITagsSelectProps } from './types';
import { Wrapper, WrapperSort } from './styles';
import { sortStrategies } from './helpers';

export const TagsSelect: FC<ITagsSelectProps> = memo(
  ({ onSelect, tag, mainColor, selected, canSelectKey, className }) => {
    const [typeSort, setTypeSort] = useState({ keyToSort: IKeyToSort.NUMBER, direction: IDirectionToSort.DESC });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue, 300);

    const memoizedSortStrategies = useMemo(() => sortStrategies(typeSort.direction), [typeSort.direction]);

    const values = useMemo(() => {
      const sortStrategy = memoizedSortStrategies[typeSort.keyToSort];

      const validKeys = Object.keys(memoizedSortStrategies) as IKeyToSort[];
      if (!validKeys.includes(typeSort.keyToSort)) {
        throw new Error(`Invalid sort key: ${typeSort.keyToSort}`);
      }

      return [...tag.values].sort(sortStrategy);
    }, [tag, typeSort, memoizedSortStrategies]);

    const filteredValues: (MetaValueWithCounter | ValueWithCounter)[] = useMemo(() => {
      return debouncedInputValue.length
        ? values.filter((t) => t.value.toLowerCase().includes(debouncedInputValue.toLowerCase()))
        : values;
    }, [values, debouncedInputValue]);

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (values.length && !canSelectKey) {
        handleArrowClick(e);
      } else {
        onSelect(tag.key);
      }
    };

    const handleArrowClick = (e: MouseEvent<HTMLButtonElement>) => {
      setInputValue('');
      setAnchorEl(e.currentTarget);
    };

    const handleSelectValue = (value: string) => () => {
      onSelect(tag.key, value);
      handleClose();
    };

    const handleSubmitForm = (e: FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (inputValue.trim().length) {
        onSelect(tag.key, inputValue);
        handleClose();
      }
    };

    const handleChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleChange = (event: SelectChangeEvent) => {
      setTypeSort((prevState) => ({
        ...prevState,
        keyToSort: event.target.value as IKeyToSort,
      }));
    };
    const changeDirectionSort = () => {
      return setTypeSort((prevState) => ({
        ...prevState,
        direction: prevState.direction === IDirectionToSort.ASC ? IDirectionToSort.DESC : IDirectionToSort.ASC,
      }));
    };
    return (
      <Wrapper className={className} selected={selected} mainColor={mainColor}>
        <ButtonGroup variant='contained' color='primary'>
          <Button onClick={handleClick} variant='contained' color='primary' size='small' data-testid={tag.key}>
            {decodeURI(tag.key)}
            {!canSelectKey && !!values.length && <ArrowDropDown fontSize='small' />}
          </Button>
          {(canSelectKey || !values.length) && (
            <Button onClick={handleArrowClick} variant='contained' color='primary' size='small'>
              <ArrowDropDown fontSize='small' />
            </Button>
          )}
        </ButtonGroup>
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box component={List} fontSize={16}>
            <ListItem onSubmit={handleSubmitForm} component='form'>
              <TextField
                value={inputValue}
                onChange={handleChangeInputValue}
                autoFocus={true}
                placeholder='Enter value'
              />
              <IconButton disabled={!inputValue.length} size='small' type='submit'>
                <Add />
              </IconButton>
            </ListItem>
            <WrapperSort>
              <FormControl>
                <Select
                  id='simple-select-sort'
                  value={typeSort.keyToSort}
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleChange}
                  startAdornment={
                    <InputAdornment position='start'>
                      <Typography component='span'>Sort By: </Typography>
                    </InputAdornment>
                  }
                >
                  <MenuItem value={IKeyToSort.COUNT}>Count</MenuItem>
                  <MenuItem value={IKeyToSort.NUMBER}>Number</MenuItem>
                  <MenuItem value={IKeyToSort.ABC}>ABC</MenuItem>
                  <MenuItem value={IKeyToSort.DATE}>Date</MenuItem>
                </Select>
              </FormControl>
              <IconButton onClick={changeDirectionSort} data-testid={`move-direction-${typeSort.direction}-button`}>
                {typeSort.direction === IDirectionToSort.ASC ? <ArrowUpward /> : <ArrowDownward />}
              </IconButton>
            </WrapperSort>
            {filteredValues.map((val) => (
              <ListItem key={val.value} onClick={handleSelectValue(val.value)} button={true}>
                {val.value}
              </ListItem>
            ))}
          </Box>
        </Popover>
      </Wrapper>
    );
  }
);
