import { Button, ButtonStyles, StyledDialogActions, setColor } from '../styles';
import { ConfirmDialogActionsProps } from '../types';

export const ConfirmDialogActions = (props: ConfirmDialogActionsProps) => {
  const { type, closeButton, successButton, onClose, onSuccess } = props;

  const closeButtonLabel = closeButton?.label ?? 'Close';
  const successButtonLabel = successButton?.label ?? 'Ok';

  const colorProps = setColor(type);

  return (
    <StyledDialogActions>
      {props.closeButton !== null && (
        <Button onClick={onClose} color={props.closeButton?.color ?? 'primary'} data-testid='confirm-dialog-cancel'>
          {closeButtonLabel}
        </Button>
      )}
      {props.successButton !== null && (
        <ButtonStyles
          {...colorProps}
          onClick={onSuccess}
          color={props.successButton?.color ?? 'primary'}
          data-testid='confirm-dialog-submit'
        >
          {successButtonLabel}
        </ButtonStyles>
      )}
    </StyledDialogActions>
  );
};
