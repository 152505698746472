import { FC, useState, useEffect } from 'react';
import { IconButton, Slide } from '@mui/material';
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import { Subject } from 'rxjs';

import { Markdown } from '/shared/Markdown';

import { HelperCard, HelperCardContent, HelperCardHeader, StyledHelper } from './styles';

export interface IHelperProps {
  path?: string;
  onClose?: () => void;
}

export interface IHelperWrapperProps extends IHelperProps {
  open: boolean;
}

export interface IHelperContentProps {
  navSidebarOpen: boolean;
}

const helperSubject = new Subject<IHelperWrapperProps>();

export const openHelper = (props: IHelperProps) => {
  helperSubject.next({ ...props, open: true });
};

export const Helper: FC<IHelperContentProps> = ({ navSidebarOpen }) => {
  const [props, setProps] = useState<IHelperWrapperProps>({
    open: false,
  });
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const sub = helperSubject.subscribe((nextProps: IHelperWrapperProps) => setProps(nextProps));
    return () => sub.unsubscribe();
  }, []);

  const toggleFullMode = () => setExpanded(!expanded);
  const handleClose = () => {
    setProps({
      ...props,
      open: false,
    });

    if (props.onClose) {
      props.onClose();
    }

    setExpanded(false);
  };

  const expandIcon = expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />;

  return (
    <Slide direction='up' in={props.open}>
      <StyledHelper navSidebarOpen={navSidebarOpen}>
        <HelperCard expanded={expanded}>
          <HelperCardHeader
            title={
              <>
                <HelpOutlineIcon color='primary' />
                <span>Helpful info</span>
              </>
            }
            action={
              <>
                <IconButton onClick={toggleFullMode} size='large'>
                  {expandIcon}
                </IconButton>
                <IconButton onClick={handleClose} size='large'>
                  <CloseIcon />
                </IconButton>
              </>
            }
          />
          <HelperCardContent expanded={expanded}>{props.path && <Markdown path={props.path} />}</HelperCardContent>
        </HelperCard>
      </StyledHelper>
    </Slide>
  );
};
