import { styled, Link, Toolbar, Divider, Typography } from '@mui/material';

export const headerHeight = 48;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  overflow-x: auto;
`;

export const AppInfo = styled('div')`
  display: flex;
  align-items: center;
`;

export const StyledNav = styled('nav')`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const StyledDivider = styled(Divider)`
  height: 38px;
  margin: 0 4px;
  background-color: white;
`;

export const AppName = styled(Typography)`
  margin-left: 5px;
`;

export const AppIconWrapper = styled('div')(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

export const LinkToHome = styled(Link)`
  color: white;
  &:hover {
    text-decoration: none;
    color: white;
  }
`;
