import { ReactNode } from 'react';
import * as polished from 'polished';
import { Box, styled, Toolbar as MatToolbar, Typography } from '@mui/material';
import { ToolbarProps } from '@mui/material/Toolbar';
import { IWithCSS } from 'common/models';

import { ITableState } from '/shared/Table';

type StyledToolbarProps = ToolbarProps & { count: number };

type TableToolbarProps<D> = ITableState<D> & {
  content?: ReactNode;
};

const StyledToolbar = styled(MatToolbar)<StyledToolbarProps>`
  padding-right: ${({ theme }) => theme.spacing()}px;
  margin-bottom: ${({ theme }) => theme.spacing()}px;
  background-color: ${({ count, theme }) =>
    count > 0 ? polished.lighten('0.3', theme.palette.secondary.main) : 'transparent'};
`;
export const ToolbarWrapper = styled('div')<IWithCSS>`
  display: flex;

  ${({ css }) => css}
`;

export function GenericTableToolbar<D>(props: TableToolbarProps<D>) {
  const { options, selectedRowsCount, toolbarTitle, components, styles } = props;
  const { withAnyFilter, withColumnSelection } = options;
  const { AnyFilter, ColumnSelection, ToolbarActions } = components;
  const { toolbarAnyWrapper: toolbarAnyWrapperCSS } = styles ?? {};

  return (
    <StyledToolbar count={selectedRowsCount}>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexGrow: 1,
        }}
      >
        <Typography variant='h5'>
          {selectedRowsCount > 0 ? `${selectedRowsCount} row(s) selected` : toolbarTitle || null}
        </Typography>
        {props.content}
      </Box>
      <ToolbarWrapper css={toolbarAnyWrapperCSS}>
        {withAnyFilter && <AnyFilter {...props} />}
        {withColumnSelection && <ColumnSelection {...props} />}
        <ToolbarActions {...props} />
      </ToolbarWrapper>
    </StyledToolbar>
  );
}
