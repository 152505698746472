export const sortDate = (from: string, to: string): number => {
  const regex = /\d{1,2}\/\d{1,2}\/\d{2,4}/g;
  const isValidDate = from.match(regex) && to.match(regex) ? true : false;
  if (isValidDate) {
    return +new Date(from) - +new Date(to);
  }
  return 0;
};

//format matched 0.1.0
export const isValidSemanticVersion = (value: string): boolean => {
  const versionPattern = /^\d+\.\d+\.\d+$/;
  return versionPattern.test(value);
};
export const sortVersionedValues = (a: string, b: string): number => {
  if (isValidSemanticVersion(a) && isValidSemanticVersion(b)) {
    const [majorA, minorA, patchA] = a.split('.').map(Number);
    const [majorB, minorB, patchB] = b.split('.').map(Number);

    if (majorA !== majorB) return majorB - majorA;
    if (minorA !== minorB) return minorB - minorA;
    return patchB - patchA;
  }
  return 0;
};
