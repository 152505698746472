import { FC, SyntheticEvent, useEffect, useReducer } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DeleteForever as DeleteForeverIcon,
  MoreVert as MoreVertIcon,
  Notifications as NotificationsIcon,
  NotificationsNone as NotificationsNoneIcon,
  SentimentDissatisfied as SentimentDissatisfiedIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import { handleDeleteAllNotifications } from '/common/Header/Notification/helpers';
import {
  SNACKBAR_STORAGE_MAX_SIZE,
  snackbarStorage,
  snackbarStorageKey,
} from '/storage/sessionStorage/snackBarHistory';
import { IDefaultSnackBarHistoryItem } from '/common/SnackBar';
import { notificationsToolkit } from '/store';
import { useMultipleModals } from '/hooks';

import { SnackbarNtf } from './SnackbarNtf';
import { NotificationChip, NotificationMenu, StyledEmptyListStub, StyledLimitMessage, StyledMenu } from './styles';

export const Notification: FC = () => {
  const { anchors, checkAnchorElOpen, addAnchorEl, clearAnchorEl } = useMultipleModals<
    'anchorEl' | 'notificationMenuAnchorEl'
  >();

  const anchorEl = anchors.get('anchorEl');
  const notificationMenuAnchorEl = anchors.get('notificationMenuAnchorEl');

  //Need to force update to fix the issue with the list not updating after deleting notifications
  //As we use sessionStorage React can't automatically trace changes in it
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

  const haveUnreadNotifications = useSelector(notificationsToolkit.selectors.haveUnreadNotifications);
  const dispatch = useDispatch();

  const notificationID = 'snackbar-notification';
  const notificationSettings = 'snackbar-settings';

  const snackbarHistoryLogs: IDefaultSnackBarHistoryItem[] = JSON.parse(
    snackbarStorage.get(snackbarStorageKey) || '[]'
  ) as IDefaultSnackBarHistoryItem[];

  const emptySnackbarHistoryLogs = snackbarHistoryLogs.length <= 0;
  const maxSizeLogReached = snackbarHistoryLogs.length >= SNACKBAR_STORAGE_MAX_SIZE;

  useEffect(
    function markNotificationRead() {
      if (anchorEl) {
        dispatch(notificationsToolkit.actions.setUnreadNotifications(false));
      }
    },
    [anchorEl, dispatch]
  );

  const handleCloseNotifications = (event?: unknown) => {
    if ((event as SyntheticEvent & { metaKey?: boolean })?.metaKey) {
      return;
    }
    clearAnchorEl('anchorEl');
  };

  const handleCloseNotificationsMenu = (event?: unknown) => {
    if ((event as SyntheticEvent & { metaKey?: boolean })?.metaKey) {
      return;
    }
    clearAnchorEl('notificationMenuAnchorEl');
  };

  const notificationForceUpdate = () => {
    forceUpdate();
  };

  const handleDeleteNotifications = () => {
    handleDeleteAllNotifications();
    handleCloseNotificationsMenu();
  };

  const menu = (
    <NotificationMenu>
      <Tooltip title='More'>
        <IconButton
          aria-owns={anchorEl ? notificationSettings : undefined}
          aria-haspopup='true'
          aria-label='Notification'
          onClick={addAnchorEl('notificationMenuAnchorEl')}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <MuiMenu
        id={notificationSettings}
        anchorEl={notificationMenuAnchorEl}
        open={checkAnchorElOpen('notificationMenuAnchorEl')}
        onClose={handleCloseNotificationsMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MuiMenuItem onClick={handleDeleteNotifications}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize='small' />
          </ListItemIcon>

          <ListItemText>Delete all notifications</ListItemText>
        </MuiMenuItem>
      </MuiMenu>
    </NotificationMenu>
  );

  const emptyListStub = (
    <StyledEmptyListStub>
      <SentimentDissatisfiedIcon sx={{ fontSize: '4em' }} />
      <Typography sx={{ marginBottom: '15px' }} variant='body1' align={'center'}>
        List is empty
      </Typography>
    </StyledEmptyListStub>
  );

  return (
    <>
      <IconButton
        color='inherit'
        aria-owns={anchorEl ? notificationID : undefined}
        aria-haspopup='true'
        aria-label='Notification'
        onClick={addAnchorEl('anchorEl')}
        size='large'
        sx={{ position: 'relative' }}
        data-testid={haveUnreadNotifications ? 'has-unread-notifications' : undefined}
      >
        {haveUnreadNotifications && <NotificationChip />}
        {emptySnackbarHistoryLogs && !haveUnreadNotifications ? <NotificationsNoneIcon /> : <NotificationsIcon />}
      </IconButton>
      <StyledMenu
        id={notificationID}
        anchorEl={anchorEl}
        open={checkAnchorElOpen('anchorEl')}
        onClose={handleCloseNotifications}
        variant={'menu'}
        $maxLimit={maxSizeLogReached}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menu}
        <Typography variant='h6' align={'center'}>
          Snackbar history
        </Typography>

        {emptySnackbarHistoryLogs && emptyListStub}
        {snackbarHistoryLogs.reverse().map((notification) => (
          <SnackbarNtf forceUpdate={notificationForceUpdate} key={notification.timestamp} notification={notification} />
        ))}

        {maxSizeLogReached && (
          <StyledLimitMessage>
            <Typography variant='body2' align={'center'}>
              Notification limit reached, the list is limited to {SNACKBAR_STORAGE_MAX_SIZE} notifications.
            </Typography>
            <Typography variant='body2' align={'center'}>
              If necessary, old notifications will be automatically deleted and replaced with new ones.
            </Typography>
            <Typography variant='body2' align={'center'} sx={{ opacity: 0.7 }}>
              p.s. You can also clear the list by yourself.
            </Typography>
          </StyledLimitMessage>
        )}
      </StyledMenu>
    </>
  );
};
