import { ReactNode } from 'react';
import { DialogProps } from '@mui/material/Dialog';

type IDialogProps = Omit<DialogProps, 'title' | 'open' | 'onClose' | 'children'>;

export enum ConfirmDialogType {
  Info = 'Info',
  Error = 'Error',
  Warning = 'Warning',
}

export interface IConfirmProps {
  type?: ConfirmDialogType;
  title?: string;
  text?: ReactNode;
  closeButton?: {
    label: string;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    onClick?: (cb: () => void) => void;
  } | null;
  onClose?: () => void;
  successButton?: {
    label: string;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    onClick?: (cb: () => void) => void;
  } | null;
  onSuccess?: () => void;
  afterClose?: () => void;
  withoutActions?: boolean;
  dialogProps?: IDialogProps;
}

export interface IConfirmDialogProps extends IConfirmProps {
  open: boolean;
}

export type ConfirmDialogHeaderProps = Pick<IConfirmProps, 'title' | 'type'>;

export type ConfirmDialogActionsProps = Pick<
  IConfirmProps,
  'closeButton' | 'successButton' | 'onClose' | 'onSuccess' | 'type'
>;

export type ConfirmDialogContentProps = Pick<IConfirmProps, 'text'>;
