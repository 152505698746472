import { IconButton, styled, Theme } from '@mui/material';
import { blueGrey, red } from '@mui/material/colors';
import { PhotoCamera, PlayCircleOutline } from '@mui/icons-material';
import { FiPackage } from 'react-icons/fi';

import { IThemeDependentStyledComponentProps } from '/common/models';

export const ImageStyled = styled('img')`
  display: flex;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  height: inherit;
  width: inherit;
  object-fit: contain;
`;

export const RebuildThumbIconStyled = styled(IconButton)<IThemeDependentStyledComponentProps>`
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  background: ${blueGrey[500]};
  padding: 5px;
  z-index: ${({ $theme }: IThemeDependentStyledComponentProps) => ($theme as Theme).zIndex.tooltip};

  &:hover {
    background: ${red[500]};
  }
`;

const iconStyles = `
  width: calc(100% - 25%);
  height: calc(100% - 25%);
`;

export const PhotoCameraIcon = styled(PhotoCamera)`
  ${iconStyles}
` as typeof PhotoCamera;

export const PackageIcon = styled(FiPackage)`
  ${iconStyles}
`;

export const PlayButtonStyled = styled(PlayCircleOutline)(({ theme }) => ({
  position: 'absolute',
  cursor: 'pointer',
  height: '75%',
  width: '75%',
  color: theme.palette.action.selected,
  '&:hover': {
    color: theme.palette.action.active,
  },
}));
