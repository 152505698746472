import { isValidSemanticVersion, sortDate, sortVersionedValues } from '/helpers/sortTagsSelect';

import { IKeyToSort, IDirectionToSort } from '../types';

import { SortFunction } from './types';

export const sortStrategies = (typeDirection: string): Record<IKeyToSort, SortFunction> => ({
  [IKeyToSort.COUNT]: (a, b) => (typeDirection === IDirectionToSort.ASC ? b.count - a.count : a.count - b.count),
  [IKeyToSort.NUMBER]: (a, b) => {
    const valueA = a.value;
    const valueB = b.value;
    if (isValidSemanticVersion(valueA) && isValidSemanticVersion(valueB)) {
      return typeDirection === IDirectionToSort.ASC
        ? sortVersionedValues(valueB, valueA)
        : sortVersionedValues(valueA, valueB);
    }
    const numA = parseFloat(valueA);
    const numB = parseFloat(valueB);

    return typeDirection === IDirectionToSort.ASC ? numA - numB : numB - numA;
  },
  [IKeyToSort.ABC]: (a, b) =>
    typeDirection === IDirectionToSort.ASC ? a.value.localeCompare(b.value) : b.value.localeCompare(a.value),
  [IKeyToSort.DATE]: (a, b) =>
    typeDirection === IDirectionToSort.ASC ? sortDate(b.value, a.value) : sortDate(a.value, b.value),
});
