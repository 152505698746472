import { styled } from '@mui/material/styles';
import { DialogContent, IconButton } from '@mui/material';

import { getOppositeColor } from '/helpers';

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: getOppositeColor(theme.palette.primary.main),
}));

export const TableContainer = styled(DialogContent)({
  marginTop: '100px',
});
