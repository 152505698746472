import { Link } from 'react-router-dom';
import { ListItemText, styled } from '@mui/material';

export const StyledListItemText = styled(ListItemText)`
  text-transform: none;
`;

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.text.primary,
  },
}));
