import { FC, MouseEvent } from 'react';
import { Lock as LockIcon, LockOpen as LockOpenIcon } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';

import { IFingerprintSaltsValid } from './types';
import { AppSaltsButton, AppSaltsInfoIcon } from './styles';

interface IAppSaltsLabelProps {
  openSalts: boolean;
  setOpenSalts: () => void;
  fingerprintSaltsValid: IFingerprintSaltsValid;
  readonly: boolean;
}

const AppSaltsLabel: FC<IAppSaltsLabelProps> = (props) => {
  const { openSalts, setOpenSalts, fingerprintSaltsValid, readonly } = props;
  let invalidFingerprintSaltsEnv: 'sandbox' | 'live' | undefined;

  if (fingerprintSaltsValid.sandbox) {
    invalidFingerprintSaltsEnv = 'sandbox';
  } else if (fingerprintSaltsValid.live) {
    invalidFingerprintSaltsEnv = 'live';
  }

  const handleOpenSalts = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!readonly) {
      setOpenSalts();
    }
  };

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      {invalidFingerprintSaltsEnv && (
        <Tooltip
          placement='top'
          title={`Salts mismatch Fingerprint clientSideSecret for ${invalidFingerprintSaltsEnv} environment.`}
        >
          <AppSaltsInfoIcon color='error' />
        </Tooltip>
      )}

      <b>Salts</b>
      <AppSaltsButton color='inherit' disabled={readonly} onClick={handleOpenSalts} size='small'>
        {openSalts ? <LockOpenIcon fontSize='small' /> : <LockIcon fontSize='small' />}
      </AppSaltsButton>
    </Stack>
  );
};

export default AppSaltsLabel;
