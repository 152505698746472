import { UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { SpaceID, AppID } from '@playq/octopus-common';
import { GenericFailure } from '@playq/services-shared';
import { AppSpacePromotions } from '@playq/octopus2-apps-utility';

import { snackbarService } from '/common/snackbarService';
import { useEitherQuery } from '/api/service-hooks';
import { services2 } from '/api/services2';
import { appToolkit } from '/store';

export const useAppSpacePromotions = (
  id?: SpaceID,
  options?: UseQueryOptions<AppSpacePromotions | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const keys = ['spacesPromotionsService', 'appSpacePromotions', appID?.company, appID?.id, id?.id];

  return useEitherQuery(keys, () => services2.spacesPromotionsService.appSpacePromotions(appID as AppID, id), {
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
    enabled: options?.enabled !== false && !!appID,
  });
};
