/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentProps, ReactNode, SyntheticEvent, useCallback, useContext, useMemo } from 'react';
import { Box, Theme, Tooltip } from '@mui/material';

import { Id, IdWrapper, IRowData, ITableColumn, ITableState, MuiTableRowStyled, TruncatedCell } from '/shared/Table';
import { clipboardWrite } from '/helpers';
import { ThemeModeContext } from '/common';
import { IThemeModeContext } from '/common/models';
import { snackbarService } from '/common/snackbarService';
import { STATUS_CELL_MIN_WIDTH } from '/shared/Table/constants';

export interface ITableRowProps<D> extends ITableState<D>, ComponentProps<any> {
  rowData: IRowData<D>;
  overrideProps?: ComponentProps<any>;
}

export function GenericTableRow<D>({ rowData, overrideProps, ...props }: ITableRowProps<D>) {
  const {
    options,
    actions,
    components,
    onSelection,
    columns,
    hiddenColumns,
    details,
    onRowClick,
    onDetailsChange,
    getEntityURL,
    disableSelection,
  } = props;

  const { item, detailsOpen, id, selected } = rowData;

  const handleClickIdCell = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const textContent = (e?.target as unknown as { textContent: string | undefined })?.textContent?.trim();

    if (textContent === undefined) {
      return;
    }

    clipboardWrite(textContent);
    snackbarService.info(`${textContent} copied to clipboard!`);
  };

  const getCellValue = useCallback(
    (rData: IRowData<D>, { render, label, customRender, truncatedText }: ITableColumn<D>) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!render) {
        return;
      }
      const renderCellValue =
        typeof render === 'function'
          ? render(rData.item, rData)
          : (rData.item as { render: string | ReactNode }).render;

      const isIdCell = label?.toLowerCase() === 'id';
      const isStatusCell = label?.toLowerCase() === 'status';

      if (truncatedText) {
        return <TruncatedCell title={truncatedText}>{renderCellValue}</TruncatedCell>;
      }

      if (!isIdCell || label === undefined || customRender) {
        return renderCellValue;
      }

      if (isStatusCell) {
        return (
          <Box component='div' sx={{ minWidth: `${STATUS_CELL_MIN_WIDTH}px` }}>
            {renderCellValue}
          </Box>
        );
      }

      return (
        <Box component='div' sx={{ display: 'grid' }}>
          <IdWrapper>
            <Tooltip placement='top' title='Click to copy ID to clipboard'>
              <Id onClick={handleClickIdCell}>{renderCellValue}</Id>
            </Tooltip>
          </IdWrapper>
        </Box>
      );
    },
    []
  );

  const handleSelection = (isSelected: boolean) => {
    onSelection(rowData, isSelected);
  };

  const handleRowCmdClick = () => {
    if (getEntityURL === undefined) {
      return;
    }

    const url = getEntityURL(item);
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleRowClick = (event: SyntheticEvent) => {
    if (onRowClick) {
      if ((event as SyntheticEvent & { metaKey: boolean }).metaKey && getEntityURL) {
        handleRowCmdClick();
        return;
      }
      onRowClick(event, item);
    }

    if (details) {
      onDetailsChange(rowData, !detailsOpen);
    }
  };

  const isColumnHidden = useCallback(
    (column: ITableColumn<D>) => {
      return hiddenColumns.some((hiddenColumn) => hiddenColumn === column.label);
    },
    [hiddenColumns]
  );

  const { Cell } = components;
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const isActionsExist: boolean = useMemo(() => (actions instanceof Array ? actions.length > 0 : !!actions), [actions]);

  const rowCells = useMemo(() => {
    const columnsToRender = columns.filter((column) => !isColumnHidden(column));
    const lastIdx = columnsToRender.length - 1;

    return columnsToRender.map((column: ITableColumn<D>, cellIdx: number) => {
      const shouldShowActions = !options.withAdditionalColumn && isActionsExist && cellIdx === lastIdx;

      return (
        <Cell key={cellIdx} columnCellStyles={column.styles?.cell} columnHidden={column.hidden} {...props}>
          {getCellValue(rowData, column)}
          {shouldShowActions && <components.RowActions rowData={rowData} {...props} />}
        </Cell>
      );
    });
  }, [
    columns,
    isColumnHidden,
    options.withAdditionalColumn,
    isActionsExist,
    Cell,
    props,
    getCellValue,
    rowData,
    components,
  ]);

  const themeModeContext: IThemeModeContext = useContext(ThemeModeContext);
  const theme: Theme = themeModeContext.getCurrentTheme();

  const style = props.options.transparentRow
    ? { background: 'transparent' }
    : id % 2
      ? { background: '#00000005' }
      : { background: theme.palette.action.hover };

  return (
    <MuiTableRowStyled
      style={style}
      hover={!!onRowClick || !!details}
      selected={selected}
      onClick={handleRowClick}
      {...overrideProps}
    >
      {options.withSelection && (
        <components.SelectionRowCell
          initialSelection={selected}
          isSelectionDisabled={disableSelection?.(item)}
          onSelect={handleSelection}
          {...props}
        />
      )}
      {rowCells}
      {options.withAdditionalColumn && (
        <Cell {...props}>
          <components.RowActions rowData={rowData} {...props} />
        </Cell>
      )}
    </MuiTableRowStyled>
  );
}
