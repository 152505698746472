import { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react';
import { Chip, styled, Tooltip } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';

import { ContainsFilter } from '@playq/octopus-common';

import { IFilterCellProps } from '/shared/Table/components/TableHeadCell/IFilterCellProps';
import { FilterClearButton, FilterForm, FilterTextFieldValidator } from '/shared/Table/components/TableHeadCell/styles';
import { FilterData } from '/shared/Table/interfaces/FilterData';

type ParsedFilterDataType = { [key: string]: string };

const SelectedWrapper = styled('div')`
  border-bottom: 2px solid ${({ theme }) => theme.palette.text.primary};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  max-width: ${({ theme }) => theme.spacing(100)};
  display: flex;
  overflow-x: scroll;
`;

/**
 * @param filterData should be a string[] with every child is a stringified object with required key 'id'
 */

const prefix = 'ID: ';

const parseData = (data?: FilterData): ParsedFilterDataType[] => {
  if (!data) {
    return [];
  }
  return data
    .map((item) => {
      try {
        return JSON.parse(String(item)) as ParsedFilterDataType;
      } catch (_e) {
        console.error('Invalid filter data');
      }
    })
    .filter((item) => item?.id !== undefined) as ParsedFilterDataType[];
};

const getInfo = (id: string, data: ParsedFilterDataType[]) => {
  const selectedItem = data.find((item) => item.id === id);
  if (selectedItem === undefined) {
    return `ID: ${id}`;
  }
  const selectedItemEntries = Object.entries(selectedItem);
  return (
    <>
      {selectedItemEntries.map(([key, value]) => (
        <p key={key + value}>
          {key.toUpperCase()}: {value}
        </p>
      ))}
    </>
  );
};

export function ContainsFilterCell({
  filter,
  filterData,
  currentTheme,
  onSubmit,
  onClear,
  validate,
}: IFilterCellProps) {
  const [value, setValue] = useState<string>('');

  const selected = useMemo(() => {
    if (!filter) {
      return [];
    }
    return (filter as ContainsFilter).values;
  }, [filter]);

  const convertedFilterData = useMemo(() => parseData(filterData), [filterData]);

  const errorMsg = useMemo(() => (!validate ? undefined : validate(value)), [value, validate]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = (id: string | string[]) => {
    const newValues = selected.concat(id);

    const nContainsFilter = new ContainsFilter({ values: newValues });
    onSubmit(nContainsFilter, true);
  };

  const handleEnter = (e: KeyboardEvent) => {
    if (e.key !== 'Enter' || !value || !!errorMsg) {
      return;
    }

    handleSubmit(value);
  };

  const handleDelete = (valueToRemove: string) => () => {
    const newFilter = new ContainsFilter();
    const newValues = selected.filter((selectedValue) => selectedValue !== valueToRemove);
    newFilter.values = newValues;
    if (!newValues.length) {
      onClear();
      return;
    }
    onSubmit(newFilter, false);
  };

  return (
    <FilterForm>
      {selected.length > 0 && (
        <SelectedWrapper>
          {selected.map((selectedValue) => {
            const shortID = selectedValue.slice(0, 5);
            const shortTitle = selectedValue.length > 5 ? prefix + shortID + `\u2026` : prefix + shortID;
            return (
              <Tooltip placement='top' title={getInfo(selectedValue, convertedFilterData)} key={selectedValue}>
                <Chip label={shortTitle} onDelete={handleDelete(selectedValue)} color='primary' />
              </Tooltip>
            );
          })}
        </SelectedWrapper>
      )}
      <FilterTextFieldValidator
        fullWidth={true}
        value={value}
        onChange={handleChange}
        onKeyPress={handleEnter}
        autoFocus={true}
        $theme={currentTheme}
        placeholder='Enter the ID'
        InputProps={{
          endAdornment: (
            <FilterClearButton
              onClick={onClear}
              show={!!value}
              aria-label='Clear Filter'
              title='Clear Filter'
              data-testid='clear-filter'
            >
              <ClearIcon color='disabled' />
            </FilterClearButton>
          ),
        }}
        error={!!errorMsg}
        errorMsg={errorMsg}
      />
    </FilterForm>
  );
}

ContainsFilterCell.type = ContainsFilter.ClassName;
