import { styled } from '@mui/material';

import { collapsedNavSidebarWidth } from '/constants';

import { headerHeight } from '../Header/styles';

export const APP_CONTENT_PADDING_PX = 15;

export const AppContent = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - ${collapsedNavSidebarWidth}px);
  min-width: 0;
  flex: 1;
  min-height: calc(100vh - ${headerHeight}px);
  overflow: clip;
  margin-top: ${headerHeight}px;
  padding: ${APP_CONTENT_PADDING_PX}px;
  ${({ theme }) => `${theme.breakpoints.down('sm')}`} {
    padding: 0;
    max-width: 100%;
  }
`;

export const RootComponent = styled('div')`
  display: flex;
`;
