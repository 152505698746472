import { memo } from 'react';

import {
  ICategoryAccordionStyles,
  IMenuCategorizedContentProps,
  IMenuCategoryData,
  IMenuCategoryItemData,
  ICategoryItemComponent,
} from './types';
import {
  Category,
  CategoryItem,
  CategoryItemsList,
  CategoryLabel,
  CategoryLabelIcon,
  CategoryLabelText,
} from './styles';

function MenuCategorizedContentUnmemoized<T = unknown>({
  categories,
  categoryAccordionProps,
  categoryAccordionStyles = {},
  categoryItemComponent: CategoryItemComponent = CategoryItem as ICategoryItemComponent<T>,
}: IMenuCategorizedContentProps<T>) {
  const {
    root: categoryStyles,
    label: categoryLabelStyles,
    labelText: categoryLabelTextStyles,
    labelIcon: categoryLabelIconStyles,
    itemsList: categoryItemsListStyles,
  }: ICategoryAccordionStyles = categoryAccordionStyles;

  return (
    <>
      {categories.map((category: IMenuCategoryData<T>) => {
        const {
          name: categoryName,
          id: categoryId,
          items,
          isExpanded: isCategoryExpanded,
          onToggle: onCategoryToggle,
        } = category;

        return (
          <Category
            {...categoryAccordionProps}
            css={categoryStyles}
            expanded={isCategoryExpanded}
            key={categoryId}
            elevation={0}
            onChange={onCategoryToggle}
          >
            <CategoryLabel css={categoryLabelStyles}>
              <CategoryLabelText css={categoryLabelTextStyles}>{categoryName}</CategoryLabelText>
              <CategoryLabelIcon css={categoryLabelIconStyles} />
            </CategoryLabel>

            {items.length && (
              <CategoryItemsList css={categoryItemsListStyles}>
                {items.map(({ name: itemName, id: itemId, onClick: onItemClick }: IMenuCategoryItemData<T>) => (
                  <CategoryItemComponent key={itemId} onClick={onItemClick}>
                    {itemName}
                  </CategoryItemComponent>
                ))}
              </CategoryItemsList>
            )}
          </Category>
        );
      })}
    </>
  );
}

export const MenuCategorizedContent = memo(MenuCategorizedContentUnmemoized) as typeof MenuCategorizedContentUnmemoized;
