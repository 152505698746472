import { CardHeader, DialogActions, DialogContent, Button as MatButton, styled } from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';

import { ConfirmDialogType } from './types';

interface ISetColorProps {
  custom: string;
  color?: string;
}

export const setColor = (type: ConfirmDialogType | undefined) => {
  switch (type) {
    case ConfirmDialogType.Info: {
      return {
        custom: 'rgb(107, 168, 196)',
      };
    }
    case ConfirmDialogType.Warning: {
      return {
        custom: 'rgb(255, 204, 2)',
      };
    }
    case ConfirmDialogType.Error: {
      return {
        custom: 'rgb(216,0,13)',
      };
    }
    default: {
      return { custom: '' };
    }
  }
};

export const IconStyles = styled(ErrorIcon)`
  ${({ custom }: ISetColorProps) => `color: ${custom}`};
`;

export const Button = styled(MatButton)`
  min-width: 100px;
`;

export const ButtonStyles = styled(Button)`
  ${({ custom, color }: ISetColorProps) => (color ? `color: ${custom}` : '')};
`;

export const TitleWrapperStyles = styled('div')`
  ${({ custom }: ISetColorProps) => `color: ${custom}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DialogHeader = styled(CardHeader)`
  display: flex;
`;

export const StyledDialogActions = styled(DialogActions)`
  margin: 0;
`;

export const StyledDialogContent = styled(DialogContent)`
  overflow: auto;
  height: 95px;
  padding: 20px;
`;
