import { Button, DialogContent, FormHelperText, SpeedDial, SpeedDialAction, styled } from '@mui/material';
import { IconType } from 'react-icons';

import { TextFieldValidator } from '/shared/TextFieldValidator';

export const StyledSpeedDial = styled(SpeedDial)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(0.25)};
  bottom: ${({ theme }) => theme.spacing(1.5)};
`;

export const SpeedDialIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',

  '& svg': {
    userSelect: 'none',
    fontSize: theme.typography.pxToRem(20),
    width: '1em',
    height: '1em',
    overflow: 'hidden',
    display: 'inline-block',
    flexShrink: 0,
  },
}));

export const SpeedDialActionIcon = ({ Icon }: { Icon: IconType }) => (
  <SpeedDialIconWrapper>
    <Icon />
  </SpeedDialIconWrapper>
);

export const StyledDialogContent = styled(DialogContent)`
  min-width: 500px;
`;

export const StyledTextFieldValidator = styled(TextFieldValidator)`
  margin-bottom: 15px;
`;

export const StyledFormHelperText = styled(FormHelperText)`
  position: absolute;
  bottom: -17px;
  margin: 0;
`;

export const StyledSpeedDialAction = styled(SpeedDialAction)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const SubmitButton = styled(Button)`
  min-width: 100px;
`;
