import { FC, memo, ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import { InfoRounded as InfoRoundedIcon } from '@mui/icons-material';

import { IBlockThemedAction, IBlockThemedProps } from './types';
import { BlockActions, BlockContainer, BlockContent, StyledButton, StyledLabel, StyledSpinner } from './styles';

export const BlockThemed: FC<IBlockThemedProps> = memo((props) => {
  const { label, error, styles = {}, children, pending, actions } = props;

  const {
    block: blockContainerCSS,
    label: labelCSS,
    blockContent: blockContentCSS,
    blockActions: blockActionsCSS,
    spinner: spinnerCSS,
    button: buttonCSS,
  } = styles;

  const blockLabel: ReactNode = typeof label === 'string' ? <b>{label}</b> : label;

  const actionsButtons = actions
    ? actions.map((action: IBlockThemedAction, idx: number) => {
        const Icon = action.icon;
        const actionButton = (
          <StyledButton
            key={idx}
            size='small'
            color='primary'
            disabled={action.disabled}
            onClick={action.onClick}
            css={buttonCSS}
            {...action.fabProps}
          >
            <Icon data-testid={action.dataCy || 'icon-add'} />
          </StyledButton>
        );

        return action?.tooltip !== undefined ? (
          <Tooltip key={idx} disableFocusListener title={action.tooltip} placement='top'>
            {actionButton}
          </Tooltip>
        ) : (
          actionButton
        );
      })
    : null;

  const labelString = typeof label === 'string' ? label : 'label';

  return (
    <BlockContainer
      data-testid={`${labelString.replace('*', '').trim()}-block`}
      $error={Boolean(error)}
      css={blockContainerCSS}
    >
      {pending && <StyledSpinner css={spinnerCSS} color='secondary' />}
      <StyledLabel css={labelCSS} $error={Boolean(error)}>
        {blockLabel}
        {Boolean(error) && typeof error === 'string' && (
          <Tooltip title={error} placement='top'>
            <InfoRoundedIcon fontSize='small' />
          </Tooltip>
        )}
      </StyledLabel>
      <BlockActions css={blockActionsCSS}>{actionsButtons}</BlockActions>
      <BlockContent css={blockContentCSS}>{children}</BlockContent>
    </BlockContainer>
  );
});
