import { TableCell as MatTableCell, TableRow as MatTableRow } from '@mui/material';

import { IRowData, ITableState } from '/shared/Table';

export interface ITableDetailsRowProps<D> extends ITableState<D> {
  rowData: IRowData<D>;
}

export function GenericTableDetailsRow<D>({ rowData, details, renderColumnsCount }: ITableDetailsRowProps<D>) {
  if (!details) {
    return null;
  }

  return (
    <MatTableRow>
      <MatTableCell colSpan={renderColumnsCount}>{details(rowData.item)}</MatTableCell>
    </MatTableRow>
  );
}
