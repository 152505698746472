import { useCallback, useState, MouseEvent, useMemo, SyntheticEvent, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import {
  FileCopy as CopyAllIcon,
  Difference as CopyIcon,
  Merge as UpdateIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import { SpaceID } from '@playq/octopus-common';
import { Space, AppEntityClass } from '@playq/octopus2-apps';
import { AppSpacePromotions, SpacePromotions } from '@playq/octopus2-apps-utility';

import { services2, unwrapEither } from '/api';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { ConfirmDialogType, confirmDialog } from '/common/ConfirmDialog';
import { ITableRowAction } from '/shared/Table';
import { throwGenericFailure } from '/helpers';

import { useSpacesList } from '../useSpacesList';

import { getAvailableEntitiesToCopy } from './helpers';
import { usePromotionsEntitiesPopover } from './usePromotionsEntitiesPopover';
import {
  ClonePromotionsTooltipTitle,
  CopyPromotionsTooltipTitle,
  CustomTooltip,
  UdpatePromotionsTooltipTitle,
} from './CustomTooltip';

export type UseSpacesTableActionsParams = {
  setCurrentSpaceID: (spaceID?: SpaceID) => void;
  setSpacePromotions: (promotions?: SpacePromotions) => void;
};

export const useSpacesTableActions = ({
  setCurrentSpaceID,
  setSpacePromotions,
}: UseSpacesTableActionsParams): {
  actions: ITableRowAction<Space>[];
  PromotionsEntitiesPopover: JSX.Element;
} => {
  const appID = useSelector(appToolkit.selectors.appID);

  const [targetSpaceID, setTargetSpaceID] = useState<SpaceID>();

  const { sandboxPromotions, deleteSpace, isSpaceProcessing, clonePromotions, copyPromotions, updatePromotions } =
    useSpacesList();

  const didCancel = useRef(false);

  useEffect(
    () => () => {
      didCancel.current = true;
    },
    []
  );

  const handleDelete = useCallback(
    (space: Space) => {
      setCurrentSpaceID(space.id);
      services2.spacesPromotionsService.spacePromotions(space.id).then((data) =>
        data.bifold((res) => {
          if (didCancel.current) {
            return;
          }
          if (res.byApp.length === 0) {
            confirmDialog({
              title: `DELETE ${space.name}?`,
              text: `Are you sure you want to delete the ${space.name}?`,
              closeButton: { label: 'NO' },
              successButton: { label: 'YES' },
              onSuccess: () => deleteSpace(space.id),
            });
          } else {
            setSpacePromotions(res);
          }
        }, throwGenericFailure)
      );
    },
    [setCurrentSpaceID, setSpacePromotions, deleteSpace]
  );

  const handleClone = useCallback(
    (_: SyntheticEvent, space: Space) => {
      if (appID) {
        confirmDialog({
          type: ConfirmDialogType.Warning,
          title: 'CLONE',
          text: (
            <>
              <Typography>
                This action will demote all entities from target space and then promote entities from Sandbox. If you
                want to promote entities without demoting existing, use &quot;Merge Promotions&quot; action. Make sure
                you have write permissions to all of the kinds of promoted entities.
              </Typography>
              <Typography>Are you sure you want to proceed?</Typography>
            </>
          ),
          onSuccess: () => clonePromotions({ appID, targetSpaceID: space.id }),
        });
      }
    },
    [appID, clonePromotions]
  );

  const handleUpdate = useCallback(
    async (_: SyntheticEvent, space: Space) => {
      if (!appID || !sandboxPromotions) {
        return;
      }
      const spaceID = space.id;
      try {
        const targetPromotions = await unwrapEither(
          services2.spacesPromotionsService.appSpacePromotions(appID, spaceID)
        );
        const updatedPromotions = new AppSpacePromotions(targetPromotions.serialize());
        if (sandboxPromotions.config !== undefined) {
          updatedPromotions.config = sandboxPromotions.config;
        }
        if (sandboxPromotions.segment !== undefined) {
          updatedPromotions.segment = sandboxPromotions.segment;
        }
        updatedPromotions.experiments.push(...sandboxPromotions.experiments);
        updatedPromotions.events.push(...sandboxPromotions.events);
        updatedPromotions.flows.push(...sandboxPromotions.flows);

        confirmDialog({
          type: ConfirmDialogType.Warning,
          title: 'MERGE PROMOTIONS',
          text: `Are you sure you want to promote all Sandbox entities to ${space.name}?  Make sure you have write permissions to all of the kinds of promoted entities.`,
          onSuccess: () => updatePromotions({ spaceID, promotions: updatedPromotions }),
        });
      } catch (err) {
        snackbarService.genericFailure(err as Error);
      }
    },
    [appID, sandboxPromotions, updatePromotions]
  );

  const handleCopy = useCallback(
    (entitiesToCopy: AppEntityClass[]) => {
      if (appID && targetSpaceID) {
        confirmDialog({
          type: ConfirmDialogType.Warning,
          title: 'COPY',
          text: (
            <>
              <Typography>
                This action will demote all selected entities from target space and then promote selected entities. Make
                sure you have write permissions to all of the kinds of selected entities.
              </Typography>
              <Typography>Are you sure you want to proceed?</Typography>
            </>
          ),
          onSuccess: () => copyPromotions({ appID, targetSpaceID, entities: entitiesToCopy }),
        });
      }
    },
    [appID, targetSpaceID, copyPromotions]
  );

  const availableEntitiesToCopy = getAvailableEntitiesToCopy(sandboxPromotions);

  const { PromotionsEntitiesPopover, openEntitiesToCopySelect } = usePromotionsEntitiesPopover({
    availableEntitiesToCopy,
    onApply: handleCopy,
  });

  const handleCopyIconClick = useCallback(
    (event: SyntheticEvent, space: Space) => {
      setTargetSpaceID(space.id);
      openEntitiesToCopySelect(event as MouseEvent<HTMLButtonElement>);
    },
    [openEntitiesToCopySelect]
  );

  const actions: ITableRowAction<Space>[] = useMemo(
    () => [
      {
        label: (
          <CustomTooltip title={<UdpatePromotionsTooltipTitle />}>
            <div>Merge With Default Promotions</div>
          </CustomTooltip>
        ),
        icon: () => (
          <CustomTooltip title={<UdpatePromotionsTooltipTitle />}>
            <UpdateIcon />
          </CustomTooltip>
        ),
        pending: (space: Space) => isSpaceProcessing(space),
        hidden: !sandboxPromotions,
        onClick: handleUpdate,
      },
      {
        icon: () => (
          <CustomTooltip title={<CopyPromotionsTooltipTitle />}>
            <CopyIcon />
          </CustomTooltip>
        ),
        label: (
          <CustomTooltip title={<CopyPromotionsTooltipTitle />}>
            <div>Copy From Default</div>
          </CustomTooltip>
        ),
        pending: (space: Space) => isSpaceProcessing(space),
        hidden: !sandboxPromotions,
        onClick: handleCopyIconClick,
      },
      {
        label: (
          <CustomTooltip title={<ClonePromotionsTooltipTitle />} placement='top'>
            <div>Clone All From Default</div>
          </CustomTooltip>
        ),
        icon: () => (
          <CustomTooltip title={<ClonePromotionsTooltipTitle />}>
            <CopyAllIcon />
          </CustomTooltip>
        ),
        pending: (space: Space) => isSpaceProcessing(space),
        hidden: !sandboxPromotions,
        onClick: handleClone,
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        tooltip: (space: Space) => `Delete ${space.name}`,
        tooltipPlacement: 'top',
        pending: (space: Space) => isSpaceProcessing(space),
        onClick: (_: SyntheticEvent, space: Space) => handleDelete(space),
      },
    ],
    [handleClone, handleCopyIconClick, handleDelete, handleUpdate, isSpaceProcessing, sandboxPromotions]
  );

  return {
    actions,
    PromotionsEntitiesPopover,
  };
};
