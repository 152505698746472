import { useState } from 'react';
import { Button, Checkbox, List, ListItem, ListItemText, Popover } from '@mui/material';

import { AppEntityClass } from '@playq/octopus2-apps';

import { useModal } from '/hooks';

import { SelectEntitiesActions } from './styles';

export type UsePromotionsEntitiesPopoverParams = {
  availableEntitiesToCopy: AppEntityClass[];
  onApply: (entitiesToCopy: AppEntityClass[]) => void;
};

export const usePromotionsEntitiesPopover = ({
  availableEntitiesToCopy,
  onApply,
}: UsePromotionsEntitiesPopoverParams) => {
  const { anchorEl, openAnchorEl, closeAnchorEl } = useModal();

  const [entitiesToCopy, setEntitiesToCopy] = useState<AppEntityClass[]>([]);

  const handleToggleEntityToCopy = (entity: AppEntityClass) => () => {
    setEntitiesToCopy((prevEntities) =>
      prevEntities.includes(entity) ? prevEntities.filter((e) => e !== entity) : [...prevEntities, entity]
    );
  };

  const handleClearEntitiesToCopy = () => {
    setEntitiesToCopy([]);
    closeAnchorEl();
  };

  const handleApplyEntitiesToCopy = () => {
    if (entitiesToCopy.length > 0) {
      onApply(entitiesToCopy);
      closeAnchorEl();
    }
  };

  const Node = (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={closeAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <List>
        {availableEntitiesToCopy.map((entity) => (
          <ListItem key={entity} dense={true} button={true} onClick={handleToggleEntityToCopy(entity)}>
            <Checkbox checked={entitiesToCopy.includes(entity)} disableRipple={true} tabIndex={-1} />
            <ListItemText primary={entity} />
          </ListItem>
        ))}
      </List>
      <SelectEntitiesActions>
        <Button color='secondary' onClick={handleClearEntitiesToCopy}>
          Cancel
        </Button>
        <Button onClick={handleApplyEntitiesToCopy}>Apply</Button>
      </SelectEntitiesActions>
    </Popover>
  );

  return {
    PromotionsEntitiesPopover: Node,
    entitiesToCopy,
    openEntitiesToCopySelect: openAnchorEl,
    closeEntitiesToCopySelect: closeAnchorEl,
  };
};
