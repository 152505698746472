import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, css, Link, List, ListItem, ListItemText, styled } from '@mui/material';

import { Environment, PromotionFilter, SpaceID } from '@playq/octopus-common';
import { Space } from '@playq/octopus2-apps';

import { useSpaceQuery } from '/api';
import { activeSpaceToolkit, currentModuleToolkit } from '/store';
import { SpaceSelect } from '/common/Spaces/SpaceSelect';
import { IFilterCellProps } from '/shared/Table/components/TableHeadCell/IFilterCellProps';

const ListStyled = styled(List)`
  width: 100%;
  max-width: 200px;
`;

const SpaceWrapper = styled('div')`
  display: block;
  max-width: 200px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  word-break: break-all;
`;

const disabledLinkCSS = css`
  color: ${({ theme }) => theme.palette.text.disabled};
  pointer-events: none;
`;

const DisabledSpan = styled('span')<{ disable: boolean }>`
  ${({ disable }) => disable && disabledLinkCSS}
`;

const DisabledLink = styled(Link)<{ disable: boolean }>`
  ${({ disable }) => disable && disabledLinkCSS}
`;

const filterData = [Environment.Live, Environment.Sandbox];

export function PromotionFilterCell({ filter: propsFilter, onSubmit, onClear }: IFilterCellProps) {
  const filter = propsFilter as PromotionFilter | undefined;
  const currentActiveSpace = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const isSpaceLessModule = useSelector(currentModuleToolkit.selectors.isSpaceLessModule);
  const activeSpace = isSpaceLessModule ? undefined : currentActiveSpace;

  const [spaceId, setSpaceId] = useState<SpaceID | undefined>(filter?.space);
  const [currentSpace, setCurrentSpace] = useState<Space | undefined>();
  const [spaceSelectOpen, setSpaceSelectOpen] = useState(false);

  const selected = useMemo(() => {
    if (!filter) {
      return new Set<Environment>();
    }

    return new Set<Environment>(filter.env);
  }, [filter]);

  const { isFetching } = useSpaceQuery(spaceId, {
    onSettled: (data) => {
      setCurrentSpace(data);
    },
  });

  const updateFilterBySpace = useCallback(
    (selectedSpace?: Space | undefined) => {
      const promotionFilter = new PromotionFilter(filter?.serialize());
      promotionFilter.space = selectedSpace?.id;
      onSubmit(promotionFilter, true);
    },
    [filter, onSubmit]
  );

  useEffect(() => {
    if (isSpaceLessModule) {
      setCurrentSpace(undefined);
      setSpaceId(undefined);
    }
  }, [currentSpace, isSpaceLessModule]);

  useEffect(() => {
    if (!filter) {
      setSpaceId(activeSpace?.id);
      return;
    }
    setSpaceId(filter.space);
  }, [activeSpace, filter]);

  const handleSpaceSelectOpen = () => {
    if (isSpaceLessModule) {
      return;
    }
    setSpaceSelectOpen(true);
  };

  const handleSpaceSelectClose = () => {
    setSpaceSelectOpen(false);
  };

  const handleSpaceSelect = useCallback(
    (selectedSpace?: Space) => {
      setSpaceSelectOpen(false);

      updateFilterBySpace(selectedSpace);

      setCurrentSpace(selectedSpace);
      setSpaceId(selectedSpace?.id);
    },
    [updateFilterBySpace]
  );

  const handleSelect = (option: Environment) => () => {
    const nSelected = new Set(selected);

    if (nSelected.has(option)) {
      nSelected.delete(option);
    } else {
      nSelected.add(option);
    }
    if (nSelected.size === 0) {
      onClear();
      return;
    }

    const promotionFilter = new PromotionFilter();
    promotionFilter.env = Array.from(nSelected);
    if (promotionFilter.env.includes(Environment.Sandbox)) {
      promotionFilter.space = currentSpace?.id;
    }

    setTimeout(() => {
      onSubmit(promotionFilter, true);
    }, 0);
  };

  return (
    <>
      <ListStyled>
        {filterData.map((option) => (
          <ListItem key={option} divider={true} dense={true} button={true} onClick={handleSelect(option)}>
            <Checkbox
              data-testid={`${option.toLowerCase()}-checkbox`}
              checked={selected.has(option)}
              tabIndex={-1}
              disableRipple={true}
            />
            <ListItemText primary={option} />
          </ListItem>
        ))}
      </ListStyled>
      <SpaceWrapper>
        <DisabledSpan disable={Boolean(isSpaceLessModule)}>Space: </DisabledSpan>
        <>
          {isFetching ? (
            <i>fetching...</i>
          ) : (
            <DisabledLink disable={Boolean(isSpaceLessModule)} onClick={handleSpaceSelectOpen}>
              {currentSpace?.name || 'Default'}
            </DisabledLink>
          )}
        </>
      </SpaceWrapper>
      {spaceSelectOpen && (
        <SpaceSelect activeSpaceID={currentSpace?.id} onSelect={handleSpaceSelect} onClose={handleSpaceSelectClose} />
      )}
    </>
  );
}

PromotionFilterCell.type = PromotionFilter.ClassName;
