import { useCallback, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Space } from '@playq/octopus2-apps';

import {
  useAppSpacePromotionsCopy,
  useAppSpacePromotionsClone,
  useAppSpacePromotionsUpdate,
  useAppSpacePromotions,
} from '/api';

export const useSpacesListPromotionsActions = (entitiesProcessing?: Record<string, boolean>) => {
  const [isFetchingEnabled, setIsFetchingEnabled] = useState(false);

  const queryClient = useQueryClient();

  const {
    data: sandboxPromotions,
    isFetching: isRetrievingSandbox,
    keys,
  } = useAppSpacePromotions(undefined, {
    staleTime: 10000,
    enabled: isFetchingEnabled,
  });

  const cachedPromotions = queryClient.getQueryData(keys);

  const { mutate: clonePromotions, isLoading: isCloning } = useAppSpacePromotionsClone();

  const { mutate: copyPromotions, isLoading: isCopying } = useAppSpacePromotionsCopy();

  const { mutate: updatePromotions, isLoading: isUpdating } = useAppSpacePromotionsUpdate();

  const isSpaceProcessing = useCallback(
    (space: Space) => {
      return isRetrievingSandbox || isCloning || isCopying || isUpdating || !!entitiesProcessing?.[space.id.id];
    },
    [entitiesProcessing, isRetrievingSandbox, isCloning, isCopying, isUpdating]
  );

  useEffect(
    function enableFetchingIfNoCachedPromotions() {
      setIsFetchingEnabled(cachedPromotions === undefined);
    },
    [cachedPromotions]
  );

  return {
    sandboxPromotions,
    clonePromotions,
    copyPromotions,
    updatePromotions,
    isSpaceProcessing,
  };
};
