import { ReactNode, useMemo } from 'react';
import { Checkbox, List, ListItemButton, ListItemText, styled, Typography } from '@mui/material';

import { OptionsFilter } from '@playq/octopus-common';

import { IFilterCellProps } from '/shared/Table/components/TableHeadCell/IFilterCellProps';

const ListStyled = styled(List)`
  width: 100%;
  max-width: 360px;
`;

export function OptionsFilterCell({ filter, filterData, onSubmit, onClear }: IFilterCellProps) {
  const selected = useMemo(() => {
    if (!filter) {
      return new Set();
    }
    return new Set((filter as OptionsFilter).options);
  }, [filter]);

  const handleSelect = (option: ReactNode) => () => {
    const optionAsString = String(option);
    const nSelected = new Set(selected);
    if (nSelected.has(optionAsString)) {
      nSelected.delete(optionAsString);
    } else {
      nSelected.add(optionAsString);
    }
    if (nSelected.size === 0) {
      onClear();
      return;
    }
    const optionsFilter = new OptionsFilter();
    optionsFilter.options = Array.from(nSelected) as string[];
    onSubmit(optionsFilter, false);
  };

  if (!filterData) {
    return <Typography>There is no data provided</Typography>;
  }

  if (!(filterData instanceof Array)) {
    return <Typography>Wrong options data. Should be array, but provided: {filterData}</Typography>;
  }

  return (
    <ListStyled>
      {filterData.map((option) => (
        <ListItemButton key={String(option)} divider={true} dense={true} onClick={handleSelect(option)}>
          <Checkbox checked={selected.has(option)} tabIndex={-1} disableRipple={true} />
          <ListItemText primary={option} />
        </ListItemButton>
      ))}
    </ListStyled>
  );
}

OptionsFilterCell.type = OptionsFilter.ClassName;
