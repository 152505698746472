import { Chip, styled, Tooltip } from '@mui/material';

import { ContainsFilter, Filter } from '@playq/octopus-common';

import { IPreviewComponentProps } from './IPreviewComponentProps';

export const Wrapper = styled('div')`
  width: 100%;
  max-width: 360px;
  overflow-y: scroll;
  padding: 2px 0;
`;

const prefix = 'ID: ';

export function ContainsPreview({ filter, format }: IPreviewComponentProps) {
  if (!(filter instanceof ContainsFilter)) {
    return null;
  }

  if (format !== undefined) {
    return format(filter);
  }

  return (
    <div>
      <Wrapper>
        {filter.values.map((value: string) => {
          const shortID = value.slice(0, 5);
          const shortTitle = value.length > 5 ? prefix + shortID + `\u2026` : prefix + shortID;
          return (
            <Tooltip placement='top' title={`ID: ${value}`} key={value}>
              <Chip size='small' label={shortTitle} color='primary' />
            </Tooltip>
          );
        })}
      </Wrapper>
    </div>
  );
}

ContainsPreview.canPreview = (filter: Filter) => filter instanceof ContainsFilter;
