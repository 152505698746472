import { css, IconButton, styled } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

export const content = css`
  margin-top: ${({ theme }) => theme.spacing(2)};
  display: grid;
  row-gap: ${({ theme }) => theme.spacing(3)};
`;

export const AppSaltsButton = styled(IconButton)`
  padding: 0px;
`;

export const AppSaltsInfoIcon = styled(InfoIcon)`
  margin: 0 2px;
`;
