import { FC, useContext, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { PhotoCamera as PhotoCameraIcon } from '@mui/icons-material';

import { FileRevisionID } from '@playq/octopus2-files';

import AppContext from '/component/Apps/App/AppContext';
import { FilePicker, ISelectedFile } from '/component/Files/FilePicker';
import { SourcePreview } from '/shared/FilePreview';

export const AppIcon: FC = () => {
  const { appImage, readonly, onSelectAppImage } = useContext(AppContext);
  const [filePickerOpen, setFilePickerOpen] = useState<boolean>(false);

  const handleFilePickerOpen = (val: boolean) => () => {
    setFilePickerOpen(val);
  };

  const handleFilePick = ({ fileRevision }: ISelectedFile) => {
    onSelectAppImage(fileRevision.id);
    setFilePickerOpen(false);
  };

  const getAppImageElement = (handleClick?: () => void) => {
    if (!appImage) {
      return (
        <IconButton disabled={readonly} onClick={handleClick} size='large'>
          <PhotoCameraIcon sx={{ fontSize: 150 }} />
        </IconButton>
      );
    }

    const source = appImage instanceof FileRevisionID ? appImage : appImage.url;
    return <SourcePreview source={source} onClick={handleClick} fallbackTitle='' />;
  };

  const appImageElement = getAppImageElement(readonly ? undefined : handleFilePickerOpen(true));

  return (
    <>
      <Tooltip title='Pick a file' placement='bottom'>
        <div>{appImageElement}</div>
      </Tooltip>
      <FilePicker
        open={filePickerOpen}
        selectedRevID={appImage instanceof FileRevisionID ? appImage : undefined}
        onSelect={handleFilePick}
        onClose={handleFilePickerOpen(false)}
      />
    </>
  );
};
