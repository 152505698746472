import { ReactNode } from 'react';

import { GenericTableCellStyled, IBaseColumnStyles, ITableState } from '/shared/Table';

export interface ICellProps<D> extends ITableState<D> {
  children?: ReactNode;
  columnHidden?: boolean;
  /**
   * columnCellStyles - specific props for styling the cell from the column list.
   */
  columnCellStyles?: IBaseColumnStyles['cell'];
}

export function GenericTableCell<D>({ children, columnHidden, columnCellStyles, styles }: ICellProps<D>) {
  const { cell: genericTableCellCSS } = styles ?? {};

  return (
    <GenericTableCellStyled
      $columnHidden={Boolean(columnHidden)}
      $cssColumn={columnCellStyles}
      css={genericTableCellCSS}
    >
      {children}
    </GenericTableCellStyled>
  );
}
