import { styled, Tooltip } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';

import { IWithCSS } from '/common/models';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps & IWithCSS) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  ${({ css }) => css}
`;
