import { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import {
  FileCopy as CopyAllIcon,
  Difference as CopyIcon,
  Close as ClearIcon,
  Merge as UpdateIcon,
} from '@mui/icons-material';
import { orange } from '@mui/material/colors';

import { SpaceID } from '@playq/octopus-common';
import { Space, AppEntityClass } from '@playq/octopus2-apps';
import { AppSpacePromotions } from '@playq/octopus2-apps-utility';

import {
  useAppSpacePromotionsCopy,
  useAppSpacePromotionsClone,
  useAppSpacePromotionsClean,
  useAppSpacePromotionsUpdate,
  services2,
  unwrapEither,
} from '/api';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { ConfirmDialogType, confirmDialog } from '/common/ConfirmDialog';
import { TextAnimation } from '/shared/TextAnimation';

import { SpaceSelect } from '../SpaceSelect';

import { ISpacePromotionsActionsProps } from './types';
import { getAvailableEntitiesToCopy } from './helpers';
import {
  ClonePromotionsTooltipTitle,
  CopyPromotionsTooltipTitle,
  CustomTooltip,
  UdpatePromotionsTooltipTitle,
} from './CustomTooltip';
import { usePromotionsEntitiesPopover } from './usePromotionsEntitiesPopover';

type SpaceSelectOpenReason = 'clone' | 'copy' | 'update';

export const SpacePromotionsActions = ({ id, name, promotions, onClear }: ISpacePromotionsActionsProps) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const [spaceSelectOpenReason, setSpaceSelectOpenReason] = useState<SpaceSelectOpenReason>();

  const { mutate: clonePromotions, isLoading: isCloning } = useAppSpacePromotionsClone();

  const { mutate: copyPromotions, isLoading: isCopying } = useAppSpacePromotionsCopy();

  const { mutate: clearAll } = useAppSpacePromotionsClean({
    onSuccess: () => {
      onClear();
      snackbarService.success('All the entities successfully demoted');
    },
  });

  const { mutate: updatePromotions, isLoading: isUpdating } = useAppSpacePromotionsUpdate();

  const handleApplyEntitiesToCopy = (entities: AppEntityClass[]) => {
    if (entities.length > 0) {
      handleSpaceSelectOpen('copy')();
    }
  };

  const availableEntitiesToCopy = getAvailableEntitiesToCopy(promotions);

  const { PromotionsEntitiesPopover, entitiesToCopy, openEntitiesToCopySelect } = usePromotionsEntitiesPopover({
    availableEntitiesToCopy,
    onApply: handleApplyEntitiesToCopy,
  });

  const handleClone = useCallback(
    (targetSpaceID: SpaceID) => {
      if (appID) {
        confirmDialog({
          type: ConfirmDialogType.Warning,
          title: 'CLONE',
          text: (
            <>
              <Typography>
                This action will demote all entities from target space and then promote entities from the current space.
                If you want to promote entities without demoting existing, use &quot;Merge Promotions&quot; action. Make
                sure you have write permissions to all of the kinds of promoted entities.
              </Typography>
              <Typography>Are you sure you want to proceed?</Typography>
            </>
          ),
          onSuccess: () => clonePromotions({ appID, sourceSpaceID: id, targetSpaceID }),
        });
      }
    },
    [appID, clonePromotions, id]
  );

  const handleCopy = useCallback(
    (targetSpaceID: SpaceID) => {
      if (appID) {
        confirmDialog({
          type: ConfirmDialogType.Warning,
          title: 'COPY',
          text: (
            <>
              <Typography>
                This action will demote all selected entities from target space and then promote selected entities. Make
                sure you have write permissions to all of the kinds of selected entities.
              </Typography>
              <Typography>Are you sure you want to proceed?</Typography>
            </>
          ),
          onSuccess: () => copyPromotions({ appID, sourceSpaceID: id, targetSpaceID, entities: entitiesToCopy }),
        });
      }
    },
    [appID, copyPromotions, id, entitiesToCopy]
  );

  const handleClear = useCallback(() => {
    if (appID) {
      confirmDialog({
        type: ConfirmDialogType.Warning,
        title: 'DEMOTE',
        text: 'Are you sure you want to demote all the entities? Make sure you have write permissions to all of the kinds of promoted entities.',
        onSuccess: () => clearAll({ appID, spaceID: id }),
      });
    }
  }, [appID, clearAll, id]);

  const handleUpdate = useCallback(
    async (spaceID: SpaceID) => {
      if (!promotions || !appID) {
        return;
      }
      try {
        const targetPromotions = await unwrapEither(
          services2.spacesPromotionsService.appSpacePromotions(appID, spaceID)
        );
        const updatedPromotions = new AppSpacePromotions(targetPromotions.serialize());
        if (promotions.config !== undefined) {
          updatedPromotions.config = promotions.config;
        }
        if (promotions.segment !== undefined) {
          updatedPromotions.segment = promotions.segment;
        }
        updatedPromotions.experiments.push(...promotions.experiments);
        updatedPromotions.events.push(...promotions.events);
        updatedPromotions.flows.push(...promotions.flows);

        confirmDialog({
          type: ConfirmDialogType.Warning,
          title: 'MERGE PROMOTIONS',
          text: 'Are you sure you want to promote all the entities to another space?  Make sure you have write permissions to all of the kinds of promoted entities.',
          onSuccess: () => updatePromotions({ spaceID, promotions: updatedPromotions }),
        });
      } catch (err) {
        snackbarService.genericFailure(err as Error);
      }
    },
    [appID, promotions, updatePromotions]
  );

  const handleSpaceSelectOpen = (reason: SpaceSelectOpenReason) => () => {
    setSpaceSelectOpenReason(reason);
  };

  const handleSpaceSelectClose = useCallback(() => {
    setSpaceSelectOpenReason(undefined);
  }, []);

  const handleSpaceSelect = useCallback(
    (reason: SpaceSelectOpenReason) => (space?: Space) => {
      if (space) {
        if (reason === 'clone') {
          handleClone(space.id);
        }
        if (reason === 'copy') {
          handleCopy(space.id);
        }
        if (reason === 'update') {
          handleUpdate(space.id);
        }
      }
      handleSpaceSelectClose();
    },
    [handleClone, handleCopy, handleSpaceSelectClose, handleUpdate]
  );

  const actions = useMemo(
    () => [
      {
        key: 'Merge Promotions',
        icon: <UpdateIcon />,
        title: <UdpatePromotionsTooltipTitle name={name} />,
        onClick: handleSpaceSelectOpen('update'),
      },
      {
        icon: <CopyIcon />,
        key: 'Copy',
        title: <CopyPromotionsTooltipTitle name={name} />,
        onClick: openEntitiesToCopySelect,
      },
      {
        key: 'Clone All',
        icon: <CopyAllIcon />,
        title: <ClonePromotionsTooltipTitle name={name} />,
        onClick: handleSpaceSelectOpen('clone'),
      },
      {
        key: 'Demote All',
        icon: <ClearIcon />,
        title: (
          <>
            <Typography variant='h6'>Demote All</Typography>
            <Typography>Demote all the entities from selected space.</Typography>
          </>
        ),
        onClick: handleClear,
      },
    ],
    [openEntitiesToCopySelect, handleClear, name]
  );

  if (isCloning || isCopying || isUpdating) {
    return <TextAnimation color={orange[500]}>Processing</TextAnimation>;
  }

  return (
    <div>
      {actions.map(({ icon, key, title, onClick }) => (
        <CustomTooltip title={title} key={key}>
          <IconButton size='small' color='default' onClick={onClick}>
            {icon}
          </IconButton>
        </CustomTooltip>
      ))}
      {!!spaceSelectOpenReason && (
        <SpaceSelect
          activeSpaceID={id}
          onSelect={handleSpaceSelect(spaceSelectOpenReason)}
          onClose={handleSpaceSelectClose}
        />
      )}
      {PromotionsEntitiesPopover}
    </div>
  );
};
