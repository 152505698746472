import { css, styled } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { IWithCSS } from 'common/models';

export const iconBasicCSS = css`
  margin-bottom: 20px;
  font-size: 80px;
`;

export const wrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
  color: ${({ theme }) => theme.palette.text.disabled};
`;

export const Text = styled('span')`
  font-size: 16px;
`;

export const DefaultIcon = styled(SearchIcon)<IWithCSS>`
  ${iconBasicCSS}
  ${({ css }) => css}
`;

export const DefaultWrapper = styled('div')<IWithCSS>`
  ${wrapperCSS}
  ${({ css }) => css}
`;
