import { FC, useState } from 'react';
import { DateTime } from 'luxon';
import { isNil } from 'lodash';

import { minDateDefault } from './constants';
import { IDateTimePickerProps } from './types';
import { StaticDatePickerStyled, TimePickerStyled } from './styles';

export const DateTimePicker: FC<IDateTimePickerProps> = (props) => {
  const { date: original, time, onAccept, onChange, disabled, styles, optional = false } = props;
  const { datePicker: datePickerCSS } = styles ?? {};

  const now = new Date();

  const [date, setDate] = useState(() => {
    if (original) {
      return original > minDateDefault ? original : minDateDefault;
    }

    return optional ? undefined : minDateDefault;
  });

  const transformToDateTime = (jsDate: Date | undefined) => {
    if (jsDate === undefined) {
      return;
    }
    return DateTime.fromJSDate(jsDate);
  };

  function handleChangeDateTime(d: DateTime | null) {
    if (disabled || isNil(d)) {
      return;
    }

    setDate(d.toJSDate());
    onChange(d.toJSDate());
  }

  return (
    <>
      <StaticDatePickerStyled
        value={transformToDateTime(date || now)}
        onChange={handleChangeDateTime}
        displayStaticWrapperAs='desktop'
        maxDate={transformToDateTime(props.maxDate)}
        minDate={transformToDateTime(props.minDate)}
        disableHighlightToday={!props.highlightCurrentDay}
        views={['year', 'month', 'day']}
        onAccept={onAccept}
        showDaysOutsideCurrentMonth={true}
        css={datePickerCSS}
      />
      {time && (
        <TimePickerStyled
          value={transformToDateTime(date || now)}
          onChange={handleChangeDateTime}
          views={['hours', 'minutes']}
          ampm={false}
          timeSteps={{ minutes: 1 }}
        />
      )}
    </>
  );
};
