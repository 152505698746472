import { useCallback, useState } from 'react';

interface UseToggleElements<K, V> {
  isElementToggled: (key: K) => boolean;
  handleToggleElements: (key: K, value?: V) => void;
  clearToggles: (key?: K) => void;
  toggledElements: Map<K, V | undefined>;
}
/**
 * A generic hook for managing the toggling state of elements, where each element is identified by a key.
 * The state is stored in a Map, allowing for flexible use of key-value pairs.
 *
 * @template K - The type of the key used to identify elements (extends string).
 * @template V - The type of the value associated with each key (optional, defaults to undefined).
 *
 * @param {Array<[K, V | undefined]>} [initialState] - An optional array of key-value pairs to initialize the toggled elements map.
 *
 * @returns {UseToggleElements<K, V>} An object containing:
 *
 * @property {function(K): boolean} isElementToggled - Checks if an element with the given key is toggled (exists in the map).
 *
 * @property {function(K, V=): void} handleToggleElements - Toggles the element with the given key. Adds or removes it from the map, optionally setting a value for it.
 *
 * @property {function(K=): void} clearToggles - Clears the toggled elements. If a key is provided, only that specific key is cleared; otherwise, all toggles are cleared.
 *
 * @property {Map<K, V | undefined>} toggledElements - The map of currently toggled elements.
 */
export const useToggleElements = <K extends string, V = undefined>(
  initialState?: [K, V | undefined][]
): UseToggleElements<K, V> => {
  const [toggle, setToggle] = useState<Map<K, V | undefined>>(new Map(initialState));

  const isElementToggled = useCallback((key: K) => toggle.has(key), [toggle]);

  const clearToggles = useCallback((key?: K) => {
    setToggle((prev) => {
      if (key === undefined) {
        return new Map();
      }
      const newMap = new Map(prev);
      newMap.delete(key);
      return newMap;
    });
  }, []);

  const handleToggleElements = useCallback((key: K, value?: V) => {
    setToggle((prev) => {
      const newMap = new Map(prev);
      if (newMap.has(key)) {
        newMap.delete(key);
      } else {
        newMap.set(key, value);
      }
      return newMap;
    });
  }, []);

  return { isElementToggled, handleToggleElements, clearToggles, toggledElements: toggle };
};
