import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

import { Tags } from '/shared/Tags';

export const Wrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 56px;
  grid-column: 1;
`;

export const TagsStyled = styled(Tags)`
  flex: 1;
`;

export const ActionButtonStyled = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));
