import { ReactNode, useState } from 'react';
import { Checkbox, List, ListItemButton, ListItemText } from '@mui/material';

import { BooleanFilter } from '@playq/octopus-common';

import { IFilterCellProps } from '/shared/Table/components/TableHeadCell/IFilterCellProps';

const TRUE = 'TRUE';
const FALSE = 'FALSE';
const initialFilterData = [TRUE, FALSE];

export function BooleanFilterCell({ filter, onSubmit, onClear, filterData = initialFilterData }: IFilterCellProps) {
  const [value, setValue] = useState<boolean | undefined>((filter as BooleanFilter)?.value);
  const [selected, setSelected] = useState<ReactNode | undefined>();

  const handleSelect = (option: ReactNode) => () => {
    if (option === selected) {
      setSelected(undefined);
      setValue(undefined);
      onClear();
      return;
    }

    setSelected(option);

    const condition = option === filterData[0];
    setValue(condition);

    const booleanFiter = new BooleanFilter();

    booleanFiter.value = condition;
    onSubmit(booleanFiter, false);
  };

  const setCheckedIndex = (): number => {
    if (value === undefined) return -1;
    return value ? 0 : 1;
  };

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
      }}
    >
      {filterData.map((option, idx) => {
        return (
          <ListItemButton key={`${String(option)}-${idx}`} divider={true} dense={true} onClick={handleSelect(option)}>
            <Checkbox checked={idx === setCheckedIndex()} tabIndex={-1} disableRipple={true} />
            <ListItemText primary={option} />
          </ListItemButton>
        );
      })}
    </List>
  );
}

BooleanFilterCell.type = BooleanFilter.ClassName;
