import { FC, ReactNode } from 'react';
import { CardHeader, Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';

import { mergeClasses } from '/helpers/mergeClasses';

import { useDialogStyles } from './styles';

interface ISelectDialogClasses extends Record<string, string | undefined> {
  dialogHeader?: string;
  dialogActions?: string;
  dialogHeaderAction?: string;
  dialogContent?: string;
}

type CommonSelectDialogProps = Omit<DialogProps, 'title' | 'classes' | 'children' | 'content'> & {
  subtitle?: string;
  content?: ReactNode;
  actions?: ReactNode;
  headerAction?: ReactNode;
  onClose: () => void;
  classes?: ISelectDialogClasses;
  fullWidth?: boolean;
  CustomActions?: ReactNode;
  Content?: ReactNode;
};

type SelectDialogHeaderProps = { title: string | ReactNode; Header?: never } | { Header: ReactNode; title?: never };

type SelectDialogProps = CommonSelectDialogProps & SelectDialogHeaderProps;

export const SelectDialog: FC<SelectDialogProps> = (props) => {
  const {
    title,
    subtitle,
    content,
    actions,
    headerAction,
    onClose,
    fullWidth = true,
    classes: propsClasses = {},
    Header: PropsHeader,
    Content: PropsContent,
    CustomActions = null,
    ...dialogProps
  } = props;

  const innerClasses = useDialogStyles();
  const classes = mergeClasses(innerClasses, propsClasses);

  const handleClose = () => onClose();

  return (
    <Dialog
      aria-labelledby='simple-dialog-title'
      fullWidth={fullWidth}
      maxWidth='md'
      onClose={handleClose}
      {...dialogProps}
    >
      {PropsHeader ?? (
        <CardHeader
          title={title}
          subheader={subtitle}
          action={headerAction}
          classes={{ root: classes.dialogHeader, action: classes.dialogHeaderAction }}
        />
      )}
      {PropsContent ?? <DialogContent className={classes.dialogContent}>{content}</DialogContent>}
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {actions && <DialogActions className={classes.dialogActions}>{actions}</DialogActions>}
      {CustomActions}
    </Dialog>
  );
};
