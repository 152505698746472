import { FC, SyntheticEvent, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Badge, Box, Chip, Stack, Tooltip, Typography, useEventCallback } from '@mui/material';
import { Backup as UploadIcon, CloudDownload as CloudDownloadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { orange } from '@mui/material/colors';
import * as d3 from 'd3';

import {
  FileID,
  FileResponse,
  FileRevisionID,
  FilesFilterField,
  FilesFilterFieldHelpers,
  FilesSort,
  FilesSortField,
  FilesSortFieldHelpers,
} from '@playq/octopus2-files';
import { BooleanFilter, NumberFilter, TagsFilter, TextFilter, TimeFilter } from '@playq/octopus-common';

import {
  CollectionTable,
  GenericTableRow,
  GenericTableRowActions,
  GenericTableToolbar,
  ICollectionTableColumn,
  ICollectionTableQueryChange,
  ITableRowAction,
  ITableState,
} from '/shared/Table';
import { formatDate, formatSize, getTagLabel, throwGenericFailure } from '/helpers';
import { history } from '/Router/history';
import { confirmDialog } from '/common/ConfirmDialog';
import { services2 } from '/api';
import { ThemeModeContext } from '/common';
import { ITableRowProps } from '/shared/Table/components/TableRow';
import { Tags } from '/shared/Tags';
import { LabelThemed } from '/shared/LabelThemed';
import { Spinner } from '/shared/Spinner/Spinner';
import { SyntheticEventWithMetaKey } from '/common/models';
import { FilesPickDialog } from '/shared/FilesPickDialog';
import { TextAnimation } from '/shared/TextAnimation';

import { checkIfFileStatusShouldBeUpdated, handleSuccessDownloadFile, lastRevision } from '../File/helpers';

import { breakAllCellCSS, breakWordCellCSS, getFilterByMissedIconColor, idCellCSS, ToolbarCollapse } from './styles';
import { IFilesListTableProps, IFilesQuery } from './types';
import { resolvePickerLabel } from './PickerLabel';
import { VersionsPicker } from './VersionsPicker';
import { useMissedFilesFilterData } from './useMissedFilesFilterData';

const FilesTable = CollectionTable.ofType<FileResponse, FilesSortField, FilesFilterField, FilesSort, IFilesQuery>();

const colorScale = d3.scaleOrdinal(d3.schemeTableau10);

const emptyArray: [] = [];

export const FilesListTable: FC<IFilesListTableProps> = (props) => {
  const {
    selectedRevID,
    entitiesProcessing,
    files,
    errorMessage,
    collapseBy,
    initialQuery: query,
    queryKeys,
    processing,
    showOnlyCompletedFiles,
    checkedFiles = [],

    onSelect,
    canWrite,
    onCollapseChange,
    onCollapseApply,
    onDeleteFile,
    onQueryChange,
    ...restProps
  } = props;

  const themeModeContext = useContext(ThemeModeContext);
  const currentTheme = themeModeContext.getCurrentTheme();

  const [isPickerOpened, setIsPickerOpened] = useState<boolean>(false);
  const [searchByMissedEnabled, setSearchByMissedEnabled] = useState(false);

  const missedFilesFilterData = useMissedFilesFilterData({
    filterBy: query.filterBy,
    enabled: searchByMissedEnabled,
  });

  const filterByMissedIconColor = useMemo(
    () => getFilterByMissedIconColor(query.filterBy[FilesFilterField.IsMissed] as BooleanFilter),
    [query.filterBy]
  );

  const handleSearchByMissedOpen = useCallback(() => {
    setSearchByMissedEnabled(true);
  }, []);

  const handlePickerOpen = useCallback(
    (open: boolean) => () => {
      setIsPickerOpened(open);
    },
    []
  );

  const uploadedFileRevisionID = useRef<FileRevisionID | undefined>();
  const retryFileID = useRef<FileID | undefined>();

  const isFileProcessing = useCallback(
    (f: FileResponse) => entitiesProcessing?.[f.file.id.id] === true,
    [entitiesProcessing]
  );

  const getRowKey = useCallback(
    (item: FileResponse): string => `${item.file.id.id}_${item.collapsedCount?.tag.value ?? 'file'}`,
    []
  );

  const download = useCallback((f: FileResponse) => {
    const { revisions } = f;
    if (!revisions?.length) return;
    const latestRevision = revisions[0];

    const id = latestRevision.id;
    services2.filesService
      .download(id, false)
      .then((data) => data.bifold(handleSuccessDownloadFile, throwGenericFailure));
  }, []);

  const handleFilesRead = (fs: File[], fileRevisionID?: FileRevisionID, fileID?: FileID) => {
    setIsPickerOpened(false);
    history.push(`/files/upload`, {
      files: fs,
      selectedFiles: [],
      fileRevisionID: fileRevisionID?.serialize(),
      retryFileID: fileID?.serialize(),
    });
  };

  const upload = useEventCallback((f: FileResponse) => {
    uploadedFileRevisionID.current = lastRevision(f)?.id;
    retryFileID.current = f.file.id;
    setIsPickerOpened(true);
  });

  const handleDelete = (f: FileResponse) => {
    confirmDialog({
      title: `DELETE ${f.file.name}?`,
      text: `Are you sure you want to delete the ${f.file.name}?`,
      closeButton: { label: 'NO' },
      successButton: { label: 'YES' },
      onSuccess: () => onDeleteFile(f),
    });
  };

  const getFileURL = useCallback((file: FileResponse) => `/files/${file.file.id.id}`, []);

  const editFile = useCallback(
    (file: FileResponse) => {
      const url = getFileURL(file);
      history.push(url, { id: file.file.id.id, currentPageQueryKey: queryKeys });
    },
    [queryKeys, getFileURL]
  );

  const handleRowClick = (e: SyntheticEventWithMetaKey, f: FileResponse) => {
    if (onSelect && !e.metaKey) {
      handleSelect(e, f);
      return;
    }
    if (!canWrite(f) || isFileProcessing(f)) {
      return;
    }
    editFile(f);
  };

  const actions: ITableRowAction<FileResponse>[] = [
    {
      label: 'Upload',
      icon: UploadIcon,
      tooltip: (f: FileResponse) => `Upload ${f.file.name}`,
      pending: (f: FileResponse) => isFileProcessing(f),
      onClick: (_event, f: FileResponse) => upload(f),
      hidden: (f: FileResponse) => {
        const { missed, processing: uploading } = checkIfFileStatusShouldBeUpdated(f, checkedFiles);
        return !missed || uploading;
      },
    },
    {
      label: 'Download',
      icon: CloudDownloadIcon,
      tooltip: (f: FileResponse) => `Download ${f.file.name}`,
      pending: (f: FileResponse) => isFileProcessing(f),
      onClick: (_event, f: FileResponse) => download(f),
      hidden: (f: FileResponse) => {
        const { missed, processing: uploading } = checkIfFileStatusShouldBeUpdated(f, checkedFiles);
        return missed || uploading;
      },
    },
    {
      label: 'Delete',
      icon: DeleteIcon,
      tooltip: (f: FileResponse) => `Delete ${f.file.name}`,
      pending: (f: FileResponse) => isFileProcessing(f),
      hidden: (f: FileResponse) => !canWrite(f),
      onClick: (_event, f: FileResponse) => handleDelete(f),
    },
  ];

  const handleSelect = useCallback(
    (event: SyntheticEvent, f: FileResponse) => {
      event.stopPropagation();
      if (onSelect) {
        onSelect({ fileRevision: lastRevision(f), fileResponse: f });
      }
    },
    [onSelect]
  );

  const columns: ICollectionTableColumn<FileResponse, FilesSortField, FilesFilterField>[] = [
    {
      filter: FilesFilterField.Id,
      filterType: NumberFilter.ClassName,
      sort: FilesSortField.Id,
      label: 'ID',
      styles: {
        cell: idCellCSS,
      },
      customRender: true,
      render: (f: FileResponse) => {
        if (processing) {
          return <Spinner centered={true} size={20} />;
        }
        return f.collapsedCount ? (
          <Badge max={999999} badgeContent={f.collapsedCount.count} color='secondary'>
            <LabelThemed>{getTagLabel(f.collapsedCount.tag)}</LabelThemed>
          </Badge>
        ) : (
          <>{f.file.id.id}</>
        );
      },
    },
    {
      filter: FilesFilterField.Name,
      filterType: TextFilter.ClassName,
      sort: FilesSortField.Name,
      label: 'Name',
      styles: { cell: breakAllCellCSS },
      render: (f: FileResponse) => (
        <>
          {f.file.name}
          {selectedRevID && selectedRevID.file === f.file.id.id && <LabelThemed>Selected</LabelThemed>}
        </>
      ),
    },
    {
      label: 'Author',
      filter: FilesFilterField.AuthorName,
      filterType: TextFilter.ClassName,
      sort: FilesSortField.AuthorName,
      styles: { cell: breakWordCellCSS },
      render: (f: FileResponse) => f.authorName,
    },
    {
      label: 'Status',
      filter: FilesFilterField.IsMissed,
      filterType: BooleanFilter.ClassName,
      filterData: missedFilesFilterData,
      fixedFilter: showOnlyCompletedFiles,
      searchIconColor: filterByMissedIconColor,
      onFilterOpen: handleSearchByMissedOpen,
      formatFilterPreview: (filter) =>
        (filter as BooleanFilter).value ? (
          <Typography variant='body2' color='error' sx={{ ml: 0.5 }}>
            Missed
          </Typography>
        ) : (
          <Typography variant='body2' sx={{ color: 'success.main', ml: 0.5 }}>
            Exists
          </Typography>
        ),
      render: (f: FileResponse) => {
        const { missed, processing: uploading } = checkIfFileStatusShouldBeUpdated(f, checkedFiles);
        if (!missed || processing) {
          return null;
        }
        return uploading ? (
          <TextAnimation color={orange[500]}>Uploading</TextAnimation>
        ) : (
          <Typography color='error' variant='body2'>
            Missed
          </Typography>
        );
      },
    },
    {
      label: 'UpdatedAt',
      filter: FilesFilterField.UpdatedAt,
      filterType: TimeFilter.ClassName,
      sort: FilesSortField.UpdatedAt,
      styles: { cell: breakWordCellCSS },
      render: (f: FileResponse) => formatDate(f.file.updatedAt),
    },
    {
      label: 'Size',
      sort: FilesSortField.Size,
      filter: FilesFilterField.Size,
      filterType: NumberFilter.ClassName,
      render: (f: FileResponse) => {
        const lR = lastRevision(f);
        const size = lR != null ? lR.size : 0;
        return (
          <Tooltip title={`In bytes: ${size} bytes`}>
            <span>{formatSize(size)}</span>
          </Tooltip>
        );
      },
    },
    {
      label: 'Tags',
      filter: FilesFilterField.Tag,
      filterType: TagsFilter.ClassName,
      render: (f: FileResponse) => <Tags tags={f.tags} onTagClick={onCollapseChange} viewOnly={true} />,
    },
    {
      label: 'Last Revision',
      filter: FilesFilterField.Comment,
      filterType: TextFilter.ClassName,
      styles: { cell: breakWordCellCSS },
      render: (f: FileResponse) => {
        if (onSelect) {
          return (
            <VersionsPicker
              onSelect={onSelect}
              file={f}
              isLink={false}
              renderLabel={resolvePickerLabel(currentTheme)}
            />
          );
        }

        const lR = lastRevision(f);
        return lR != null ? lR.comment : '';
      },
    },
  ];

  const Toolbar = useMemo(
    () => (state: ITableState<FileResponse>) => {
      return (
        <Stack>
          {collapseBy && (
            <ToolbarCollapse>
              <b>Collapse By:</b>&nbsp;
              <Chip label={collapseBy.key} variant='outlined' onDelete={() => onCollapseChange()} />
            </ToolbarCollapse>
          )}

          <Box flex={1}>
            <GenericTableToolbar {...state} />
          </Box>
        </Stack>
      );
    },
    [collapseBy, onCollapseChange]
  );

  const Row = useMemo(
    () =>
      ({ rowData, ...tableState }: ITableRowProps<FileResponse>) => {
        let overrideProps = {};
        if (rowData.item.collapsedCount) {
          overrideProps = {
            style: {
              backgroundColor: Object.assign(d3.color(colorScale(rowData.id.toString())) as d3.RGBColor, {
                opacity: 0.2,
              }),
              cursor: 'pointer',
            },
            onClick: () => {
              if (rowData.item.collapsedCount) {
                onCollapseApply(rowData.item.collapsedCount.tag);
              }
            },
          };
        }

        return <GenericTableRow<FileResponse> rowData={rowData} overrideProps={overrideProps} {...tableState} />;
      },
    [onCollapseApply]
  );

  const RowActions = useMemo(
    () =>
      ({ rowData, ...tableState }: ITableRowProps<FileResponse>) => {
        if (rowData.item.collapsedCount) {
          return null;
        }

        return <GenericTableRowActions<FileResponse> rowData={rowData} {...tableState} />;
      },
    []
  );

  const handleQueryChange = useEventCallback((qc: ICollectionTableQueryChange<IFilesQuery>) => {
    onQueryChange(qc.query);
  });

  return (
    <>
      <FilesTable
        sortHelper={FilesSortFieldHelpers}
        filterHelper={FilesFilterFieldHelpers}
        sortClass={FilesSort}
        getRowKey={getRowKey}
        actions={onSelect ? emptyArray : actions}
        columns={columns}
        data={files}
        error={errorMessage}
        processing={processing}
        placeholder={{
          text: 'There is no files yet.',
        }}
        components={{
          Toolbar,
          Row,
          RowActions,
        }}
        initialQuery={query}
        queryKeys={queryKeys}
        onQueryChange={handleQueryChange}
        onRowClick={handleRowClick}
        getEntityURL={getFileURL}
        {...restProps}
      />
      <FilesPickDialog
        open={isPickerOpened}
        fileRevisionID={uploadedFileRevisionID.current}
        retryFileID={retryFileID.current}
        onFilesRead={handleFilesRead}
        onClose={handlePickerOpen(false)}
      />
    </>
  );
};
