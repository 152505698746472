import { FC } from 'react';
import { Switch, ListItem, ListItemText, Grid } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

import { TextFieldValidator } from '/shared/TextFieldValidator';

import { AvatarStyled } from './styles';
import { IMarketProps } from './types';

export const Market: FC<IMarketProps> = (props) => {
  const {
    market,
    marketId,
    imageUrl,
    imageSelected,
    selected,
    readonly,
    validationSchema,
    selectMarketImage,
    changeMarket,
    onIdChange,
  } = props;

  const handleSelectMarketImage = () => {
    if (!imageUrl || imageSelected || !selectMarketImage) {
      return;
    }
    selectMarketImage(market);
  };
  const toggleMarket = () => changeMarket(true);

  return (
    <ListItem sx={{ minHeight: '65px', padding: 0 }}>
      <Grid container gap={2} alignItems='center'>
        <Grid item xs='auto' onClick={handleSelectMarketImage}>
          <AvatarStyled
            src={imageUrl}
            alt='app-market-icon'
            ownerState={{
              imageSelected,
              imageUrl,
            }}
          >
            <PhotoCamera />
          </AvatarStyled>
        </Grid>
        <Grid item md='auto'>
          <Switch data-testid={`${market}-switch`} onChange={toggleMarket} checked={selected} disabled={readonly} />
        </Grid>
        <Grid item xs={4} md={3}>
          <ListItemText primary={market} />
        </Grid>
        {selected && (
          <Grid item md>
            <div>
              <TextFieldValidator
                sx={{ marginBottom: '17px' }}
                inputProps={{ 'data-testid': `${market}_id` }}
                disabled={readonly}
                required
                label={`${market} id...`}
                value={marketId}
                onChange={onIdChange}
                schema={validationSchema}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};
