import { FC, forwardRef, memo, ReactElement, ReactNode, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Dialog, Slide, Toolbar, Typography } from '@mui/material';
import { ArrowBack as ArrowBackIcon, Close as CloseIcon, Folder as FolderIcon } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';

import { FileFeature, FileID, FileResponse, FileRevision, FileRevisionID } from '@playq/octopus2-files';

import { services2 } from '/api';
import { appToolkit, authToolkit } from '/store';
import { throwGenericFailure } from '/helpers';
import { FilesPickDialog } from '/shared/FilesPickDialog/FilesPickDialog';
import { Dropzone } from '/shared/Dropzone';
import { FilesList } from '/component/Files/FilesList/FilesList';
import { FilesUpload } from '/component/Files/FilesUpload';
import { PersistedQueryKeys } from '/common/models';

import { IconButtonStyled, TableContainer } from './styles';

export interface ISelectedFile {
  fileRevision: FileRevision;
  fileResponse: FileResponse;
}

export interface IFilePickerProps {
  open?: boolean;
  selectedRevID?: FileRevisionID;
  globalSearch?: string;
  idSearch?: string;
  features?: FileFeature[];
  disablePersist?: boolean;
  showOnlyCompletedFiles?: boolean;

  onClose?: (event: SyntheticEvent) => void;
  onSelect: (selectedFile: ISelectedFile) => void;
}

enum RenderComponentID {
  List = 'List',
  Upload = 'Upload',
  File = 'File',
}

interface IFilePickerState {
  id: RenderComponentID;
  component: ReactNode;
  title: string;
}

export const FilePickerContent: FC<IFilePickerProps> = (props) => {
  const { selectedRevID, globalSearch, idSearch, features, disablePersist, showOnlyCompletedFiles, onClose, onSelect } =
    props;
  const [file, setFile] = useState<FileResponse | undefined>(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const app = useSelector(appToolkit.selectors.app);
  const [state, setState] = useState<IFilePickerState>(() => {
    return getListComponent();
  });

  const company = useSelector(authToolkit.selectors.company);

  function getListComponent(): IFilePickerState {
    return {
      id: RenderComponentID.List,
      component: (
        <FilesList
          key={1}
          globalSearch={globalSearch}
          idSearch={idSearch}
          selectedFile={file}
          selectedRevID={selectedRevID}
          onSelect={onSelect}
          queryKey={disablePersist ? undefined : PersistedQueryKeys.FilePicker}
          showOnlyCompletedFiles={showOnlyCompletedFiles}
          features={features}
        />
      ),
      title: `Select file from list`,
    };
  }

  function getUploadComponent(files: File[]): IFilePickerState {
    return {
      id: RenderComponentID.List,
      component: <FilesUpload key={2} initFiles={files} app={app} onUploadFinish={handleStepBack} />,
      title: 'Upload new files',
    };
  }

  useEffect(() => {
    let didCancel = false;
    if (!selectedRevID || !company) {
      return;
    }

    const fileID = new FileID();
    fileID.company = company.id;
    fileID.id = selectedRevID.file;

    services2.filesService.getFile(fileID, [FileFeature.Revisions, FileFeature.Tags]).then((data) =>
      data.bifold(
        (f) => {
          if (!didCancel) {
            setFile(f);
          }
        },
        (err) => throwGenericFailure(err)
      )
    );
    return () => {
      didCancel = true;
    };
  }, [company, selectedRevID]);

  const handleFilesRead = (fs: File[]) => {
    setState(getUploadComponent(fs));
    setDialogOpen(false);
  };

  const handleStepBack = () => {
    setState(getListComponent());
  };

  const handleDialogOpen = (val: boolean) => () => {
    setDialogOpen(val);
  };

  const isBackIcon = useMemo(() => state.id !== RenderComponentID.List, [state.id]);

  return (
    <>
      <AppBar>
        <Toolbar>
          <IconButtonStyled onClick={onClose} size='large'>
            <CloseIcon />
          </IconButtonStyled>

          {isBackIcon && (
            <IconButtonStyled onClick={handleStepBack} size='large'>
              <ArrowBackIcon />
            </IconButtonStyled>
          )}

          <Typography variant='h6' color='inherit' flex={1}>
            {state.title}
          </Typography>

          <IconButtonStyled data-testid='icon-upload' aria-label='Upload' size='large' onClick={handleDialogOpen(true)}>
            <FolderIcon />
          </IconButtonStyled>
        </Toolbar>
      </AppBar>
      <TableContainer>
        <Dropzone onFilesRead={handleFilesRead} disable={dialogOpen}>
          {state.component}
        </Dropzone>
      </TableContainer>
      <FilesPickDialog open={dialogOpen} onFilesRead={handleFilesRead} onClose={handleDialogOpen(false)} />
    </>
  );
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SlideTransition = forwardRef<unknown, TransitionProps & { children: ReactElement<any, any> }>(
  (tranProps, ref) => (
    <Slide direction='up' ref={ref} {...tranProps}>
      {tranProps.children}
    </Slide>
  )
);
export const FilePicker: FC<IFilePickerProps> = memo((props) => (
  <Dialog
    TransitionComponent={SlideTransition}
    open={props.open || false}
    onClose={props.onClose}
    fullScreen={true}
    fullWidth={true}
    maxWidth='lg'
    aria-labelledby='file-picker'
  >
    <FilePickerContent {...props} />
  </Dialog>
));
