import { styled } from '@mui/material';

export const ContainerStyled = styled('div')({
  display: 'flex',
  position: 'relative',
  width: 'inherit',
  height: 'inherit',
  justifyContent: 'center',
  alignItems: 'center',
});
