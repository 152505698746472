import { MouseEvent } from 'react';

import { SortDirection } from '@playq/octopus-common';

import { ITableColumn, ITableColumnSort, ITableState } from '/shared/Table/interfaces';
import { HeaderCellWrapper } from '/shared/Table';

import { CellLabel, HeaderCell, SortCell, SortLabel } from './styles';

export interface ITableHeadCellProps<D> extends ITableState<D> {
  column: ITableColumn<D>;
}

export function GenericTableHeadCell<D>({ column, ...props }: ITableHeadCellProps<D>) {
  const { sortMap, onSortChange, components, styles } = props;

  /**
   * Same to <TableCell/> component has 2 variant of get styles:
   * 1. Directly from each column declaration
   * 2. From the global table styles
   * So this is reason to manage 2 variant of css prop styles here.
   */
  const { styles: columnStyles } = column;
  const { headerCell: columnHeaderCellCSS, cell: columnCellCSS } = columnStyles ?? {};

  const { headerCell: headerCellCSS } = styles ?? {};

  const getSortDirection = () => {
    const { sortField } = column;
    if (!sortField || !sortMap.has(sortField.toString())) {
      return undefined;
    }
    const sort = sortMap.get(sortField.toString()) as ITableColumnSort<string>;
    return sort.ord === SortDirection.Ascending ? 'asc' : 'desc';
  };

  const handleSortChange = (event: MouseEvent<HTMLElement>) => {
    onSortChange(event, column.sortField);
  };

  return (
    <HeaderCellWrapper
      align={column.align ? column.align : 'inherit'}
      size={column.disablePadding ? 'small' : undefined}
      sortDirection={getSortDirection()}
      $columnHidden={Boolean(column.hidden)}
      $css={headerCellCSS}
      $cssColumn={columnHeaderCellCSS}
      $cellCSS={columnCellCSS}
    >
      <HeaderCell>
        {!column.sortField ? (
          <CellLabel>{column.label}</CellLabel>
        ) : (
          <SortCell>
            <SortLabel
              active={!sortMap.has(column.sortField.toString())}
              direction={getSortDirection()}
              onClick={handleSortChange}
            >
              {column.label}
            </SortLabel>
          </SortCell>
        )}

        {!!column.filterField && <components.HeadCellFilter column={column} {...props} />}
      </HeaderCell>
    </HeaderCellWrapper>
  );
}
