import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  CssBaseline,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
  useEventCallback,
} from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { FaGoogle, FaUser } from 'react-icons/fa';
import { reach } from 'yup';

import { LoginResponse, LoginWithEmail, SuccessfulLogin } from '@playq/octopus2-auth';
import { GenericFailure } from '@playq/services-shared';
import { Either } from '@playq/irt';

import { authToolkit, useAppDispatch } from '/store';
import { login as loginAction } from '/store/toolkits/auth/actions';
import { companyId } from '/constants';
import { services2 } from '/api';
import { TextFieldValidator } from '/shared/TextFieldValidator';
import { snackbarService } from '/common/snackbarService';
import { history } from '/Router/history';
import { oauthSignIn } from '/component/Login/helpers';

import { AvatarStyled, FormStyled, PaperStyled, ResetPasswordLink, VersionSection } from './styles';
import { loginSchema } from './schema';

interface Credentials {
  email: string;
  passHash: string;
  readonly companyId: number;
}

export const Login: FC = () => {
  const dispatch = useAppDispatch();

  const rememberMe = useSelector(authToolkit.selectors.rememberMe);
  const loginRedirectURL = useSelector(authToolkit.selectors.loginRedirectURL);

  const [state, setState] = useState({
    credentials: {
      email: '',
      passHash: '',
      companyId,
    },
    loading: false,
    isValid: false,
  });
  const updateGoogleRedirectURL = useEventCallback((url?: string) =>
    dispatch(authToolkit.actions.updateGoogleRedirectURL(url))
  );

  const updateRememberMe = useEventCallback((rm: boolean) => dispatch(authToolkit.actions.updateRememberMe(rm)));
  const login = useEventCallback((payload: SuccessfulLogin) => dispatch(loginAction(payload)));

  const handleChange = (name: keyof Credentials) => (event: ChangeEvent<HTMLInputElement>) => {
    const newVal = event.target.value;

    const newCredentials = {
      ...state.credentials,
      [name]: newVal,
    };
    setState({
      ...state,
      credentials: newCredentials,
      isValid: loginSchema.isValidSync(newCredentials),
    });
  };

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    setState((s) => ({ ...s, loading: true }));
    const emailReq = new LoginWithEmail(state.credentials);
    services2.authService.login(emailReq).then((resData: Either<GenericFailure, LoginResponse>) => {
      resData.bifold(
        (loginResponse: LoginResponse) => {
          if (loginResponse instanceof SuccessfulLogin) {
            setState((s) => ({ ...s, loading: false }));
            login(loginResponse);
          }
        },
        (err: GenericFailure) => {
          console.error(err);
          snackbarService.error(`Login failed. msg: ${err.message}`);
          setState((s) => ({ ...s, loading: false }));
        }
      );
    });
  };

  const handleLoginWithGoogle = (): void => {
    oauthSignIn();
  };

  const handleRememberMeChange = useCallback(() => updateRememberMe(!rememberMe), [updateRememberMe, rememberMe]);

  useEffect(() => {
    updateGoogleRedirectURL(loginRedirectURL !== undefined ? loginRedirectURL : location.pathname);
  }, [loginRedirectURL, updateGoogleRedirectURL]);

  const handleResetPassword = () => history.push('/resetPassword');
  return (
    <Container maxWidth='xs' component='main'>
      <CssBaseline />

      <PaperStyled>
        <AvatarStyled>
          <LockOutlined />
        </AvatarStyled>

        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>

        <Typography component='h2' variant='subtitle1'>
          to continue to Octopus
        </Typography>

        <FormStyled onSubmit={handleSubmit}>
          <TextFieldValidator
            name='email'
            type='email'
            label='Email Address'
            fullWidth
            inputProps={{ 'data-testid': 'input-email' }}
            autoComplete='email'
            value={state.credentials.email}
            schema={reach(loginSchema, 'email')}
            onChange={handleChange('email')}
            autoFocus={true}
          />
          <div>
            <TextFieldValidator
              name='password'
              type='password'
              label='Password'
              inputProps={{ 'data-testid': 'input-password' }}
              required={true}
              fullWidth
              autoComplete='current-password'
              value={state.credentials.passHash}
              onChange={handleChange('passHash')}
              schema={reach(loginSchema, 'passHash')}
            />

            <FormControlLabel
              control={
                <Checkbox checked={rememberMe} onChange={handleRememberMeChange} value='remember' color='primary' />
              }
              label='Remember me'
              sx={{ mt: 1 }}
            />
          </div>
          <Stack spacing={2}>
            <Button
              type='submit'
              disabled={!state.isValid || state.loading}
              fullWidth
              variant='contained'
              color='primary'
              data-testid='button-login'
            >
              {state.loading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                <>
                  <FaUser />
                  &nbsp;Sign in
                </>
              )}
            </Button>

            <ResetPasswordLink color='textSecondary' onClick={handleResetPassword} className='disabled-link'>
              Reset password (disabled)
            </ResetPasswordLink>
            <Divider sx={{ opacity: '0.6' }} />
            <Button
              disabled={false}
              fullWidth
              variant='contained'
              color='secondary'
              data-testid='sign-in'
              onClick={handleLoginWithGoogle}
            >
              <FaGoogle />
              &nbsp;Sign in with Google
            </Button>
            <VersionSection hidden={process.env.NODE_ENV !== 'production'}>{`v${__CI_BUILD__}`}</VersionSection>
          </Stack>
        </FormStyled>
      </PaperStyled>
    </Container>
  );
};
