import { CircularProgress, css, styled, TextField } from '@mui/material';
import { green } from '@mui/material/colors';

import { TextFieldValidator } from '/shared/TextFieldValidator';

export const StyledTextField = styled(TextField)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const StyledTextFieldValidator = styled(TextFieldValidator)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const ActionWrapper = styled('div')`
  position: relative;
`;

export const ActionCircularProgress = styled(CircularProgress)`
  color: ${green[500]};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

export const ListActionsWrapper = styled('div')`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
`;

export const actionsMenuCSS = css`
  & .MuiPaper-root {
    max-width: 230px;
  }
`;
