import { CardHeader } from '@mui/material';

import { TitleWrapperStyles, IconStyles, setColor } from '../styles';
import { ConfirmDialogHeaderProps } from '../types';

export const ConfirmDialogHeader = (props: ConfirmDialogHeaderProps) => {
  const { type } = props;

  const title = props.title ?? 'Are you sure?';

  const colorProps = setColor(type);

  return (
    <CardHeader
      title={
        <TitleWrapperStyles custom={colorProps.custom}>
          {title}
          <IconStyles custom={colorProps.custom} />
        </TitleWrapperStyles>
      }
    />
  );
};
