import { UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { GenericFailure } from '@playq/services-shared';
import { MetaResponse } from '@playq/services-beetle';
import { AppID, Environment, TimePrecision } from '@playq/octopus-common';

import { useEitherQuery } from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { iteratorMaxLimit, timeUnitPrecisionMillisecondValues } from '/constants';
import { SupportContentType } from '/component/Support/BeetlesLookup/types';

import { methods } from './serviceHandlerRequest';

export const metaKeys: unknown[] = ['query', 'service', 'audience', 'meta'];

const emptyArray: [] = [];

export const useQueryMeta = (
  type: SupportContentType,
  env: Environment = Environment.Live,
  entityExist?: boolean,
  keys = iteratorMaxLimit,
  valuesPerKey = iteratorMaxLimit,
  customAppId?: AppID | undefined,
  options?: UseQueryOptions<MetaResponse | undefined, GenericFailure | Error>
) => {
  const request = methods(type);
  const appID = useSelector(appToolkit.selectors.appID) as AppID;

  const customId = customAppId ?? appID;
  const tags = metaKeys.concat(type, env, keys, valuesPerKey, customId.serialize());

  const res = useEitherQuery(tags, () => request.queryMeta(customId, keys, valuesPerKey, env), {
    keepPreviousData: true,
    staleTime: timeUnitPrecisionMillisecondValues[TimePrecision.Minute] * 5,
    onError: (err) => snackbarService.genericFailure(err.message),
    enabled: entityExist,
    ...options,
  });

  return { ...res, keys: res.data?.keys || emptyArray };
};
