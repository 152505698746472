import { MouseEvent, useEffect, useState } from 'react';
import { Badge, Checkbox, IconButton, List, ListItemButton, ListItemText, Popover, styled } from '@mui/material';
import { ViewColumn as ViewColumnIcon } from '@mui/icons-material';

import { ITableColumn, ITableColumnsSelectionProps, ITableState } from '/shared/Table';
import { createStorage } from '/storage';
import { IWithCSS } from '/common/models';

type ITableColumnsSelection<D> = Pick<ITableState<D>, 'columns' | 'hiddenColumns' | 'setHiddenColumns' | 'styles'> & {
  showCheck?: boolean;
};

const storage = createStorage();
const columnsCachePrefix = '#columns_';

const StyledIconButton = styled(IconButton)<IWithCSS>`
  ${({ css }) => css}
`;

export function GenericTableColumnSelection<D>(props: ITableColumnsSelection<D>) {
  const { columns, hiddenColumns, setHiddenColumns, styles, showCheck = false } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { toolbarColumnSelection: toolbarColumnSelectionCSS } = styles ?? {};

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (column: ITableColumn<D>) => () => {
    if (isColumnVisible(column)) {
      setHiddenColumns((c) => {
        if (column.label) {
          return c.concat(column.label);
        }

        return c;
      });
    } else {
      setHiddenColumns((c) => c.filter((hiddenColumn) => hiddenColumn !== column.label));
    }
  };

  const isColumnVisible = (column: ITableColumn<D>) =>
    !hiddenColumns.some((hiddenColumn) => hiddenColumn === column.label);

  const isAllColumnsAlwaysVisible = columns.every((c) => c.alwaysVisible);

  const open = Boolean(anchorEl);
  return !isAllColumnsAlwaysVisible ? (
    <>
      <StyledIconButton
        onClick={handleClick}
        data-testid='columns-selection-btn'
        size='large'
        css={toolbarColumnSelectionCSS}
      >
        <Badge invisible={!hiddenColumns.length} color='secondary' variant='dot'>
          <ViewColumnIcon />
        </Badge>
      </StyledIconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          {columns.map((column) =>
            !column.alwaysVisible ? (
              <ListItemButton key={column.label} dense={true} onClick={handleToggle(column)}>
                <Checkbox
                  checked={showCheck ? !isColumnVisible(column) : isColumnVisible(column)}
                  disableRipple={true}
                  tabIndex={-1}
                  sx={{
                    marginRight: '8px',
                    padding: 0,
                  }}
                />
                <ListItemText primary={column.label} />
              </ListItemButton>
            ) : null
          )}
        </List>
      </Popover>
    </>
  ) : null;
}

export function CollectionTableColumnSelectionDetached<D>(props: ITableColumnsSelectionProps<D>) {
  const { setHiddenColumns, hiddenColumns, styles = {} } = props;

  useEffect(() => {
    const item = storage.get(columnsCachePrefix + window.location.pathname);
    if (item) {
      setHiddenColumns(JSON.parse(item) as string[]);
    }
  }, [setHiddenColumns]);

  useEffect(() => {
    storage.set(columnsCachePrefix + window.location.pathname, JSON.stringify(hiddenColumns));
  }, [hiddenColumns]);

  return <GenericTableColumnSelection {...props} styles={styles} />;
}
