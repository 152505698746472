/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { FC, useEffect, useRef, useState } from 'react';
import { Fade } from '@mui/material';

import { StyledTooltip } from '/shared/StyledTooltip';

import { StyledIconButton } from './styles';
import { IIconButtonWithTooltipProps } from './types';
import { TOOLTIP_FADE_OUT_TIMEOUT_MS, TOOLTIP_FADE_TRANSITION_DURATION_MS } from './constants';

export const IconButtonWithTooltip: FC<IIconButtonWithTooltipProps> = (props) => {
  const {
    tooltipProps: { enterDelay, leaveDelay, open: openValueProvided, ...tooltipProps },
    icon,
    tooltipInitialFadeOut,
    disabled,
    onClick,
    styles = {},
  } = props;
  const { button: buttonCSS, tooltip: tooltipCSS } = styles;
  const [isOpen, setIsOpen] = useState(false);

  const tooltipTogglingTimeoutRef = useRef<NodeJS.Timeout>();
  const isTooltipTogglingDeferred = tooltipTogglingTimeoutRef.current !== undefined;

  const onTooltipOpen = () => {
    if (isTooltipTogglingDeferred) {
      clearTimeout(tooltipTogglingTimeoutRef.current);
    }

    tooltipTogglingTimeoutRef.current = setTimeout(() => {
      setIsOpen(true);
    }, enterDelay);
  };

  const onTooltipClose = () => {
    if (isTooltipTogglingDeferred) {
      clearTimeout(tooltipTogglingTimeoutRef.current);
    }

    tooltipTogglingTimeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, leaveDelay);
  };

  useEffect(
    function fadeOutTooltipAfterFirstPaint() {
      if (!tooltipInitialFadeOut) {
        return;
      }

      setIsOpen(true);

      const initialFadeInTimeout = setTimeout(
        () => {
          setIsOpen(false);
        },
        (typeof tooltipInitialFadeOut !== 'boolean' ? tooltipInitialFadeOut.leaveDelay : null) ??
          TOOLTIP_FADE_OUT_TIMEOUT_MS
      );

      return () => {
        clearTimeout(initialFadeInTimeout);
      };
    },
    // This should be invoked only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(function clearTooltipTogglingTimeout() {
    return () => {
      if (tooltipTogglingTimeoutRef.current !== undefined) {
        clearTimeout(tooltipTogglingTimeoutRef.current);
      }
    };
  }, []);

  return (
    <StyledTooltip
      {...{ placement: 'bottom', ...tooltipProps }}
      open={openValueProvided ?? isOpen}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: TOOLTIP_FADE_TRANSITION_DURATION_MS }}
      onOpen={onTooltipOpen}
      onClose={onTooltipClose}
      css={tooltipCSS}
    >
      <StyledIconButton css={buttonCSS} disabled={disabled} onClick={onClick}>
        {icon}
      </StyledIconButton>
    </StyledTooltip>
  );
};
