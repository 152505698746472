import { FC } from 'react';

import { TableContentWrapper, TableStyled, TableWrapperStyled } from '/shared/Table/styles';
import { useTableState } from '/shared/Table/useTableState';

import { ITableProps } from './interfaces';
import { TableError } from './components/TableError';

function GenericTable<D, S extends string = string, F extends string = string>(props: ITableProps<D, S, F>) {
  const state = useTableState(props);
  const { options, components, error, filterBy, sortBy, size, styles } = state;
  const { withToolbar, withPagination } = options;
  const { Toolbar, Pagination, Head, Body, QueryRow } = components;

  const customQueryRowComponent = props.customQueryRowComponent ?? null;
  const showQueryRow = !!customQueryRowComponent || Object.values(filterBy).length > 0 || sortBy.length > 0;
  const { tableWrapper: tableWrapperCSS, tableContentWrapper: tableContentWrapperCSS } = styles ?? {};

  return (
    <>
      {error !== undefined && error !== '' ? (
        <TableError error={error} />
      ) : (
        <>
          <TableWrapperStyled css={tableWrapperCSS}>
            {withToolbar && <Toolbar {...state} />}
            {showQueryRow && <QueryRow {...state} />}
            <TableContentWrapper css={tableContentWrapperCSS}>
              <TableStyled size={size} aria-labelledby='tableTitle'>
                <Head {...state} />
                <Body {...state} />
              </TableStyled>
            </TableContentWrapper>
            {withPagination && <Pagination {...state} />}
          </TableWrapperStyled>
        </>
      )}
    </>
  );
}

GenericTable.ofType = <D, S extends string = string, F extends string = string>() => {
  return GenericTable as FC<ITableProps<D, S, F>>;
};

export { GenericTable };
