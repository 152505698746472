import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';

import { SelectDialog } from '/shared/SelectDialog';
import { handleEnterPress } from '/helpers';

import { IConfirmDialogProps, IConfirmProps } from './types';
import { ConfirmDialogHeader, ConfirmDialogActions } from './components';
import { ConfirmDialogContent } from './components/ConfirmDialogContent';

const subject = new Subject<IConfirmDialogProps>();

export const confirmDialog = (props: IConfirmProps) => {
  subject.next({ ...props, open: true });
};

export const ConfirmDialogWrapper = () => {
  const [props, setProps] = useState<IConfirmDialogProps>({
    open: false,
  });
  const { type } = props;

  useEffect(() => {
    const sub = subject.subscribe((nextProps: IConfirmDialogProps) => {
      setProps(nextProps);
    });
    return () => sub.unsubscribe();
  }, []);

  const closeDialog = () => {
    setProps((p: IConfirmDialogProps) => ({
      ...p,
      open: false,
    }));
  };

  const handleCloseClick = () => {
    if (props.closeButton?.onClick) {
      props.closeButton.onClick(closeDialog);
    } else {
      closeDialog();
      if (props.onClose) {
        props.onClose();
      }
      if (props.afterClose) {
        props.afterClose();
      }
    }
  };

  const handleSuccessClick = () => {
    if (props.successButton?.onClick) {
      props.successButton.onClick(closeDialog);
    } else {
      closeDialog();
      if (props.onSuccess) {
        props.onSuccess();
      }
      if (props.afterClose) {
        props.afterClose();
      }
    }
  };

  return (
    <SelectDialog
      data-testid='confirm-dialog-wrapper'
      Header={<ConfirmDialogHeader type={type} title={props.title} />}
      CustomActions={
        !props.withoutActions ? (
          <ConfirmDialogActions
            type={type}
            closeButton={props.closeButton}
            successButton={props.successButton}
            onClose={handleCloseClick}
            onSuccess={handleSuccessClick}
          />
        ) : null
      }
      maxWidth='md'
      fullWidth={false}
      Content={<ConfirmDialogContent text={props.text} />}
      open={props.open}
      onClose={closeDialog}
      onKeyUp={handleEnterPress(handleSuccessClick)}
      {...props.dialogProps}
    />
  );
};
