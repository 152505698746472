import { ChangeEvent, FC, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Stack,
} from '@mui/material';
import { SpeedDialIcon } from '@mui/material';
import { GrAppleAppStore } from 'react-icons/gr';
import { FaTag } from 'react-icons/fa';
import { object, reach, string } from 'yup';

import { Market, MarketHelpers } from '@playq/services-shared';

import { services2 } from '/api';
import { throwGenericFailure } from '/helpers';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';

import {
  SpeedDialActionIcon,
  StyledDialogContent,
  StyledFormHelperText,
  StyledSpeedDial,
  StyledSpeedDialAction,
  StyledTextFieldValidator,
  SubmitButton,
} from './styles';

const notificationsService = services2.notificationsTimelineService;
const markets = MarketHelpers.all;

const validationSchema = object().shape({
  message: string().required(),
});
const messageReach = reach(validationSchema, 'message');

export const AppTags: FC = memo(() => {
  const appID = useSelector(appToolkit.selectors.appID);
  const [message, setMessage] = useState('');
  const [showMarket, setShowMarket] = useState(false);
  const [market, setMarket] = useState<Market>(markets[0]);
  const [version, setVersion] = useState('');
  const [error, setError] = useState<string | undefined>();
  const [open, setOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [pending, setPending] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFormOpen = () => {
    setMessage('');
    setVersion('');
    setMarket(markets[0]);
    setError(undefined);
    setFormOpen(true);
    setPending(false);
  };
  const handleTagFormOpen = () => {
    setShowMarket(false);
    handleFormOpen();
  };
  const handleMarketTagFormOpen = () => {
    setShowMarket(true);
    handleFormOpen();
  };

  const handleFormClose = () => {
    setFormOpen(false);
  };

  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setMessage(value);
    validationSchema
      .validateAt('message', { message: value })
      .then(() => setError(undefined))
      .catch((e: Error) => {
        setError(e.message);
      });
  };

  const handleVersionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVersion(event.target.value);
  };

  const handleMarketChange = (event: SelectChangeEvent<Market>) => {
    setMarket(event.target.value as Market);
  };

  const handleSubmit = () => {
    if (appID && message && validationSchema.isValidSync({ message })) {
      const request = showMarket
        ? notificationsService.createMarketAppTag(appID, market, version || undefined, message)
        : notificationsService.createAppTag(appID, message);
      setPending(true);
      request
        .then((data) =>
          data.bifold(() => {
            handleFormClose();
            snackbarService.success('Tag has been created');
          }, throwGenericFailure)
        )
        .catch((e: Error) => {
          setPending(false);
          snackbarService.error('Failed to create tag:', e.message);
        });
    }
  };

  const needDisableButton = !!error || !message || pending;

  return (
    <>
      <StyledSpeedDial
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        icon={<SpeedDialIcon />}
        FabProps={{
          size: 'small',
        }}
        direction='up'
        ariaLabel='timeline-annotation'
      >
        <StyledSpeedDialAction
          onClick={handleTagFormOpen}
          icon={<SpeedDialActionIcon Icon={FaTag} />}
          title='Add app tag'
        />
        <StyledSpeedDialAction
          onClick={handleMarketTagFormOpen}
          icon={<SpeedDialActionIcon Icon={GrAppleAppStore} />}
          title='Add app market tag'
        />
      </StyledSpeedDial>
      <Dialog open={formOpen} onClose={handleFormClose}>
        <DialogTitle>{showMarket ? 'Add Market App Tag' : 'Add App Tag'}</DialogTitle>
        <StyledDialogContent>
          <StyledTextFieldValidator
            value={message}
            onChange={handleMessageChange}
            schema={messageReach}
            FormHelperTextProps={{
              component: StyledFormHelperText,
            }}
            inputProps={{ 'data-testid': 'input-name' }}
            required={true}
            autoFocus={true}
            fullWidth={true}
            label='Message'
          />
          {showMarket && (
            <Stack spacing={2} alignItems='flex-start'>
              <TextField value={version} onChange={handleVersionChange} fullWidth={true} label='Version' />
              <Select value={market} onChange={handleMarketChange}>
                {markets.map((m) => (
                  <MenuItem key={m} value={m}>
                    {m}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          )}
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={handleFormClose}>Close</Button>
          <SubmitButton onClick={handleSubmit} disabled={needDisableButton} color='primary' variant='contained'>
            {pending ? <CircularProgress size={24} /> : 'Save'}
          </SubmitButton>
        </DialogActions>
      </Dialog>
    </>
  );
});
