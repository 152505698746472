import { useMemo, useState } from 'react';
import { Chip, MenuItem, Select, styled, Typography } from '@mui/material';

import { KeyValueFilter, KeyValuePair, KeyValuePairSerialized } from '@playq/octopus-common';

import { IFilterCellProps } from '/shared/Table/components/TableHeadCell/IFilterCellProps';
import { FilterForm } from '/shared/Table/components/TableHeadCell/styles';
import { FilterData } from '/shared/Table/interfaces/FilterData';

const Wrapper = styled('div')`
  width: 100%;
  max-width: 360px;
`;

const Row = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectStyled = styled(Select)`
  width: 100px;
`;

const SelectedWrapper = styled('div')`
  border-bottom: 2px solid ${({ theme }) => theme.palette.text.primary};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;

const MenuItemStyled = styled(MenuItem)<{ emptyInputValue: boolean }>`
  ${({ emptyInputValue }) => emptyInputValue && `display: none`}
`;

const emptyInputValue = 'Select';
const emptyValueValue = 'All';

export const makeKeyValuePair = (key: string, value: string | undefined) => {
  const newKeyValuePair = new KeyValuePair();
  newKeyValuePair.key = key;
  newKeyValuePair.value = value;
  return newKeyValuePair;
};

const setChipColor = (key: string) => {
  const isRequired = key.startsWith('$');

  return isRequired ? 'secondary' : 'primary';
};

/**
 * @param filterData should be a string[] with every child is a stringified KeyValuePairSerialized
 */

const parseKeyValuesFilterData = (filterData: FilterData) =>
  filterData
    .map((keyValuePairAsString) => {
      try {
        const keyValuePairSerialized = JSON.parse(String(keyValuePairAsString)) as KeyValuePairSerialized;
        return new KeyValuePair(keyValuePairSerialized);
      } catch (_err) {
        return null;
      }
    })
    .filter(Boolean) as KeyValuePair[];

export function KeyValueFilterCell({ filter, filterData, onSubmit, onClear }: IFilterCellProps) {
  const keyValuePairs = useMemo(() => parseKeyValuesFilterData(filterData || []), [filterData]);

  const selected = useMemo(() => {
    if (!filter) {
      return [];
    }
    return (filter as KeyValueFilter).pairs;
  }, [filter]);

  const [pair, setPair] = useState<{ key: string; value: string | undefined }>({
    key: emptyInputValue,
    value: undefined,
  });

  const keyOptions = useMemo(() => {
    const allKeyOptions = keyValuePairs.map(({ key }) => key);
    const uniqueKeyOptions = Array.from(new Set(allKeyOptions));
    const keyOptionsWithValue = uniqueKeyOptions.filter(
      (key) =>
        !selected.some(
          ({ key: selectedKey, value: selectedValue }) => selectedKey === key && selectedValue === undefined
        )
    );

    if (pair.key) {
      keyOptionsWithValue.push(pair.key);
    }

    return [emptyInputValue, ...keyOptionsWithValue];
  }, [keyValuePairs, pair.key, selected]);

  const valueOptions = useMemo(() => {
    if (!pair.key) {
      return [emptyValueValue];
    }
    const allValueOptions = keyValuePairs.filter(({ key }) => key === pair.key).map(({ value }) => value);
    const uniqueValueOptions = Array.from(new Set(allValueOptions));
    const valueOptionsWithoutSelected = uniqueValueOptions.filter(
      (value) =>
        !selected.some(
          ({ key: selectedKey, value: selectedValue }) => selectedValue === value && selectedKey === pair.key
        )
    );

    return [emptyInputValue, ...valueOptionsWithoutSelected, emptyValueValue];
  }, [keyValuePairs, pair.key, selected]);

  const handleKeyClick = (key: string) => () => {
    const newPair = { ...pair, key };
    setPair(newPair);
  };

  const handleValueClick = (value: string | undefined) => () => {
    if (!pair.key || pair.key === emptyInputValue) {
      return;
    }
    const newKeyValuePair = makeKeyValuePair(pair.key, value);
    const newFilter = new KeyValueFilter();
    const filteredSelected =
      value === undefined
        ? selected.filter(({ key }) => key !== pair.key)
        : selected.filter(
            ({ key: selectedKey, value: selectedValue }) => !(selectedKey === pair.key && selectedValue === undefined)
          );
    newFilter.pairs = filteredSelected.concat(newKeyValuePair);
    onSubmit(newFilter, false);
  };

  const handleDelete = (key: string, value: string | undefined) => () => {
    const newFilter = new KeyValueFilter();
    const newPairs = selected.filter(
      ({ key: selectedKey, value: selectedValue }) => !(key === selectedKey && value === selectedValue)
    );
    newFilter.pairs = newPairs;
    if (!newPairs.length) {
      onClear();
      return;
    }
    onSubmit(newFilter, false);
  };

  return (
    <FilterForm>
      <Wrapper>
        {selected.length > 0 && (
          <SelectedWrapper>
            {selected.map(({ key: selectedKey, value: selectedValue }) => (
              <Chip
                key={selectedKey + (selectedValue || '')}
                label={`${selectedKey}: ${selectedValue || emptyValueValue}`}
                onDelete={handleDelete(selectedKey, selectedValue)}
                color={setChipColor(selectedKey)}
              />
            ))}
          </SelectedWrapper>
        )}
        <Row>
          <Row>
            <Typography variant='body1'>Key: </Typography>
            <SelectStyled value={pair.key}>
              {keyOptions.map((option, idx) => (
                <MenuItemStyled
                  key={`key-${option}-${idx}`}
                  value={option}
                  onClick={handleKeyClick(option)}
                  emptyInputValue={option === emptyInputValue}
                >
                  {option}
                </MenuItemStyled>
              ))}
            </SelectStyled>
          </Row>
          <Row>
            <Typography variant='body1'>Value: </Typography>
            <SelectStyled value={pair.value || emptyInputValue}>
              {valueOptions.map((option, idx) => (
                <MenuItemStyled
                  key={`value-${option || ''}-${idx}`}
                  value={option}
                  onClick={handleValueClick(option === emptyValueValue ? undefined : option)}
                  emptyInputValue={option === emptyInputValue}
                >
                  {option}
                </MenuItemStyled>
              ))}
            </SelectStyled>
          </Row>
        </Row>
      </Wrapper>
    </FilterForm>
  );
}

KeyValueFilterCell.type = KeyValueFilter.ClassName;
