import { Avatar, Link, Paper, styled } from '@mui/material';

export const FormStyled = styled('form')`
  margin-top: ${({ theme }) => theme.spacing(2)};
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

export const AvatarStyled = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;
export const ResetPasswordLink = styled(Link)`
  font-size: 14px;
  text-align: center;
`;
export const PaperStyled = styled(Paper)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(1),
  justifyItems: 'center',
  marginTop: theme.spacing(9),
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
}));

export const VersionSection = styled('div')`
  text-align: center;
  color: hsl(0, 0%, 75%);

  &::selection {
    background: #ff5722;
    color: white;
  }
`;
