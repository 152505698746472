import { IAvatarStyledProps } from './types';

export const getAvatarBorderColor = (
  theme: IAvatarStyledProps['theme'],
  { imageSelected, imageUrl }: IAvatarStyledProps['ownerState']
) => {
  if (imageSelected) return theme.palette.secondary.main;
  if (imageUrl) return theme.palette.text.disabled;
  return 'transparent';
};
