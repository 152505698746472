import { ReactElement, ReactNode, SyntheticEvent, useMemo, useState } from 'react';
import { CircularProgress, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import {
  ITableToolbarAction,
  RenderToolbarActionFunction,
  StatusToolbarActionFunction,
  ToolbarActionClickEvent,
} from '/shared/Table/interfaces/ITableToolbarAction';
import { TableIconAction } from '/shared/Table/components/TableIconAction';
import { ActionList, ITableState } from '/shared/Table';

interface ITableToolbarActionsComponent {
  actions: ITableToolbarAction[];
}

function getDisabledValue(disabled?: boolean | StatusToolbarActionFunction): boolean {
  if (typeof disabled === 'function') {
    return disabled();
  }

  return !!disabled;
}

function getTooltipValue(tooltip?: string | RenderToolbarActionFunction | ReactNode): string | ReactNode {
  if (typeof tooltip === 'function') {
    return tooltip();
  }

  return tooltip;
}

function getLabelValue(label?: string | RenderToolbarActionFunction | ReactNode) {
  if (typeof label === 'function') {
    return label();
  }

  return label;
}

function getHiddenValue(hidden?: boolean | StatusToolbarActionFunction): boolean {
  if (typeof hidden === 'function') {
    return hidden();
  }

  return !!hidden;
}

function getPendingValue(pending?: boolean | StatusToolbarActionFunction): boolean {
  if (typeof pending === 'function') {
    return pending();
  }

  return !!pending;
}

const TableToolbarActionsMenu = (props: ITableToolbarActionsComponent) => {
  const { actions } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    const htmlElement = event.currentTarget as HTMLElement;
    setAnchorEl(htmlElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (onClick: (event: SyntheticEvent) => void) => (event: SyntheticEvent) => {
    event.stopPropagation();
    onClick(event);
    handleClose();
  };

  return (
    <>
      <Tooltip disableFocusListener={true} title={`Open ${actions.length} actions`}>
        <IconButton
          aria-label='More'
          aria-haspopup='true'
          data-testid='table-toolbar-actions-menu'
          onClick={handleClick}
          size='large'
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ width: 230 }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {actions.map(({ label, disabled, icon: Icon, onClick }: ITableToolbarAction, idx: number) => (
          <MenuItem key={idx} disabled={getDisabledValue(disabled)} selected={false} onClick={handleSelect(onClick)}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <Typography variant='inherit' noWrap={true}>
              {getLabelValue(label)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const TableToolbarActionsList = ({ actions }: ITableToolbarActionsComponent) => {
  const handleClick = (onClick: ToolbarActionClickEvent) => (event: SyntheticEvent) => {
    event.stopPropagation();
    onClick(event);
  };

  return (
    <ActionList>
      {actions.map(({ disabled, tooltip, onClick, ...rest }: ITableToolbarAction, idx: number) => {
        return (
          <TableIconAction
            key={idx}
            disabled={getDisabledValue(disabled)}
            tooltip={getTooltipValue(tooltip)}
            onClick={handleClick(onClick)}
            {...rest}
          />
        );
      })}
    </ActionList>
  );
};

export function GenericTableToolbarActions<D>(state: ITableState<D>) {
  const { toolbarActions = [], toolbarActionsLength } = state;

  const visibleActions = useMemo(() => {
    if (Array.isArray(toolbarActions)) {
      return toolbarActions.filter((a: ITableToolbarAction) => !getHiddenValue(a.hidden));
    }
    return undefined;
  }, [toolbarActions]);

  const someProcessing = useMemo(() => {
    if (Array.isArray(toolbarActions)) {
      return toolbarActions.some((a: ITableToolbarAction) => getPendingValue(a.pending));
    }
    return undefined;
  }, [toolbarActions]);

  const [plainActions, menuActions] = useMemo(() => {
    if (visibleActions) {
      if (visibleActions.length > toolbarActionsLength) {
        return [
          visibleActions.slice(0, toolbarActionsLength - 1),
          visibleActions.slice(toolbarActionsLength - 1, visibleActions.length),
        ];
      }
    }
    return [visibleActions, []];
  }, [toolbarActionsLength, visibleActions]);

  if (!(toolbarActions instanceof Array)) {
    return toolbarActions as ReactElement;
  }

  if (someProcessing) {
    return <CircularProgress color='secondary' />;
  }

  return (
    <>
      <TableToolbarActionsList actions={plainActions as ITableToolbarAction[]} />

      {Boolean(menuActions.length) && <TableToolbarActionsMenu actions={visibleActions as ITableToolbarAction[]} />}
    </>
  );
}
