import { FC, useContext, useMemo, useState } from 'react';
import { useEventCallback } from '@mui/material';

import { Filter } from '@playq/octopus-common';

import { PopoverButton } from '/shared/PopoverButton';
import { filterComponents } from '/shared/Table/components/TableHeadCell/filterComponents';
import {
  ICustomFilterInputProps,
  IFilterCellComponent,
  IFilterCellProps,
} from '/shared/Table/components/TableHeadCell/IFilterCellProps';
import { popoverCSS } from '/shared/Table/components/TableHeadCell/styles';
import { FilterData } from '/shared/Table/interfaces/FilterData';
import { ThemeModeContext } from '/common';

import { IPreviewComponent, previewComponents } from './previewComponents';
import { QueryChipStyled } from './styles';

export interface IFilterChipProps<F extends string> {
  filter: Filter;
  filterKey: F;
  columnLabel?: string;
  filterData?: FilterData;
  CustomFilterInput?: FC<IFilterCellProps & ICustomFilterInputProps>;

  onChange?: (key: F, value: Filter) => void;
  onClear?: (key: F) => void;
  formatPreview?: (filter: Filter) => JSX.Element;
  validate?: (value: unknown) => string | undefined;
}

export function FilterChip<F extends string>({
  filter,
  filterKey,
  columnLabel,
  filterData,
  CustomFilterInput,
  onChange,
  onClear,
  formatPreview,
  validate,
}: IFilterChipProps<F>) {
  const [open, setOpen] = useState(() => false);
  const filterClassName = filter.getClassName();
  const FilterEditor = useMemo<IFilterCellComponent | undefined>(
    () => filterComponents.find((component: IFilterCellComponent) => component.type === filterClassName),
    [filterClassName]
  );

  const PreviewComponent = previewComponents.find((component: IPreviewComponent) => component.canPreview(filter));

  const themeModeContext = useContext(ThemeModeContext);
  const currentTheme = themeModeContext.getCurrentTheme();

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const handlePopoverOpen = () => {
    setOpen(true);
  };

  const handleSubmit = useEventCallback((val: Filter, close = true) => {
    onChange?.(filterKey, val);

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (close) {
      setOpen(false);
    }
  });

  const handleClear = useEventCallback(() => {
    onClear?.(filterKey);
    setOpen(false);
  });

  if (!PreviewComponent) {
    return null;
  }

  const Label = () => (
    <>
      {columnLabel || filterKey}: <PreviewComponent filter={filter} format={formatPreview} />
    </>
  );

  return (
    <PopoverButton
      open={open}
      button={
        <QueryChipStyled
          onClick={onChange ? handlePopoverOpen : undefined}
          onDelete={onClear ? handleClear : undefined}
          label={<Label />}
        />
      }
      onClose={handlePopoverClose}
      styles={{ popover: popoverCSS }}
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
    >
      {FilterEditor && (
        <FilterEditor
          filter={filter}
          filterData={filterData}
          currentTheme={currentTheme}
          CustomFilterInput={CustomFilterInput}
          onSubmit={handleSubmit}
          onClear={handleClear}
          validate={validate}
        />
      )}
    </PopoverButton>
  );
}
