import { FC, useMemo, useState } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { FilterExpression, FilterExpressionHelpers } from '@playq/octopus-common';

import { IWithCSS } from '/common/models';

import { FormGroupStyled } from './styles';

export interface IFilterExpressionsSelectProps {
  initialExp: FilterExpression;
  onChange: (e: FilterExpression) => void;
  styles?: {
    formGroup?: IWithCSS['css'];
  };
}

export const FilterExpressionsSelect: FC<IFilterExpressionsSelectProps> = (props) => {
  const { initialExp, onChange, styles = {} } = props;
  const [expression, setExpression] = useState(initialExp);

  const { formGroup: formGroupCSS } = styles;
  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const nExpressions = e.target.value as FilterExpression;
    setExpression(nExpressions);
    onChange(nExpressions);
  };

  const items = useMemo(() => {
    return FilterExpressionHelpers.all.map((exp: string, idx: number) => {
      return (
        <MenuItem key={idx} value={exp}>
          {exp}
        </MenuItem>
      );
    });
  }, []);

  return (
    <FormGroupStyled css={formGroupCSS}>
      <Select value={expression} onChange={handleChange}>
        {items}
      </Select>
    </FormGroupStyled>
  );
};
