import { FC, MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { Typography, Stack } from '@mui/material';
import { TimelineConnector, TimelineDot, TimelineSeparator } from '@mui/lab';
import { Comment as CommentIcon, Info as InfoIcon } from '@mui/icons-material';
import { FaAmazon, FaFacebook, FaGooglePlay } from 'react-icons/fa';
import { GrAppleAppStore } from 'react-icons/gr';

import { Market } from '@playq/services-shared';
import { MarketAppTag, Notification, NotificationWithUserStruct } from '@playq/octopus-notifications';

import { formatDate } from '/helpers';
import { appToolkit } from '/store';
import { Annotations } from '/common/AppTimeline/Timeline/Annotations';
import NotificationView from '/shared/Notification/NotificationView';

import { NotificationGroup } from '../types';

import { INotificationItemProps } from './types';
import { getMessage } from './getMessage';
import {
  AnnotationIcon,
  AnnotationsWrapper,
  Bullet,
  InfoTooltip,
  ListItemStyled,
  MarketLabel,
  TimelineContentStyled,
  Username,
} from './styles';

const getMarket = (notification: Notification): ReactNode => {
  if (notification.getClassName() === MarketAppTag.ClassName) {
    const marketNotification = notification as MarketAppTag;
    let Icon;
    switch (marketNotification.market) {
      case Market.GooglePlay: {
        Icon = FaGooglePlay;
        break;
      }
      case Market.AmazonMarketplace: {
        Icon = FaAmazon;
        break;
      }
      case Market.Facebook: {
        Icon = FaFacebook;
        break;
      }
      case Market.AppleAppStore: {
        Icon = GrAppleAppStore;
        break;
      }
    }
    return (
      <MarketLabel>
        <Icon />
        {marketNotification.version && `v${marketNotification.version}`}
      </MarketLabel>
    );
  }
  return null;
};

const getUsername = (notification: Notification): string | undefined => {
  if (NotificationWithUserStruct.isRegisteredType(notification.getFullClassName())) {
    return (notification as NotificationWithUserStruct).userName;
  }
};

export const NotificationItem: FC<INotificationItemProps> = ({
  item,
  records,
  isEntityTimeline,
  fullscreen,
  onAnnotationSubmit,
  onUserSelect,
}) => {
  const { notification } = item;
  const [open, setOpen] = useState(false);

  const app = useSelector(appToolkit.selectors.app);

  const itemColor = useMemo(() => {
    const group = records.find((record) => 'items' in record && record.items.some((child) => child.id === item.id)) as
      | NotificationGroup
      | undefined;
    return group?.color;
  }, [item, records]);

  const createdAt = useMemo(() => {
    const date = DateTime.fromJSDate(notification.at);
    const today = DateTime.local();
    let format = 'f';
    if (date.hasSame(today, 'day')) {
      format = 't';
    }
    return formatDate(notification.at, format);
  }, [notification]);
  const message = useMemo(() => getMessage(notification, app, isEntityTimeline), [notification, app, isEntityTimeline]);
  const market = useMemo(() => getMarket(notification), [notification]);
  const username = useMemo(() => getUsername(notification), [notification]);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleAnnotationsSubmit = useCallback(
    (annotation: string) => {
      onAnnotationSubmit(item.id, annotation);
    },
    [onAnnotationSubmit, item.id]
  );

  const handleUserSelect = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (username) {
      onUserSelect(username);
    }
  };

  return (
    <>
      <Annotations
        annotations={item.annotations}
        open={open}
        onClose={handleClose}
        onSubmit={handleAnnotationsSubmit}
      />
      <ListItemStyled onClick={handleOpen} $ltr={fullscreen} data-testid='timeline-entity'>
        <TimelineContentStyled $fullscreen={true}>
          <Stack spacing={1} direction={fullscreen ? 'row-reverse' : 'row'} justifyContent='flex-end'>
            {message}
            {market}
            <InfoTooltip
              PopperProps={{
                onClick: (e: MouseEvent) => {
                  e.preventDefault();
                  e.stopPropagation();
                },
              }}
              title={<NotificationView notification={notification} inline={false} />}
              placement={fullscreen ? 'right' : 'top-end'}
            >
              <InfoIcon data-testid='comment-icon' color='disabled' fontSize='small' style={{ cursor: 'pointer' }} />
            </InfoTooltip>
          </Stack>
          <Typography variant='body2' color='textSecondary' component='div'>
            <Stack spacing={0.5} direction='row' justifyContent={fullscreen ? 'flex-start' : 'flex-end'}>
              {username && (
                <>
                  <Username onClick={handleUserSelect} variant='inherit' noWrap={true}>
                    {username}
                  </Username>
                  <Bullet />
                </>
              )}
              {!fullscreen && !!item.annotations.length && (
                <>
                  {item.annotations.length}
                  <AnnotationIcon fontSize='small' />
                  <Bullet />
                </>
              )}
              {createdAt}
            </Stack>
          </Typography>
        </TimelineContentStyled>
        {fullscreen && item.annotations.length > 0 && (
          <AnnotationsWrapper variant='button' color='textSecondary'>
            {item.annotations.length}
            <CommentIcon fontSize='small' />
          </AnnotationsWrapper>
        )}
        <TimelineSeparator>
          <TimelineDot
            variant={itemColor ? 'filled' : 'outlined'}
            style={{
              backgroundColor: itemColor,
            }}
          />
          <TimelineConnector
            style={{
              backgroundColor: itemColor,
            }}
          />
        </TimelineSeparator>
      </ListItemStyled>
    </>
  );
};
