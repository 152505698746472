import { styled } from '@mui/material/styles';
import { FormControl, Grid, IconButton } from '@mui/material';

import { viewPort } from '/styles/global';
import { Tags } from '/shared/Tags';
import { LinearProgressWithLabel } from '/shared/LinearProgressWithLabel';

export const BulkApplyWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 56px;
  grid-column: 1;
`;

export const UploadItemWrapper = styled('div')(({ theme }) => ({
  borderBottom: '1px solid #c2c2c2',
  padding: `${theme.spacing(2)} 0`,

  '&:last-child': {
    borderBottom: 'none',
  },
}));

export const UploadItemContainer = styled(Grid)`
  minheight: 200px;
  maxwidth: ${viewPort.width}px;
`;

export const GridFlexCenter = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled('div')`
  width: 200px;
  max-height: 150px;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
`;
export const FormControlStyled = styled(FormControl)(({ theme }) => ({
  margin: `${theme.spacing(1)} 0`,
}));

export const TagsStyled = styled(Tags)(({ theme }) => ({
  margin: `${theme.spacing(1)} 0`,
}));

export const ActionContainer = styled(Grid)`
  height: 48px;
  width: 100%;
`;

export const InfoContainer = styled('div')`
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ProgressStyled = styled(LinearProgressWithLabel)`
  width: 100%;
`;

export const ErrorContainer = styled('div')`
  flex: 1;
  align-items: center;
  tablelayout: 'fixed';
  display: 'table';
  width: '100%';
  overflow: 'hidden';
  transform: 'translate(0, 50%)';
`;

export const AutoGenerateIconStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[600],
}));
