import { Tooltip, TooltipProps, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

export const CustomTooltip = (props: TooltipProps) => {
  const theme = useTheme();

  return (
    <Tooltip
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            background: theme.palette.background.paper,
            boxShadow: theme.shadows[1],
            color: theme.palette.text.primary,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          },
        },
      }}
    />
  );
};

interface IPromotionsCustomTooltipProps {
  name?: string;
}

const getCustomPromotionTooltipTitle = (action: string, name?: string) => `${action} ${!name ? 'From Default' : ''}`;

const getCustomPromotionTooltipName = (name?: string) => name ?? 'Sandbox';

export const UdpatePromotionsTooltipTitle = ({ name }: IPromotionsCustomTooltipProps) => (
  <>
    <Typography variant='h6'>{getCustomPromotionTooltipTitle('Merge', name)}</Typography>
    <Typography>
      Promote all the entities from <b>{getCustomPromotionTooltipName(name)}</b> to selected space without demoting.
    </Typography>
  </>
);

export const CopyPromotionsTooltipTitle = ({ name }: IPromotionsCustomTooltipProps) => (
  <>
    <Typography variant='h6'>{getCustomPromotionTooltipTitle('Copy', name)}</Typography>
    <Typography>
      Demote all the entities of selected types{' '}
      <i>
        (for example: <b>Experiments</b>, <b>Game Events</b>, etc.)
      </i>{' '}
      from selected space and then promote all the entities of selected types from{' '}
      <b>{getCustomPromotionTooltipName(name)}</b> to selected space.
    </Typography>
  </>
);

export const ClonePromotionsTooltipTitle = ({ name }: IPromotionsCustomTooltipProps) => (
  <>
    <Typography variant='h6'>{getCustomPromotionTooltipTitle('Clone All', name)}</Typography>
    <Typography>
      Demote all the entities from selected space and then promote all the entities from{' '}
      <b>{getCustomPromotionTooltipName(name)}</b> to selected space.
    </Typography>
  </>
);
