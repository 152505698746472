import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { INavItem } from '/common/NavSidebar/navConfig';
import { SyntheticEventWithMetaKey } from '/common/models';

import { IExpandIconProps, INavSidebarItemProps } from './types';
import { StyledList, StyledListItemButton, StyledListItemIcon, StyledListItemText } from './styles';

const ExpandIcon: FC<IExpandIconProps> = ({ open }) => (open ? <ExpandLess /> : <ExpandMore />);

export const NavSidebarItem: FC<INavSidebarItemProps> = (props) => {
  const { item, selectedItems, openSidebar, onSelectItem, onRouterRedirect } = props;

  const selected = useMemo(() => selectedItems.has(item.caption), [selectedItems, item]);
  const [collapse, setCollapse] = useState(selected && openSidebar);

  useEffect(() => {
    setCollapse(selected && openSidebar);
  }, [selected, openSidebar]);

  const handleClick = (event: SyntheticEventWithMetaKey) => {
    if (event.metaKey && item.route) {
      window.open(item.route, '_blank');
      return;
    }
    if (item.children && item.children.length > 0 && selected) {
      setCollapse(!collapse);
    }

    onSelectItem([item.caption]);

    if (item.route) {
      onRouterRedirect(item.route);
    }
  };

  const handleRedirect = (children: INavItem) => (event: SyntheticEvent & { metaKey?: boolean }) => {
    if (event.metaKey && children.route) {
      window.open(children.route, '_blank');
      return;
    }
    onSelectItem([item.caption, children.caption]);
    if (children.route) {
      onRouterRedirect(children.route);
    }
  };

  return (
    <>
      <StyledListItemButton
        key={item.caption}
        selected={selected}
        disabled={item.disabled}
        onClick={handleClick}
        hidden={item.hidden}
      >
        {item.icon && <StyledListItemIcon>{item.icon}</StyledListItemIcon>}
        <StyledListItemText inset={!item.icon} primary={item.caption} />
        {item.children && item.children.length > 0 && <ExpandIcon open={collapse && openSidebar} />}
      </StyledListItemButton>
      {item.children && item.children.length > 0 && (
        <Collapse in={collapse && openSidebar} unmountOnExit={true}>
          <StyledList>
            {item.children.map((children) => (
              <StyledListItemButton
                key={children.caption}
                selected={selectedItems.has(children.caption)}
                onClick={handleRedirect(children)}
                disabled={children.disabled}
              >
                <StyledListItemText primary={children.caption} inset={true} />
              </StyledListItemButton>
            ))}
          </StyledList>
        </Collapse>
      )}
    </>
  );
};
