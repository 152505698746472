import { styled } from '@mui/material';

export const VideoContainerStyled = styled('div')({
  position: 'relative',
  maxHeight: 'inherit',
  maxWidth: 'inherit',
  height: '100%',
  width: '100%',
});

export const VideoStyled = styled('video')({
  display: 'block',
  objectFit: 'contain',
  overflow: 'auto',
  textDecoration: 'none',
  height: '100%',
  width: '100%',
  maxHeight: 'inherit',
  maxWidth: 'inherit',
});
