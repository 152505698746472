import { useMemo } from 'react';
import { Typography } from '@mui/material';

import { BooleanFilter } from '@playq/octopus-common';
import { FileFeature, FilesFilterField } from '@playq/octopus2-files';

import { useFilesQuery } from '/api';
import { QueryHelpers } from '/helpers/query';

import { IFilesQuery } from './types';

const filterByMissed = new BooleanFilter({ value: true });
const maxQuery: IFilesQuery = QueryHelpers.getMaxValue();

interface IMissedFilesFilterDataProps {
  filterBy: IFilesQuery['filterBy'];
  enabled?: boolean;
  queryFiles?: typeof useFilesQuery;
}

const features = [FileFeature.LastRevision];

export const useMissedFilesFilterData = ({
  filterBy: initialFilterBy,
  enabled = false,
  queryFiles = useFilesQuery,
}: IMissedFilesFilterDataProps) => {
  const query = useMemo(
    () => ({
      ...maxQuery,
      filterBy: {
        ...initialFilterBy,
        [FilesFilterField.IsMissed]: filterByMissed,
      },
    }),
    //Here additionally adding initialFilterBy.Tag for deep compare
    // And correctly changing current query
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialFilterBy, initialFilterBy.Tag]
  );

  const { files: missedFiles, isLoading } = queryFiles(features, query, { enabled });

  const missedFilesTitle = useMemo(() => {
    if (isLoading) {
      return (
        <Typography variant='body2' color='gray' sx={{ fontStyle: 'italic', display: 'inline' }}>
          searching...
        </Typography>
      );
    }
    if (missedFiles?.length === undefined) {
      return '';
    }
    return missedFiles.length;
  }, [missedFiles?.length, isLoading]);

  return [
    <Typography color='error' variant='body2' key='Missed' component='span'>
      Missed {missedFilesTitle}
    </Typography>,
    <Typography sx={{ color: 'success.main' }} variant='body2' key='Exists' component='span'>
      Exists
    </Typography>,
  ];
};
