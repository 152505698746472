import { css, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { green } from '@mui/material/colors';

import { PreviewSizes } from '/constants/file';

export const idCellCSS = css`
  width: 1%;
  min-width: 82px;
`;

export const nameCellCSS = css`
  white-space: nowrap;
`;

export const useStyles = makeStyles((theme: Theme) => ({
  platformLabel: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: 5,
  },
  selectedLabel: {
    marginLeft: 5,
    verticalAlign: 'middle',
    color: green[500],
  },
  marketLabel: {
    marginRight: 5,
  },
  toolsButtons: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  toolsButton: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  nameIconWrapper: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto',
  },

  filePreview: {
    height: PreviewSizes.xsmall,
    width: PreviewSizes.xsmall,
  },
  iconFileContent: {
    height: '100%',
    position: 'relative',
  },
  appName: {
    marginLeft: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
  },
  appIconWrapper: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));
